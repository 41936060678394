import React from "react";
// import React, { useEffect, useState } from "react";
// import { DisplayText } from "./DisplayText";
import {
  //UNIT_WIDTH,
  Z_1,
} from "../nzwConstants";
import {
  EVENT_TYPES,
  ITEMS,
  NWORLD_UNIT_WIDTH_PIXELS,
} from "../../../constants";
import { emitEvent } from "../../../utils/emitEvent";
import getAuth from "../../../utils/getAuth";
import GameComponentButton from "../../../commonComponents/GameComponentButton";
import {
  getXDecimalPercentFromXPixel,
  getYDecimalPercentFromYPixel,
} from "../../../coordsMapper";

// const ATTACK_DISPLAY_REFRESH_INTERVAL = 300;
// const ATTACK_TIME = 1500;

const EquippedItemContent = ({
  //
  xPixels,
  yPixels,
  wasRightLastDirection,
  w,
  h,
  equippedItem,
}) => {
  if (equippedItem.name === ITEMS.BRICK.name) {
    const globalXOffset = 0 * NWORLD_UNIT_WIDTH_PIXELS;

    const locationXOffset = wasRightLastDirection
      ? w + globalXOffset
      : -(w + globalXOffset);

    // const locationXOffset = wasRightLastDirection ? w : -w;
    return (
      <GameComponentButton
        id="equippedItemContent"
        style={{
          position: "absolute",
          top: h / 2, // top: h,
          // left: w,d
          left: locationXOffset,

          width: w,
          height: h / 2,

          backgroundColor: "transparent",

          border: `1px solid gold`,

          boxSizing: "border-box",

          zIndex: Z_1 + 2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          const auth = getAuth();

          // console.log("HERE");

          const x = getXDecimalPercentFromXPixel(
            xPixels + locationXOffset - NWORLD_UNIT_WIDTH_PIXELS / 2
          );
          const y = getYDecimalPercentFromYPixel(yPixels);

          emitEvent({
            data: {
              auth,
              itemType: ITEMS.BRICK.itemType,
              x,
              y,
              backgroundColor: "#C84C0D",
            },
            type: EVENT_TYPES.PUT_NWORLD_OBJECT,
          });
        }}
      ></GameComponentButton>
    );
  }

  return null;
};

export default EquippedItemContent;
