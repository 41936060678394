import { LOCAL_STORAGE_KEYS } from "../../constants";
import {
  DEFAULT_START_X_PERCENT_STRING,
  DEFAULT_START_Y_PERCENT_STRING,
} from "../../worldConstants";
import updateBChar from "./updateBChar";

const doDefaultRespawn = () => {
  updateBChar({
    mutateFunction: (bCharDatum) => {
      bCharDatum.x = DEFAULT_START_X_PERCENT_STRING;
      bCharDatum.y = DEFAULT_START_Y_PERCENT_STRING;
      return bCharDatum;
    },
    sourceTag: "doDefaultRespawn",
  });
};

const goToRespawnFromLocalStorageValues = () => {
  const localStorageRespawnData = localStorage.getItem(
    LOCAL_STORAGE_KEYS.RESPAWN_DATA
  );

  if (!localStorageRespawnData) {
    doDefaultRespawn();

    return;
  }

  const respawnData = JSON.parse(localStorageRespawnData);

  if (!respawnData) {
    doDefaultRespawn();

    return;
  }

  const bCharDatum = respawnData?.bCharDatum;

  if (!bCharDatum) {
    doDefaultRespawn();

    return;
  }

  updateBChar({
    mutateFunction: (innerBCharDatum) => {
      innerBCharDatum.x = bCharDatum.x;
      innerBCharDatum.y = bCharDatum.y;

      return innerBCharDatum;
    },
    bBroadcast: true,
    sourceTag: "goToRespawnFromLocalStorageValues",
  });
};

export default goToRespawnFromLocalStorageValues;
