import { getState, setState } from "../../reactStateManagement";
import getDoPromiseInQueue from "../getDoPromiseInQueue";

const doPromiseInQueue = getDoPromiseInQueue();

// the elements can be any type of data
const updateAudioGhostQueue = ({ mutateFunction }) => {
  return new Promise((resolve, reject) => {
    doPromiseInQueue({
      operation: async () => {
        try {
          const audioGhostQueue = getState("audioGhostQueue");

          let newAudioGhostQueue = await mutateFunction(audioGhostQueue);

          if (!newAudioGhostQueue) {
            resolve();
          }

          if (newAudioGhostQueue === audioGhostQueue) {
            newAudioGhostQueue = newAudioGhostQueue.slice();
          }

          setState(["audioGhostQueue"], newAudioGhostQueue);

          resolve();
        } catch (error) {
          console.error("error in updateAudioGhostQueue:");
          console.error(error);
          reject(error);
        }
      },
    });
  });
};

export default updateAudioGhostQueue;
