import updateBChar from "../bChar/updateBChar";

const doEquipItem = ({ item }) => {
  updateBChar({
    mutateFunction: (bCharDatum) => {
      const newBCharDatum = { ...bCharDatum, equippedItem: item };

      return newBCharDatum;
    },
    bBroadcast: true,
  });
};

export default doEquipItem;
