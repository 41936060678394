// import updateBChars from "../../../../../../realtimeServer/app/utils/bChar/updateBChars.mjs";
// import { getState, setState } from "../../../../reactStateManagement";
import { getUserData } from "../../../../api/getUserData";
// import {
//   DEFAULT_START_X_PERCENT_STRING,
//   DEFAULT_START_Y_PERCENT_STRING,
//   LOCAL_STORAGE_KEYS,
// } from "../../../../constants";
// import { getState } from "../../../../reactStateManagement";
import goToRespawnFromLocalStorageValues from "../../../bChar/goToRespawnFromLocalStorageValues";
import setBCharDatumToMetaverseState from "../../../bChar/setBCharDatumToMetaverseState";
import createExecutionQueue from "../../../createExecutionQueue";
import delay from "../../../delay";
// import updateBChar from "../../../bChar/updateBChar";
// import getDoPromiseInQueue from "../../../getDoPromiseInQueue";
import { getMTokenData } from "../../../mToken";

// import updateBChar from "../../../bChar/updateBChar";
// import getDoPromiseInQueue from "../../../getDoPromiseInQueue";

// const doPromiseInQueue = getDoPromiseInQueue();

// const MAX_CREATE_IMAGE_RESPONSES_TO_STORE = 200;

// const doDefaultRespawn = () => {
//   updateBChar({
//     mutateFunction: (bCharDatum) => {
//       bCharDatum.x = DEFAULT_START_X_PERCENT_STRING;
//       bCharDatum.y = DEFAULT_START_Y_PERCENT_STRING;
//       return bCharDatum;
//     },
//   });
// };

// const goToRespawnFromLocalStorageValues = () => {
//   const localStorageRespawnData = localStorage.getItem(
//     LOCAL_STORAGE_KEYS.RESPAWN_DATA
//   );

//   if (!localStorageRespawnData) {
//     doDefaultRespawn();

//     return;
//   }

//   const respawnData = JSON.parse(localStorageRespawnData);

//   if (!respawnData) {
//     doDefaultRespawn();

//     return;
//   }

//   const bCharDatum = respawnData?.bCharDatum;

//   if (!bCharDatum) {
//     doDefaultRespawn();

//     return;
//   }

//   updateBChar({
//     mutateFunction: (innerBCharDatum) => {
//       innerBCharDatum.x = bCharDatum.x;
//       innerBCharDatum.y = bCharDatum.y;

//       return innerBCharDatum;
//     },
//     bBroadcast: true,
//   });
// };

// const doPromiseInQueue = getDoPromiseInQueue();
const executionQueue = createExecutionQueue(2);

const handleDoRespawn = async () => {
  await executionQueue(async () => {
    console.log("handleDoRespawn");
    try {
      const mTokenData = getMTokenData();

      if (!mTokenData) {
        goToRespawnFromLocalStorageValues();
        return;
      }

      const userData = await getUserData({ addToState: true });

      // const userData = getState("userData");

      //     console.log(`

      //   MEGA LOG ${JSON.stringify(
      //     {
      //       userData,
      //     },
      //     null,
      //     4
      //   )}
      //   )

      // `);

      setBCharDatumToMetaverseState({
        userData,
        // shouldUpdateCoordinates,
        shouldUpdateCharacter: false,
      });

      await delay(3000);
    } catch (error) {
      console.error("handleDoRespawn: error:");
      console.error(error);
    }
  });
};

export default handleDoRespawn;
