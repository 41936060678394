import React, {
  //  useState
  useEffect,
} from "react";
import {
  // BOTTOM_SECTION_HEIGHT_MOBILE,
  // BOTTOM_SECTION_HEIGHT_DESKTOP,
  COLOR_0,
  COLOR_1,
  COLOR_2,
  // COLOR_3,
  // EVENT,
  Z_INDICES,
} from "../../../constants";
import {
  getState,
  setState,
  // setState
} from "../../../reactStateManagement";
// import { SignInBox } from "./SignInBox";
// import { getEvent } from "../../../utils/getEvent";
import Spacer from "../../../commonComponents/Spacer";
import usePreventSpacebarPress from "../../../utils/effects/usePreventSpacebarPress";
import sendMessage from "../../../utils/message/sendMessage";
// import { SignedInBox } from "./SignedInBox";

const MESSAGE_BOX_WIDTH = 175;
// const MESSAGE_BOX_HEIGHT = 150;
// const MESSAGE_BOX_HEIGHT = BOTTOM_SECTION_HEIGHT_MOBILE;
// const MAX_MESSAGE_LENGTH = 10000;
const MAX_MESSAGE_LENGTH = 140;

// const SAMPLE_MESSAGE_DATA = [
//   {
//     text: "hello",
//     sender: "babebabe-babe-4bae-babe-babebabebabe",
//   },
//   {
//     text: "hi",
//     sender: "beefbeef-dead-4bee-dead-deadbeefdead",
//   },

//   {
//     text: "yo",
//     sender: "babebabe-babe-4bae-babe-babebabebabe",
//   },

//   {
//     text: "hey",
//     sender: "beefbeef-dead-4bee-dead-deadbeefdead",
//   },
// ];

// MessagesSection;

function uuidToHexColor(uuid) {
  // Remove hyphens from UUID
  const cleanUuid = uuid.replace(/-/g, "");

  // Take the first 6 characters to form a hex color
  const hexColor = cleanUuid.substring(0, 6);

  // Ensure the hex string is valid (in case UUID starts with non-hex chars)
  const validHex = hexColor.replace(/[^a-fA-F0-9]/g, "0").padEnd(6, "0");

  return `#${validHex}`;
}

// function uuidToHexColor(uuid) {
//   // Clean the UUID by removing hyphens
//   const cleanUuid = uuid.replace(/-/g, "");

//   // Extract first 3 characters for red channel, but we'll clamp it
//   let red = cleanUuid.substring(0, 3);

//   // Convert the hex to an integer, clamp between 0xCC and 0xFF for lighter reds
//   red = parseInt(red, 16);
//   red = Math.min(0xff, Math.max(0xcc, red)); // Clamp to avoid too dark reds

//   // Convert back to hex
//   let hexRed = red.toString(16).padStart(2, "0");

//   // Return as a hex color for light red
//   return `#${hexRed}0000`;
// }

// Example usage:
// const exampleUUID = "123e4567-e89b-12d3-a456-426614174000";
// console.log(uuidToRedShade(exampleUUID)); // Output will be a shade of red, e.g., #D00000

// // Example usage:
// const exampleUUID = "123e4567-e89b-12d3-a456-426614174000";
// console.log(uuidToHexColor(exampleUUID)); // Might output something like #123e45

const Message = ({ text, sender }) => {
  const backgroundColor = uuidToHexColor(sender);

  return (
    <div
      className={"no-drag"}
      style={{
        width: "100%",
        // height: 35,
        backgroundColor: COLOR_0,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "90%",
          // height: 35,
          // backgroundColor: COLOR_2,

          // backgroundColor: "#000000",
          // color: COLOR_3,
          backgroundColor,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <div
          style={{
            width: "95%",
            // height: 35,
            // backgroundColor: COLOR_2,
            backgroundColor,
            color: COLOR_1,
            marginTop: 10,
            marginBottom: 10,

            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",

            textAlign: "left",

            userSelect: "all",
          }}
        >
          {`${text}`}
        </div>
      </div>
      <Spacer width={"100%"} height={4} backgroundColor={COLOR_0} />
    </div>
  );
};

const MessagesSection = ({ messages }) => {
  // const messages = SAMPLE_MESSAGE_DATA;

  useEffect(() => {
    // scroll to bottom on mount

    const $ = window.$;

    const $messagesSection = $("#messages-section");

    if (!$messagesSection || $messagesSection.length === 0) {
      return;
    }

    const scrollDown = () => {
      $messagesSection.scrollTop(2222222);
    };

    setTimeout(scrollDown, 25);
    setTimeout(scrollDown, 50);
    setTimeout(scrollDown, 100);

    return () => {
      $messagesSection.scrollTop(2222222);
    };
  }, []);

  const messageElements = messages.map((messageData, index) => {
    return (
      <Message
        key={`${index}${messageData.sender}`}
        text={messageData.text}
        sender={messageData.sender}
      />
    );
  });

  return (
    <div
      id={"messages-section"}
      style={{
        width: "100%",
        height: "75%",
        backgroundColor: COLOR_0,

        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "flex-end",
        // alignItems: "center",

        display: "block",
        overflowY: "scroll",
      }}
    >
      {messageElements}
    </div>
  );
};

const CloseButton = () => {
  const id = "close-message-button";

  React.useEffect(() => {
    //     document.getElementById('yourElement').oncontextmenu = function(event) {
    //     event.preventDefault();
    //     event.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available?
    //     event.stopImmediatePropagation();
    //     return false;
    // };

    const $ = window.$;

    const $button = $(`#${id}`);

    const handleContextMenu = function (event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      return false;
    };

    $button.bind("contextmenu", handleContextMenu);

    return () => {
      $button.unbind("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <button
      id={id}
      style={{
        position: "absolute",

        top: 0,
        right: 0,

        width: 40,
        height: 40,

        backgroundColor: "red",

        zIndex: 2323232,

        fontSize: 20,
      }}
      onClick={() => {
        setState(["messageMode"], false);
      }}
    >
      {"x"}
    </button>
  );
};

/**
 *  Contains input and button for sending messages
 */
const InputSection = (/*{ messages }*/) => {
  const loading = getState("loading");
  const messageBoxInput = getState("messageBoxInput");

  // const TEST_USE_EFFECT = true;
  const TEST_USE_EFFECT = false;

  useEffect(() => {
    if (!TEST_USE_EFFECT) {
      return;
    }

    const messageBoxInputInner = getState("messageBoxInput");

    const generateTestMessage = (length) => {
      let text = "";

      // for (let i = 0; i < length; i++) {
      //   text += "W";
      // }

      const stringSegment = "Hi b ";

      for (let i = 0; i < Math.floor(length / stringSegment.length); i++) {
        text += stringSegment;
      }

      return text;
    };

    // const generateTestMessage = () => {
    //   let text = "";

    //   text += "Hey, how are you?!";

    //   return text;
    // };

    if (messageBoxInputInner.length === 0) {
      // setState(["messageBoxInput"], "Power of the monkey");

      setState(["messageBoxInput"], generateTestMessage(132));
    }
  }, [messageBoxInput]);

  usePreventSpacebarPress({
    id: "send-message-button",
  });

  useEffect(() => {
    const $ = window.$;

    const $messageBoxInput = $("#message-box-input");

    $messageBoxInput.on("keydown", (e) => {
      if (e.key === "Enter") {
        sendMessage();
      }
    });

    return () => {
      $messageBoxInput.off("keydown");
    };
  }, []);

  useEffect(() => {
    // if not focused and press "t" (for talk), focus on message box

    const $ = window.$;

    const $messageBoxInput = $("#message-box-input");

    const $document = $(document);

    const listenerFunction = (e) => {
      if (e.key === "t") {
        setTimeout(() => {
          if (getState("totalInputScreen") || getState("totalFormScreen")) {
            return;
          }

          $messageBoxInput.focus();
        }, 225);
      }
    };

    $document.on("keydown", listenerFunction);

    return () => {
      $document.off("keydown", listenerFunction);
    };
  }, []);

  useEffect(() => {
    // on focus, set state key "focus" to true

    const $ = window.$;

    const $messageBoxInput = $("#message-box-input");

    const handleFocus = () => {
      setState(["focus"], true);
    };

    $messageBoxInput.on("focus", handleFocus);

    return () => {
      $messageBoxInput.off("focus", handleFocus);
    };
  }, []);

  useEffect(() => {
    // on blur, set state key "focus" to false

    const $ = window.$;

    const $messageBoxInput = $("#message-box-input");

    const handleBlur = () => {
      setState(["focus"], false);
    };

    $messageBoxInput.on("blur", handleBlur);

    return () => {
      $messageBoxInput.off("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    // if focused and press "esc", unfocus message box

    const $ = window.$;

    const $messageBoxInput = $("#message-box-input");

    const $document = $(document);

    const listenerFunction = (e) => {
      if (e.key === "Escape") {
        $messageBoxInput.blur();
      }
    };

    $document.on("keydown", listenerFunction);

    return () => {
      $document.off("keydown", listenerFunction);
    };
  }, []);

  useEffect(() => {
    // on focus brighten the message box

    const $ = window.$;

    const $messageBoxInput = $("#message-box-input");

    function brightenMessageBox() {
      $messageBoxInput.css("backgroundColor", "#8B0000");
    }

    $messageBoxInput.on("focus", brightenMessageBox);

    function dimMessageBox() {
      $messageBoxInput.css("backgroundColor", "black");
    }

    $messageBoxInput.on("blur", dimMessageBox);

    return () => {
      $messageBoxInput.off("focus", brightenMessageBox);
      $messageBoxInput.off("blur", dimMessageBox);
    };
  }, []);

  useEffect(() => {
    setState(["unreadMessages"], 0);
  }, []);

  // console.log(messages.length)

  return (
    <div
      style={{
        width: "100%",

        height: "25%",
        backgroundColor: "turquoise",

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        position: "relative",
      }}
    >
      <input
        id={"message-box-input"}
        style={{
          width: "80%",
          height: "100%",

          border: "none",
          outline: "none",
          padding: 0,
          margin: 0,

          backgroundColor: "#d70000",

          color: COLOR_1,
        }}
        placeholder={"press t to type"}
        onFocus={() => {
          setState(["focus"], true);
        }}
        onBlur={() => {
          setState(["focus"], false);
        }}
        onChange={(e) => {
          let text = e.target.value;

          text = text.substring(0, MAX_MESSAGE_LENGTH);

          setState(["messageBoxInput"], text);
        }}
        value={messageBoxInput}
      />

      <button
        id={"send-message-button"}
        style={{
          width: "20%",
          height: "100%",

          border: "none",
          outline: "none",
          padding: 0,
          margin: 0,

          position: "relative",

          backgroundColor: loading ? "grey" : COLOR_0,

          cursor: loading ? "default" : "pointer",
        }}
        disabled={loading}
        onClick={() => {
          sendMessage();
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            backgroundColor: COLOR_2,
            color: COLOR_1,
          }}
        >
          {"Send"}
        </div>
      </button>
    </div>
  );
};

// const MOBILE_MODE_WIDTH_THRESHOLD = 800;

const NMessage = () => {
  // const loading = getState("loading");
  // const userData = getState("userData");
  const windowWidth = getState("windowWidth");
  const messages = getState("messages");

  // const mobileMode = windowWidth < MOBILE_MODE_WIDTH_THRESHOLD;
  // const mobileMode = getState("mobile");

  // const MESSAGE_BOX_HEIGHT = mobileMode
  //   ? BOTTOM_SECTION_HEIGHT_MOBILE
  //   : BOTTOM_SECTION_HEIGHT_DESKTOP;

  return (
    <div
      style={{
        // width: mobileMode ? MESSAGE_BOX_WIDTH : MESSAGE_BOX_WIDTH * 1.7,
        width: "50%",
        height: "100%",
        backgroundColor: COLOR_0,

        // position: "absolute",
        bottom: 0,
        left: (windowWidth - MESSAGE_BOX_WIDTH) / 2,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        zIndex: Z_INDICES.TOP_LAYER,
      }}
    >
      <CloseButton />
      <Spacer width={"100%"} height={4} backgroundColor={COLOR_0} />
      <MessagesSection messages={messages} />
      <InputSection messages={messages} />
    </div>
  );
};

export { NMessage };
