import React from "react";
import { FUN_CHARACTERS, NONE, Z_INDICES } from "../../constants";
// import updateBChar from "../../utils/bChar/updateBChar";
import { getState } from "../../reactStateManagement";
import updateBChar from "../../utils/bChar/updateBChar";
import useGameComponent from "../../utils/effects/useGameComponent";
// import { getMTokenData } from "../../../utils/mToken";

const SIZE_UNIT = 40;

const HeartButton = ({ bottom = 0 }) => {
  const menuOpen = getState("loginMode");
  const bCharDatum = getState("bCharDatum");

  // useEffect(() => {
  //   // no drag
  //   const $ = window.$;

  //   const attackButton = $("#heart-button-image");

  //   const preventDrag = (e) => {
  //     e.preventDefault();
  //   };

  //   const preventMobileDrag = (e) => {
  //     e.preventDefault();
  //   };

  //   attackButton.on("dragstart", preventDrag);
  //   attackButton.on("touchstart", preventMobileDrag);

  //   return () => {
  //     attackButton.off("dragstart", preventDrag);
  //     attackButton.off("touchstart", preventMobileDrag);
  //   };
  // }, []);

  const id = "heart-button";

  useGameComponent({ id });

  if (menuOpen) {
    return null;
  }

  // const mTokenData = getMTokenData();

  // if (!mTokenData) {
  //   return null;
  // }

  if (!FUN_CHARACTERS.includes(bCharDatum.character)) {
    return null;
  }

  const userData = getState("userData");

  if (!userData) {
    return null;
  }

  const fashionObjects = [];

  if (bCharDatum.fashionTop) {
    fashionObjects.push(bCharDatum.fashionTop);
  }

  if (bCharDatum.fashionBottom) {
    fashionObjects.push(bCharDatum.fashionBottom);
  }

  if (bCharDatum.fashionHat) {
    fashionObjects.push(bCharDatum.fashionHat);
  }

  if (fashionObjects.length === 0) {
    return null;
  }

  const allFashionObjectsHaveNoneImageUrl = fashionObjects.every(
    (fashionObject) => {
      return fashionObject.imageUrl === NONE;
    }
  );

  if (!allFashionObjectsHaveNoneImageUrl) {
    return null;
  }

  const mobile = getState("mobile");

  const right = (mobile ? SIZE_UNIT / 4 : SIZE_UNIT / 2) + SIZE_UNIT * 1.5;

  const haveFun = () => {
    updateBChar({
      mutateFunction: (bCharDatum) => {
        if (bCharDatum.fun) {
          delete bCharDatum.fun;

          return bCharDatum;
        }

        // bCharDatum.attack = null; //temp
        // bCharDatum.attack = Date.now();
        const systemTimeOffset = getState("systemTimeOffset");
        // client time = system time + systemTimeOffset
        // system time = client time - systemTimeOffset
        const now = Date.now();
        const fun = now - systemTimeOffset;
        // console.log("Temp report:");
        // console.log(
        //   JSON.stringify(
        //     {
        //       now,
        //       systemTimeOffset,
        //       attack,
        //     },
        //     null,
        //     4
        //   )
        // );
        bCharDatum.fun = fun;
        return bCharDatum;
      },
      bBroadcast: true,
    });
  };

  let bottomVal = SIZE_UNIT / 4 + bottom * 1;

  if (!mobile) {
    bottomVal = bottomVal + SIZE_UNIT / 6;
  }

  return (
    <button
      id={id}
      style={{
        position: "absolute",

        bottom: bottomVal,
        right,

        width: SIZE_UNIT * 1,
        height: SIZE_UNIT,

        // backgroundColor: "red",
        borderRadius: "50%",

        // maxHeight: 420,

        boxSizing: "border-box",

        zIndex: Number.MAX_SAFE_INTEGER || Z_INDICES.NEW_ZOOM_WORLD_LAYER * 2,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={haveFun}
      // onTouchStart={haveFun}
    >
      {/* {"❤️"} */}

      <img
        id={"heart-button-image"}
        src="/images/heart_button_0.png"
        alt="heart button"
        style={{
          width: "100%",
          height: "100%",

          userSelect: "none",
        }}
      />
    </button>
  );
};

export default HeartButton;
