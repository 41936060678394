import React from "react";
import { getState, setState } from "../../reactStateManagement";
import { EVENT_TYPES, Z_INDICES } from "../../constants";
// import delay from "../../utils/delay";
import { emitEvent } from "../../utils/emitEvent";
import getAuth from "../../utils/getAuth";
import { getMTokenData } from "../../utils/mToken";

const sendRelayMessage = async () => {
  const message = getState("totalInputScreenText");

  const auth = getAuth();

  const mTokenData = getMTokenData();

  if (!mTokenData || !mTokenData.address || !mTokenData.mToken) {
    console.error("sendRelayMessage: mToken data not found");
    setState(["browserAlertData"], {
      message: "Failed to submit form data, must be logged in",
    });

    return;
  }

  emitEvent({
    type: EVENT_TYPES.RELAY_MESSAGE,
    data: {
      auth,
      type: EVENT_TYPES.RELAY_MESSAGE,
      message,
      address: mTokenData.address,
      mToken: mTokenData.mToken,
    },
  });
};

const close = () => {
  setState(["totalInputScreen"], null);
  setState(["totalInputScreenText"], "");
  setState(["focus"], false);
};

const TotalInputScreen = () => {
  // put on esc key listener

  const [isLocalLoading, setIsLocalLoading] = React.useState(false);
  const totalInputScreenText = getState("totalInputScreenText");

  //   console.log("TIST:", totalInputScreenText);

  React.useEffect(() => {
    const $ = window.$;

    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        close();
      }
    };

    $(document).on("keydown", handleKeyDown);

    return () => {
      $(document).off("keydown", handleKeyDown);
    };
  }, []);

  // handle enter key
  React.useEffect(() => {
    const $ = window.$;

    const handleKeyDown = async (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        setIsLocalLoading(true);

        await sendRelayMessage();
        // await delay(1000);

        close();
      }
    };

    $(document).on("keydown", handleKeyDown);

    return () => {
      $(document).off("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div
      id={"total-input-screen-container"}
      style={{
        position: "absolute",
        top: 0,
        left: 0,

        width: "100%",
        height: "100%",
        zIndex: Z_INDICES.DIALOG_LAYER * 2,
      }}
      onClick={(e) => {
        // check if id is of container
        if (e.target.id === "total-input-screen-container") {
          close();
        }
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,

          width: "100%",
          height: "15%",

          backgroundColor: "rgba(0, 0, 0, 0.6)",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          // setState(["totalInputScreen"], null);
        }}
      >
        <textarea
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "transparent",
            color: "white",
            fontSize: "16px",
            border: "none",
            outline: "none",
            resize: "none",
            //   padding: "0 1em",
          }}
          placeholder="Type here..."
          value={totalInputScreenText}
          onChange={(e) => {
            if (isLocalLoading) {
              return;
            }

            setState(["totalInputScreenText"], e.target.value);
          }}
          autoFocus
          onFocus={() => {
            setState(["focus"], true);
          }}
          onBlur={() => {
            setState(["focus"], false);
          }}
        />
      </div>
    </div>
  );
};

export default TotalInputScreen;
