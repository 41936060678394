/*
    BCHAR: {
    "userIdToBCharDatum": {
        "uvy3YN1S8UfPios0AAAJ": {
            "x": "50.000000082740655%",
            "y": "0%",
            "lastUpdated": 1728854192623,
            "metaStateA": false,
            "xMomentum": 0,
            "lastX": "50.000000082740655%",
            "lastXMomentum": 0,
            "lastYMomentum": 0,
            "address": "0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
            "bk": "s0",
            "character": "DOGE2",
            "leftDown": false,
            "rightDown": false,
            "lastDirection": "right",
            "jumpDown": false,
            "yMomentum": 0,
            "jumpedLastIteration": 0,
            "attack": 1728853858881,
            "lastY": "0%"
        },
        "_yN0-BCDJOkAVol2AAAN": {
            "x": "50.00000011624954%",
            "y": "0%",
            "lastUpdated": 1728854200744,
            "metaStateA": false,
            "xMomentum": 0,
            "lastX": "50.00000011624954%",
            "lastXMomentum": 0,
            "lastYMomentum": 0,
            "bk": "s0",
            "jumpDown": false,
            "downDown": false,
            "rightDown": false,
            "leftDown": false,
            "lastDirection": "right",
            "yMomentum": 0,
            "jumpedLastIteration": 100,
            "lastY": "0%"
        }
    },
    */

const broadcastWorthyChangeInfo = [
  {
    key: "x",
    type: "string",
  },
  {
    key: "y",
    type: "string",
  },
  {
    key: "xMomentum",
    type: "number",
  },
  {
    key: "yMomentum",
    type: "number",
  },
  {
    key: "character",
    type: "string",
  },
  {
    key: "lastDirection",
    type: "string",
  },
  {
    key: "bk",
    type: "string",
  },
  {
    key: "attack",
    type: "number",
  },
  {
    key: "fashionBottom",
    subKeys: [
      {
        key: "imageUrl",
        type: "string",
      },
      {
        key: "imageUrlMove",
        type: "string",
      },
    ],
    type: "object",
  },
  {
    key: "fashionTop",
    subKeys: [
      {
        key: "imageUrl",
        type: "string",
      },
      {
        key: "imageUrlMove",
        type: "string",
      },
    ],
    type: "object",
  },
  {
    key: "fashionHat",
    subKeys: [
      {
        key: "imageUrl",
        type: "string",
      },
      {
        key: "imageUrlMove",
        type: "string",
      },
    ],
    type: "object",
  },
  {
    key: "address",
    type: "string",
  },
  {
    key: "fun",
    type: "boolean",
  },
  /*
    {
      type: `${CHARACTERS.DOGE}x${CHARACTERS.DOGE_FASHION}xxx0`,
      userIds: [
        dogeXDogeFashion0.dogeBCharDatum.userId,
        dogeXDogeFashion0.dogeFashionBCharDatum.userId,
      ],
      x: currentBCharDatum.x,
      y: currentBCharDatum.y,
    };
  */
  {
    key: "cuddle",
    type: "object",
    subKeys: [
      {
        key: "type",
        type: "string",
      },
      // can ignore level two objects for now
      // {
      //   key: "userIds",
      //   type: "array",
      // },
      {
        key: "x",
        type: "string",
      },
      {
        key: "y",
        type: "string",
      },
    ],
  },
];

const getIfPrimitiveDiff = (key, bCharDatum1, bCharDatum2) => {
  if (bCharDatum1[key] !== bCharDatum2[key]) {
    return true;
  }

  return false;
};

// const BCHAR_LOG_OFF = false;
const BCHAR_LOG_OFF = true;

const logBCharDiff = BCHAR_LOG_OFF
  ? () => {}
  : (key, type, bCharDatum1, bCharDatum2) => {
      console.log(
        "BCHAR DIFF [" +
          type +
          "]: " +
          key +
          " - " +
          JSON.stringify(bCharDatum1[key], null, 2) +
          " - " +
          // bCharDatum2[key]
          JSON.stringify(bCharDatum2[key], null, 2)
      );
    };

/* compare to see if the data has changed
       this function should return true if the data has changed
       in a way such that it should be broadcasted

        => broadcast-worthy changes:
          - x
          - y
          - xMomentum
          - yMomentum
          - character
          - fashionBottom
          - fashionTop
          - fashionHat
          - address
      */
const getIfBCharDatumDiff = (bCharDatum1, bCharDatum2) => {
  for (const changeInfo of broadcastWorthyChangeInfo) {
    const key = changeInfo.key;
    const type = changeInfo.type;

    if (type === "string") {
      if (bCharDatum1[key] !== bCharDatum2[key]) {
        logBCharDiff(key, type, bCharDatum1, bCharDatum2);
        return getIfPrimitiveDiff(key, bCharDatum1, bCharDatum2);
      }
    } else if (type === "number") {
      if (bCharDatum1[key] !== bCharDatum2[key]) {
        logBCharDiff(key, type, bCharDatum1, bCharDatum2);

        return getIfPrimitiveDiff(key, bCharDatum1, bCharDatum2);
      }
    } else if (type === "boolean") {
      if (bCharDatum1[key] !== bCharDatum2[key]) {
        logBCharDiff(key, type, bCharDatum1, bCharDatum2);

        return getIfPrimitiveDiff(key, bCharDatum1, bCharDatum2);
      }
    } else if (type === "object") {
      // simple

      // console.log(
      //   "PRE - BCHAR DIFF OBJECT: " +
      //     key +
      //     " - " +
      //     JSON.stringify(bCharDatum1[key], null, 2) +
      //     " - " +
      //     JSON.stringify(bCharDatum2[key], null, 2)
      // );

      // if (
      //   JSON.stringify(bCharDatum1[key]) !== JSON.stringify(bCharDatum2[key])
      // ) {
      //   logBCharDiff(key, type, bCharDatum1, bCharDatum2);
      //   return true;
      // }

      // complex;
      const subKeys = changeInfo.subKeys;
      if (bCharDatum1[key] && !bCharDatum2[key]) {
        logBCharDiff(`${key}`, type, bCharDatum1, bCharDatum2);
        return true;
      } else if (!bCharDatum1[key] && bCharDatum2[key]) {
        logBCharDiff(`${key}`, type, bCharDatum1, bCharDatum2);
        return true;
      }

      if (bCharDatum1[key]) {
        for (const subKey of subKeys) {
          if (bCharDatum1[key][subKey.key] !== bCharDatum2[key][subKey.key]) {
            logBCharDiff(
              `${key}.${subKey.key}`,
              subKey.type,
              bCharDatum1[key],
              bCharDatum2[key]
            );
            return getIfPrimitiveDiff(key, bCharDatum1, bCharDatum2);
          }
        }
      }
    }
  }
};

export default getIfBCharDatumDiff;
