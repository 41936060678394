import { getState, setState } from "../../../../reactStateManagement";
// import isNPC from "../../../bChar/isNPC";

const handleBCharData = (bCharData) => {
  const selfUserId = getState("userId");

  if (!selfUserId) {
    console.log("handleBCharData: userId not set");

    return;
  }

  const userIdToBCharDatum = bCharData.userIdToBCharDatum;

  const wCharacterData = [];

  // if (bCharData.pcsOnly) {
  //   //

  //   const existingWCharacterData = getState("wCharacterData");

  //   for (let i = 0; i < existingWCharacterData.length; i++) {
  //     const existingWCharacterDatum = existingWCharacterData[i];

  //     if (isNPC(existingWCharacterDatum.character)) {
  //       wCharacterData.push(existingWCharacterDatum);
  //     }
  //   }
  // }

  for (const userId in userIdToBCharDatum) {
    // console.log(`

    //     MEGA LOG ${JSON.stringify(
    //       {
    //         userId,
    //         selfUserId,
    //       },
    //       null,
    //       4
    //     )}
    //     )

    // `);

    if (userId !== selfUserId) {
      const wCharacterDatum = Object.assign({}, userIdToBCharDatum[userId], {
        userId,
      });

      wCharacterData.push(wCharacterDatum);
    }
  }

  // wCharacterData.sort((a, b) => {
  //   return !a.userId.localeCompare(b.userId);
  // });

  // console.log("W COUNT: " + wCharacterData.length);
  // console.log(`set wCharacterData - count: ${wCharacterData.length}`);

  setState(["wCharacterData"], wCharacterData);
};

export default handleBCharData;
