import React from "react";
import {
  // BOTTOM_SECTION_HEIGHT_MOBILE,
  // BOTTOM_SECTION_HEIGHT_DESKTOP,
  // MESSAGE_BOX_WIDTH,
  // EVENT_TYPES,
  // EVENT,
  // EVENT_TYPES,
  Z_INDICES,
  DIALOG_TYPES,
  ITEM_TYPES,
  // NWORLD_UNIT_WIDTH_PERCENT_DECIMAL,
  // NWORLD_MAX_PIXEL_ART_SIZE,
  BROWSER_ONLY_EVENT_TYPES,
  POWER_MODES,
  COLOR_6,
  COLOR_5,
  // COLOR_7,
  // COLOR_4,
  // COLOR_4 as COLOR_A,
} from "../../../constants";
// import { emitEvent } from "../../../utils/emitEvent";
// import getAuth from "../../../utils/getAuth";
import { getState, setState } from "../../../reactStateManagement";
import StagingComponent from "../../../StagingComponent";
import useGameComponent from "../../../utils/effects/useGameComponent";
// import { getMTokenData } from "../../../utils/mToken";
import { SelectButton } from "./SelectButton";
import scan from "../../../utils/world/scan";
// import { getUserData } from "../../../api/getUserData";
// import delay from "../../../utils/delay";
// import scan from "./scan";

// import { doBrowserMint } from "../../../utils/pixels/doBrowserMint";

const MIN_TIME_BETWEEN_SCANS = 1000 * 10; // 10 seconds

// import {
//   getState,
//   // setState
// } from "../../reactStateManagement";

// const MESSAGE_BOX_WIDTH = 175;
// const MESSAGE_BOX_HEIGHT = 150;
// const MESSAGE_BOX_HEIGHT = BOTTOM_SECTION_HEIGHT_MOBILE;

// const updateWorldNumber = (worldNumber) => {
//   const auth = getAuth();

//   emitEvent({
//     type: EVENT_TYPES.UPDATE_WORLD_NUMBER,
//     data: {
//       auth,
//       worldNumber,
//     },
//   });
// };

// const refreshUserDataForSomeTimeCore = async () => {
//   // exponential refresh

//   console.log("load 0");
//   await getUserData({ addToState: true });

//   await delay(1000);

//   console.log("load 1");
//   await getUserData({ addToState: true });

//   await delay(10000);

//   console.log("load 2");
//   await getUserData({ addToState: true });

//   await delay(4000);

//   console.log("load 3");
//   await getUserData({ addToState: true });

//   await delay(8000);

//   console.log("load 4");
//   await getUserData({ addToState: true });

//   await delay(16000);

//   console.log("load 5");
//   await getUserData({ addToState: true });

//   await delay(16000);

//   console.log("load 6");
//   await getUserData({ addToState: true });

//   await delay(16000);

//   console.log("load 7");
//   await getUserData({ addToState: true });

//   await delay(16000);

//   console.log("load 8");
//   await getUserData({ addToState: true });

//   await delay(16000);

//   console.log("load 9");
//   await getUserData({ addToState: true });

//   await delay(16000);

//   console.log("load 10");
//   await getUserData({ addToState: true });

//   await delay(16000);

//   console.log("load 11");
//   await getUserData({ addToState: true });
// };

// const refreshUserDataForSomeTime = async () => {
//   try {
//     await refreshUserDataForSomeTimeCore();
//   } catch (error) {
//     console.log("error in refreshUserDataForSomeTime");
//     console.log(error);
//   }
// };

const selectBuildMode = () => {
  const buildMode = getState("buildMode");

  if (buildMode) {
    setState(["buildMode"], false);
    setState(["putObjectMode"], false);
  } else {
    setState(["buildMode"], true);

    // temporary
    setState(["putObjectMode"], {
      itemType: ITEM_TYPES.TREE_0,
    });
  }
};

// const selectNPaintMode = () => {
//   const mode = getState("nPaintMode");

//   if (mode) {
//     setState(["nPaintMode"], false);
//   } else {
//     setState(["nPaintMode"], true);
//   }
// };

const ButtonRow = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "calc(100% / 3)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};

const NeoControls = () => {
  // const buildMode = getState("buildMode");
  const mobile = getState("mobile");
  // const selectedItems = getState("selectedItems");
  // const selectRectTopLeftX = getState("selectRectTopLeftX");
  // const selectRectTopLeftY = getState("selectRectTopLeftY");
  // const selectRectBottomRightX = getState("selectRectBottomRightX");
  // const selectRectBottomRightY = getState("selectRectBottomRightY");
  // const selectSquareTopLeftX = getState("selectSquareTopLeftX");
  // const selectSquareTopLeftY = getState("selectSquareTopLeftY");
  // const selectSquareBottomRightX = getState("selectSquareBottomRightX");
  // const selectSquareBottomRightY = getState("selectSquareBottomRightY");
  // const pixelSpec = getState("pixelSpec");
  const lastScanTime = getState("lastScanTime");
  const unreadMessages = getState("unreadMessages");

  const scanActive = Date.now() - lastScanTime > MIN_TIME_BETWEEN_SCANS;

  let timeUntilNextScanInSeconds;

  if (!scanActive) {
    timeUntilNextScanInSeconds = Math.ceil(
      (MIN_TIME_BETWEEN_SCANS - (Date.now() - lastScanTime)) / 1000
    );
  }

  // const mobileMode = getState("mobile");

  // const MESSAGE_BOX_HEIGHT = mobileMode
  //   ? BOTTOM_SECTION_HEIGHT_MOBILE
  //   : BOTTOM_SECTION_HEIGHT_DESKTOP;

  // const windowWidth = getState("windowWidth");

  // let width = (windowWidth - MESSAGE_BOX_WIDTH) / 2;

  // if (width > MESSAGE_BOX_WIDTH) {
  //   width = MESSAGE_BOX_WIDTH;
  // }
  const id = "neo-controls";

  useGameComponent({
    id,
  });

  //   selectRectTopLeftX: v(null),
  // selectRectTopLeftY: v(null),
  // selectRectBottomRightX: v(null),
  // selectRectBottomRightY: v(null),

  // const cancelButtonIsActive =
  //   typeof selectRectTopLeftX === "number" ||
  //   typeof selectRectTopLeftY === "number" ||
  //   typeof selectRectBottomRightX === "number" ||
  //   typeof selectRectBottomRightY === "number" ||
  //   selectedItems.length > 0 ||
  //   typeof selectSquareTopLeftX === "number" ||
  //   typeof selectSquareTopLeftY === "number" ||
  //   typeof selectSquareBottomRightX === "number" ||
  //   typeof selectSquareBottomRightY === "number";

  // const mintButtonIsActive = pixelSpec;

  const powerMode = getState("powerMode");

  let powerModeText;

  switch (powerMode) {
    case POWER_MODES.MOON:
      powerModeText = "MOON";
      break;
    case POWER_MODES.POWER:
      powerModeText = "POWER";
      break;
    default:
      powerModeText = "X";
      break;
  }

  return (
    <div
      id={id}
      style={{
        width: "50%",
        height: "100%",
        // backgroundColor: "beige",
        // backgroundColor: "pink",

        // position: "absolute",
        // bottom: 0,
        // left: (windowWidth - MESSAGE_BOX_WIDTH) / 2,
        // right: 0,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        zIndex: Z_INDICES.TOP_LAYER,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "60%",
          // backgroundColor: "red",
          backgroundColor: "transparent",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonRow>
          {/* <StagingComponent
            productionElement={<SelectButton characterNumber={"blank"} />}
          >
            <SelectButton
              mobile={mobile}
              characterNumber={696969696969}
              text={"Bot2"}
              customAction={() => {
                // updateWorldNumber(2);

                const bot2Mode = getState("bot2Mode");

                setState(["bot2Mode"], !bot2Mode);
              }}
              triggerKeys={[]}
            />
          </StagingComponent> */}

          {/* <SelectButton
            characterNumber={696969696969}
            text={"PAINT"}
            customAction={selectNPaintMode}
            triggerKeys={["p", "P"]}
          /> */}
          <SelectButton
            characterNumber={0}
            // text={"POWER"}
            text={powerModeText}
            customAction={() => {
              const $ = window.$;

              const $body = $("body");

              if (!$body.length) {
                return;
              }

              $body.trigger(BROWSER_ONLY_EVENT_TYPES.POWER_MODE_TOGGLE);
            }}
          />

          <SelectButton
            mobile={mobile}
            characterNumber={696969696969}
            text={"CHAT"}
            color={unreadMessages > 0 ? COLOR_5 : undefined}
            customAction={() => {
              // updateWorldNumber(2);

              const messageMode = getState("messageMode");

              setState(["messageMode"], !messageMode);
            }}
            triggerKeys={["c", "C"]}
            badge={unreadMessages > 0 ? unreadMessages : undefined}
          />
          {/* <StagingComponent
            productionElement={<SelectButton characterNumber={"blank"} />}
            overrideStagingOff={true}
          >
            <SelectButton
              characterNumber={696969696969}
              text={"W2"}
              customAction={() => {
                updateWorldNumber(2);
              }}
              triggerKeys={[]}
            />
          </StagingComponent> */}
          <SelectButton
            mobile={mobile}
            characterNumber={696969696969}
            text={"MENU"}
            customAction={() => {
              // updateWorldNumber(1);

              const loginMode = getState("loginMode");

              setState(["loginMode"], !loginMode);
            }}
            triggerKeys={[]}
          />
        </ButtonRow>
        <ButtonRow>
          {/* 
          {mintButtonIsActive ? (
            <SelectButton
              mobile={mobile}
              characterNumber={696969696969}
              text={"Mint"}
              customAction={async () => {
                // console.log(`

                //   MEGA LOG ${JSON.stringify(
                //     {
                //       pixelSpec,
                //     },
                //     null,
                //     4
                //   )}
                //   )

                // `);

                setState(["selectedItems"], []);
                setState(["selectRectTopLeftX"], null);
                setState(["selectRectTopLeftY"], null);
                setState(["selectRectBottomRightX"], null);
                setState(["selectRectBottomRightY"], null);

                setState(["selectSquareTopLeftX"], null);
                setState(["selectSquareTopLeftY"], null);
                setState(["selectSquareBottomRightX"], null);
                setState(["selectSquareBottomRightY"], null);
                setState(["pixelSpec"], null);

                await doBrowserMint({
                  pixelSpecData: pixelSpec,
                });
                // const mTokenData = getMTokenData();

                // if (!mTokenData) {
                //   // alert("Please login first");

                //   setState(["browserAlertData"], {
                //     message: "Please login first",
                //   });

                //   return;
                // }

                // const { address, mToken } = mTokenData;

                // const auth = getAuth();

                // const size = pixelSpec.length;

                // const MAX_SIZE = NWORLD_MAX_PIXEL_ART_SIZE;

                // if (size > MAX_SIZE) {
                //   alert(
                //     `Max size is currently ${MAX_SIZE}x${MAX_SIZE} - ` +
                //       `selected size is ${size}x${size}`
                //   );

                //   return;
                // }

                // const pixelSpecComplete = {
                //   width: size,
                //   height: size,
                //   data: pixelSpec,
                // };

                // emitEvent({
                //   type: EVENT_TYPES.MINT_NFT,
                //   data: {
                //     auth,
                //     address,
                //     mToken,
                //     pixelSpec: pixelSpecComplete,
                //   },
                // });

                // refreshUserDataForSomeTime();
              }}
              triggerKeys={[]}
            />
          ) : (
          )} */}
          <SelectButton characterNumber={"blank"} />
          <StagingComponent
            productionElement={<SelectButton characterNumber={"blank"} />}
          >
            <SelectButton
              mobile={mobile}
              characterNumber={696969696969}
              text={"F1"}
              customAction={() => {
                const dialog = getState("dialog");

                if (dialog && dialog.type === DIALOG_TYPES.EVENT_SENDER) {
                  setState(["dialog"], null);

                  return;
                }

                setState(["dialog"], {
                  type: DIALOG_TYPES.EVENT_SENDER,
                  heightPercentage: 100,
                });
              }}
              // triggered by F11
              triggerKeys={["F1"]}
            />
          </StagingComponent>

          <SelectButton characterNumber={"blank"} />
        </ButtonRow>
        <ButtonRow>
          {/* {cancelButtonIsActive ? (
            <SelectButton
              mobile={mobile}
              characterNumber={696969696969}
              text={"Cancel"}
              customAction={() => {
                setState(["selectRectTopLeftX"], null);
                setState(["selectRectTopLeftY"], null);
                setState(["selectRectBottomRightX"], null);
                setState(["selectRectBottomRightY"], null);
                setState(["selectedItems"], []);

                setState(["selectSquareTopLeftX"], null);
                setState(["selectSquareTopLeftY"], null);
                setState(["selectSquareBottomRightX"], null);
                setState(["selectSquareBottomRightY"], null);
                setState(["pixelSpec"], null);
              }}
              triggerKeys={[]}
            />
          ) : (
            <SelectButton characterNumber={"blank"} />
          )} */}
          <SelectButton
            mobile={mobile}
            characterNumber={696969696969}
            backgroundColor={scanActive ? undefined : COLOR_6}
            // text={"SCAN"}
            text={scanActive ? "SCAN" : String(timeUntilNextScanInSeconds)}
            // color={scanActive ? undefined : COLOR_7}
            customAction={async () => {
              // updateWorldNumber(2);
              // const bot2Mode = getState("bot2Mode");
              // setState(["bot2Mode"], !bot2Mode);

              if (!scanActive) {
                return;
              }

              setState(["lastScanTime"], Date.now());
              await scan();
            }}
            triggerKeys={[]}
          />
          <SelectButton
            mobile={mobile}
            characterNumber={696969696969}
            text={"ZOOM"}
            customAction={() => {
              // updateWorldNumber(1);

              const scale = getState("scale");

              switch (scale) {
                case 1:
                  setState(["scale"], 0.5);
                  break;
                default:
                  setState(["scale"], 1);
                  break;
              }
            }}
            triggerKeys={[]}
          />
          {/* <SelectButton
            mobile={mobile}
            characterNumber={696969696969}
            text={"BOTO"}
            color={unreadMessages > 0 ? COLOR_5 : undefined}
            customAction={() => {
              // updateWorldNumber(2);

              const messageMode = getState("messageMode");

              setState(["messageMode"], !messageMode);
            }}
            triggerKeys={[]}
            badge={unreadMessages > 0 ? unreadMessages : undefined}
          /> */}
          {/* HERE ADD */}
          <SelectButton
            mobile={mobile}
            characterNumber={696969696969}
            text={"BUILD"}
            customAction={selectBuildMode}
            triggerKeys={["b", "B"]}
          />
        </ButtonRow>
      </div>
    </div>
  );
};

export { NeoControls };
