import { getState, setState } from "../../reactStateManagement";
import getDoPromiseInQueue from "../getDoPromiseInQueue";

const doPromiseInQueue = getDoPromiseInQueue();

const OBJECT_ENTRY_EXPIRATION_TIME = 1000 * 60 * 15; // 15 minutes

const updateMessageIdToAudioPlayTime = async (messageId) => {
  const audioPlayTime = Date.now();

  await doPromiseInQueue({
    operation: async () => {
      const messageIdToAudioPlayTime = getState("messageIdToAudioPlayTime");

      const now = Date.now();

      const newMessageIdToAudioPlayTime = {};

      Object.entries(messageIdToAudioPlayTime).forEach(([key, value]) => {
        if (now - value < OBJECT_ENTRY_EXPIRATION_TIME) {
          newMessageIdToAudioPlayTime[key] = value;
        }
      });

      newMessageIdToAudioPlayTime[messageId] = audioPlayTime;

      setState(["messageIdToAudioPlayTime"], newMessageIdToAudioPlayTime);
    },
  });
};

export default updateMessageIdToAudioPlayTime;
