import React, { useEffect } from "react";
import {
  getState,
  // setState
} from "../../reactStateManagement";
import playAudioInQueue from "./playAudioInQueue";

const AudioGhost = () => {
  const audioGhostQueue = getState("audioGhostQueue");

  useEffect(() => {
    if (audioGhostQueue.length > 0) {
      playAudioInQueue();
    }
  }, [audioGhostQueue]);

  return <div></div>;
};

export default AudioGhost;
