import { CHARACTERS, characterToData, NONE } from "../../../../../constants";
import getFashionImage from "../../../../../utils/character/getFashionImage";

var Image = window.Image;

const getTransformations = ({ character }) => {
  switch (character) {
    case CHARACTERS.DOGE:
      return {
        xOffset: 81 + 29.45 + 0.5,
        yOffset: -47 + 25.44,
        scaleFactor: 1.01953125,
      };
    case CHARACTERS.DOGE_FASHION:
      return {
        xOffset: 0,
        yOffset: -41,
        scaleFactor: 1.029,
      };
    default:
      throw new Error(`Character ${character} not supported`);
  }
};

const createCompositeImage = ({
  baseImageUrl = "/images/base_image_black.png",
  characterImageDataUrl,
  scaleFactor = 1,
  xOffset = 0,
  yOffset = 0,
}) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Load base image
    const baseImage = new Image();
    baseImage.onload = () => {
      canvas.width = baseImage.width;
      canvas.height = baseImage.height;

      ctx.drawImage(baseImage, 0, 0);

      // Load character image
      const characterImage = new Image();
      characterImage.onload = () => {
        // Scale the character image
        let newWidth = characterImage.width * scaleFactor;
        let newHeight = characterImage.height * scaleFactor;

        // Calculate position to center the character, then apply offset
        const x = (canvas.width - newWidth) / 2 + xOffset;
        const y = (canvas.height - newHeight) / 2 + yOffset;

        // Draw the character image with scaling and offset
        ctx.drawImage(characterImage, x, y, newWidth, newHeight);

        resolve(canvas.toDataURL());
      };

      characterImage.onerror = reject; // Reject if character image fails to load
      characterImage.src = characterImageDataUrl;
    };

    baseImage.crossOrigin = "Anonymous";
    baseImage.onerror = reject; // Reject if base image fails to load
    baseImage.src = baseImageUrl;
  });
};

const getIsGurr = (character) => {
  switch (character) {
    case CHARACTERS.DOGE:
      return true;
    default:
      return false;
  }
};

const createNFTImage = async ({
  useBaseImage = false,
  character,
  fashionHat,
  fashionTop,
  fashionBottom,
}) => {
  /*
    1. Create or get (in case of useBaseImage) Character Image
    2. Superimpose Character Image with background
  */

  let characterImage;

  if (useBaseImage) {
    const characterDatum = characterToData[character];
    characterImage = characterDatum.imageUrlA;
  } else {
    const overlayImageUrls = [];

    if (fashionBottom && fashionBottom.imageUrl !== NONE) {
      overlayImageUrls.push(fashionBottom.imageUrl);
    } else {
      const censorImageUrl =
        characterToData[character].wardrobe.bottomCensors[0].imageUrl;

      if (!censorImageUrl) {
        throw new Error(
          `Fashion Bottom ${fashionBottom.imageUrl} currently not supported for Doge`
        );
      }

      overlayImageUrls.push(censorImageUrl);
    }

    if (fashionTop && fashionTop.imageUrl !== NONE) {
      overlayImageUrls.push(fashionTop.imageUrl);
    } else {
      const isGurr = getIsGurr(character);
      if (isGurr) {
        const censorImageUrl =
          characterToData[character].wardrobe.topCensors[0].imageUrl;

        if (!censorImageUrl) {
          throw new Error(
            `Fashion Top ${fashionTop.imageUrl} currently not supported for Doge`
          );
        }
        overlayImageUrls.push(censorImageUrl);
      }
    }

    if (fashionHat && fashionHat.imageUrl !== NONE) {
      overlayImageUrls.push(fashionHat.imageUrl);
    }

    // TODO: add bottom censor if exists (for Gurrs and Bois)

    console.log("Base Image Url: ");
    console.log(characterToData[character].wardrobe.base);

    console.log("Overlay Image Urls: ");
    console.log(overlayImageUrls);

    characterImage = await getFashionImage({
      baseImageUrl: characterToData[character].wardrobe.base,
      overlayImageUrls,
    });

    // console.log("Character Image: ");
    // console.log(characterImage);
  }

  const transformations = getTransformations({ character });

  return await createCompositeImage({
    characterImageDataUrl: characterImage,
    ...transformations,
  });
};

export default createNFTImage;
