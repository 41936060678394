// import { CHARACTERS } from "../../constants";
import { LOCAL_STORAGE_KEYS } from "../../constants";
import { getState, setState } from "../../reactStateManagement";
import { getMTokenData } from "../mToken";
import setArbitraryBCharDatumKeyValuePair from "./setArbitraryBCharDatumKeyValuePair";

export const setRespawn = async ({ x, y }) => {
  // setState(["showCoords"], !showCoords);

  const bCharDatum = getState("bCharDatum");

  if (!bCharDatum) {
    return;
  }

  let newBCharDatum = { ...bCharDatum };

  if (x && y) {
    newBCharDatum.x = x;
    newBCharDatum.y = y;
  } else {
    x = newBCharDatum.x;
    y = newBCharDatum.y;
  }

  const respawnData = {
    bCharDatum: newBCharDatum,
  };

  setState(["respawnData"], respawnData);

  const mTokenData = getMTokenData();

  if (!mTokenData) {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.RESPAWN_DATA,
      JSON.stringify(respawnData)
    );
    return;
  }

  //   setState(["loading"], true);

  // const TEST_SKIP = true;

  // if (TEST_SKIP) {
  //   setTimeout(() => {
  //     setState(["loading"], false);
  //   }, 2000);

  //   return;
  // }

  //   console.log(`

  //     MEGA LOG ${JSON.stringify(
  //       {
  //         x,
  //         y,
  //       },
  //       null,
  //       4
  //     )}
  //     )

  //   `);

  try {
    await setArbitraryBCharDatumKeyValuePair({
      keyValuePairs: [
        {
          key: "x",
          value: x,
        },
        {
          key: "y",
          value: y,
        },
      ],
    });
  } catch (err) {
    console.error("Error in set respawn:");
    console.error(err);
  }

  //   setState(["loading"], false);
};
