import { CHARACTERS } from "../../../../constants";
import { DEFAULT_Y_OFFSET, DEFAULT_X_OFFSET } from "./ChatBubble";

export const getSettingsByCharacter = (character, isZoomed) => {
  switch (character) {
    case CHARACTERS.ZUCC:
      return {
        xOffset: -3,
        yOffset: DEFAULT_Y_OFFSET,
      };
    case CHARACTERS.DOGE2:
      return {
        xOffset: isZoomed ? -3 : DEFAULT_X_OFFSET,
        yOffset: isZoomed ? -10 : DEFAULT_Y_OFFSET,
      };
    case CHARACTERS.ELON:
      if (isZoomed) {
        return {
          xOffset: DEFAULT_X_OFFSET,
          yOffset: -30,
        };
      }
      return {
        xOffset: DEFAULT_X_OFFSET,
        yOffset: -22,
      };

    case CHARACTERS.PEPE:
      if (isZoomed) {
        return {
          xOffset: -32,
          yOffset: -25,
        };
      }

      return {
        xOffset: -19,
        yOffset: -17,
      };

    case CHARACTERS.BEARA:
      return {
        xOffset: -10,
        yOffset: DEFAULT_Y_OFFSET,
      };
    default:
      return {
        xOffset: DEFAULT_X_OFFSET,
        yOffset: DEFAULT_Y_OFFSET,
      };
  }
};
