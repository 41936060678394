import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import {
  getState,
  setState,
  setUpReactStateManagement,
} from "./reactStateManagement";
import { NewWorld } from "./mainComponents/NewWorld";
import { chronWindowDims } from "./utils/startUp/chronWindowDims";
// import { ExecutiveBox } from "./mainComponents/ExecutiveBox";
// import { Snack } from "./mainComponents/Snack";
// import { MessageBox } from "./mainComponents/MessageBox";
import { emitEvent } from "./utils/emitEvent";
import {
  ERASER,
  EVENT_TYPES,
  // MOBILE_WIDTH_THRESHOLD
  PRODUCTION_MODE,
} from "./constants";
import getAuth from "./utils/getAuth";
// import { ControlBoxLeft } from "./mainComponents/ControlBoxLeft";
// import { ControlBoxRight } from "./mainComponents/ControlBoxRight";
// import { BuildBox } from "./mainComponents/BuildBox";
import Dialog from "./mainComponents/Dialog";
import { NewControls } from "./mainComponents/NewControls";
import { NewZoomWorld } from "./mainComponents/NewZoomWorld";
import useGameComponent from "./utils/effects/useGameComponent";
import BrowserEventProcessor from "./mainComponents/BrowserEventProcessor/BEventProcessor";
import { bEventLoopRunner } from "./utils/bEventLoop";
import { useExecutive } from "./utils/hooks/useExecutive";
import { getMTokenData } from "./utils/mToken";
import { broventMessageListener } from "./utils/broventMessageListener";
import BrowserAlert from "./mainComponents/BrowserAlert";
import SlideMenu from "./mainComponents/SlideMenu";
import { Coords } from "./mainComponents/Coords";
import { ExecutiveBox } from "./mainComponents/NewControls/ExecutiveBox";
import { doAutoSignIn } from "./utils/browserRealtime/doAutoSignIn";
import { TopStatusDisplay } from "./mainComponents/TopStatusDisplay";
import { GlobalIFrame } from "./mainComponents/GlobalIFrame/GlobalIFrame";
import { AbstractBot6Processor } from "./mainComponents/AbstractBot6Processor/AbstractBot6Processor";
import useCharactersImages from "./utils/appComponent/useCharactersImages";
import BrowserAlertB from "./mainComponents/BrowserAlertB";
import TotalInputScreen from "./mainComponents/TotalInputScreen/TotalInputScreen";
import AudioGhost from "./mainComponents/AudioGhost/AudioGhost";
import TotalFormScreen from "./mainComponents/TotalFormScreen/TotalFormScreen";
import refreshBk from "./utils/bChar/refreshBk";
// import Emitter from "./mainComponents/Emitter";
// import { usePress } from "./mainComponents/NewWorld/Core/usePress";

typeof useGameComponent;
const disco = () => {
  let auth;

  try {
    auth = getAuth();
  } catch (error) {
    //
  }
  if (!auth) {
    return;
  }
  // cleanup
  emitEvent({
    type: EVENT_TYPES.GUEST_LEAVE,
    data: {
      auth,
    },
  });
};

const AppCore = () => {
  // const buildMode = getState("buildMode");
  const mobile = getState("mobile");
  const socket = getState("socket");
  const dialog = getState("dialog");
  const totalInputScreen = getState("totalInputScreen");
  const totalFormScreen = getState("totalFormScreen");
  const loginMode = getState("loginMode");
  const globalIFrameData = getState("globalIFrameData");

  const appCoreElements = [];

  appCoreElements.push(<NewWorld key={"world"} />);
  if (socket) {
    appCoreElements.push(<NewZoomWorld key={"newZoomWorld"} />);
  }
  appCoreElements.push(<NewControls key={"newControls"} />);
  appCoreElements.push(
    <BrowserEventProcessor key={"browserEventProcessor"} mobile={mobile} />
  );
  appCoreElements.push(<BrowserAlert key={"browserAlert"} />);
  appCoreElements.push(<BrowserAlertB key={"browserAlertB"} />);
  appCoreElements.push(<SlideMenu key={"slideMenu"} />);
  // <Coords xPixels={xPixels} yPixels={yPixels} />;
  appCoreElements.push(<Coords key={"coords"} />);

  appCoreElements.push(<TopStatusDisplay key={"topStatusDisplay"} />);

  if (loginMode) {
    appCoreElements.push(<ExecutiveBox key={"executiveBox"} />);
  }

  appCoreElements.push(<AbstractBot6Processor key={"abstractBot6Processor"} />);

  appCoreElements.push(<AudioGhost key={"audioGhost"} />);

  if (!PRODUCTION_MODE) {
    for (const globalIFrameDatum of globalIFrameData) {
      appCoreElements.push(
        <GlobalIFrame
          key={`globalIFrame${globalIFrameDatum.canvasId}`}
          globalIFrameDatum={globalIFrameDatum}
        />
      );
    }
  }

  if (dialog) {
    // appCoreElements.push(<Emitter key={"emitter"} />);
    // appCoreElements.push(<Snack key={"snack"} />);
    // appCoreElements.push(<ExecutiveBox key={"executiveBox"} />);

    // appCoreElements.push(<ControlBoxRight key={"controlBoxRight"} />);

    appCoreElements.push(<Dialog key={"dialog"} dialog={dialog} />);
  }

  if (totalFormScreen) {
    appCoreElements.push(
      <TotalFormScreen
        key={"totalFormScreen"}
        totalFormScreen={totalFormScreen}
      />
    );
  }

  if (totalInputScreen) {
    appCoreElements.push(<TotalInputScreen key={"totalInputScreen"} />);
  }

  return appCoreElements;
};

const topLevelState = {
  hasAttemptedDoAutoSignIn: false,
};

function App() {
  setUpReactStateManagement(useState);

  const partitionIdToNWorldData = getState("partitionIdToNWorldData");
  const nObjectPutBackgroundColor = getState("nObjectPutBackgroundColor");
  const mobileWidthThreshold = getState("mobileWidthThreshold");
  const auth = getAuth(true);

  const [lastUserId, setLastUserId] = useState(null);
  const topLevelStateRef = useRef(topLevelState);

  useEffect(() => {
    chronWindowDims();

    const $ = window.$;

    const width = $(window).width();

    if (width < mobileWidthThreshold) {
      setState(["mobile"], true);
    } else {
      setState(["mobile"], false);
    }
  }, [mobileWidthThreshold]);

  // console.log(`

  //   MEGA LOG ${JSON.stringify(
  //     {
  //       mobileWidthThreshold,
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);

  useEffect(() => {
    // const $ = window.$;

    // listener for window refresh/close

    window.addEventListener("beforeunload", disco);
  }, []);

  useEffect(() => {
    // console.log("process.ENV:");
    // console.log(process.env);
    // console.log(JSON.stringify(process.env, null, 4));
    // temporary
    console.log("Data Center ID:");
    console.log(process.env.REACT_APP_DATA_CENTER_ID);
  }, []);

  useEffect(() => {
    const $ = window.$;

    const focus = () => {
      $(window).focus();
    };

    focus();
    setTimeout(focus, 100);
    setTimeout(focus, 200);
    setTimeout(focus, 500);
    setTimeout(focus, 1000);
  }, []);

  // useRef
  const bEventLoopRunnerHasTriggered = useRef(false);

  useEffect(() => {
    if (bEventLoopRunnerHasTriggered.current) {
      return;
    }
    bEventLoopRunnerHasTriggered.current = true;

    bEventLoopRunner();
  }, []);

  useEffect(() => {
    // const allNWorldData = [];

    // for (const partitionId in partitionIdToNWorldData) {
    //   const nWorldData = partitionIdToNWorldData[partitionId];

    //   allNWorldData.push(...nWorldData);
    // }

    // only get unique nWorldData

    const keyToNWorldDatum = {};

    for (const partitionId in partitionIdToNWorldData) {
      const nWorldData = partitionIdToNWorldData[partitionId];

      for (const nWorldDatum of nWorldData.nWorldData) {
        const key = nWorldDatum.key;

        keyToNWorldDatum[key] = nWorldDatum;
      }
    }

    const localUpdateQueueCombinedBrowser = getState(
      "localUpdateQueueCombinedBrowser"
    );

    const augmentedKeyToNWorldDatum = { ...keyToNWorldDatum };

    for (const datum of localUpdateQueueCombinedBrowser) {
      const type = datum.type;

      if (type === "add") {
        const key = datum.datum.key;

        augmentedKeyToNWorldDatum[key] = datum.datum;
      } else if (type === "remove") {
        const key = datum.datum.key;

        delete augmentedKeyToNWorldDatum[key];
      }
    }

    const allNWorldData = Object.values(augmentedKeyToNWorldDatum);

    setState(["nWorldData"], allNWorldData);
  }, [partitionIdToNWorldData]);

  useExecutive();

  useEffect(() => {
    //
    // console.log("POWER MONKEY:", auth);

    if (auth) {
      const userId = auth.userId;

      if (userId !== lastUserId) {
        setLastUserId(userId);

        // alert("NEW USER ID: " + userId);
      }

      // console.log("POWER MONKEY:", userData);
    }
  }, [auth]);

  useEffect(() => {
    if (lastUserId) {
      const mTokenData = getMTokenData();

      if (mTokenData) {
        // alert("GETTING USER LAND DATA HERE");

        setTimeout(() => {
          emitEvent({
            type: EVENT_TYPES.GET_USER_LAND_DATA,
            data: {
              auth,
              address: mTokenData.address,
              mToken: mTokenData.mToken,
            },
          });
        }, 1000);
      }
    }
  }, [lastUserId]);

  useEffect(() => {
    // add event listener for browser messages

    const $ = window.$;

    $(window).on("brovent", broventMessageListener);

    return () => {
      $(window).off("brovent", broventMessageListener);
    };
  }, []);

  useEffect(() => {
    // nObjectPutBackgroundColor
    // lastSetNObjectPutBackgroundColor

    if (nObjectPutBackgroundColor !== ERASER) {
      setState(["lastSetNObjectPutBackgroundColor"], nObjectPutBackgroundColor);
    }
  }, [nObjectPutBackgroundColor]);

  useEffect(() => {
    if (topLevelStateRef.current.hasAttemptedDoAutoSignIn) {
      return;
    }

    topLevelStateRef.current.hasAttemptedDoAutoSignIn = true;

    doAutoSignIn({
      subtle: true,
      silentFail: true,
    })
      .catch((error) => {
        console.log("error in doAutoSignIn:");
        console.log(error);
      })
      .then(() => {
        refreshBk();
      });
  }, []);

  useEffect(() => {
    if (PRODUCTION_MODE) {
      return;
    }

    const updateConstructionViewMode = () => {
      const constructionViewMode = getState("constructionViewMode");

      setState(["constructionViewMode"], !constructionViewMode);
    };

    const $ = window.$;

    // listen to ";" key

    const handleKeyDown = (event) => {
      if (event.key === ";") {
        updateConstructionViewMode();
      }
    };

    $(window).on("keydown", handleKeyDown);

    return () => {
      $(window).off("keydown", handleKeyDown);
    };
  }, []);

  // useEffect(() => {
  //   console.log("LENGTH OF wCharacterData: " + wCharacterData.length);
  // }, [wCharacterData.length]);

  useCharactersImages();

  // console.log(`

  //   MEGA LOG ${JSON.stringify(
  //     {
  //       nWorldData: getState("nWorldData").length,
  //     },
  //     null,
  //     4
  //   )}

  // `);

  // const bCharDatum = getState("bCharDatum");

  // console.log(`

  //     MEGA LOG ${JSON.stringify(
  //       {
  //         bCharDatum: bCharDatum,
  //       },
  //       null,
  //       4
  //     )}
  //     )

  //   `);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "black",

        position: "absolute",
        top: 0,
        left: 0,

        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",

        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <AppCore />
    </div>
  );
}

export default App;
