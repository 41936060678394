import React from "react";

const QuestionCopySection = ({ question }) => {
  //   const SAMPLE_TEXT = "Why did the chicken cross the road?";

  //   const text = SAMPLE_TEXT;

  const text = question || "";

  return (
    <div
      style={{
        width: "100%",
        height: 40,

        backgroundColor: "darkgreen",

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          // width: "80%",
          width: question ? "80%" : "100%",
          height: "100%",

          backgroundColor: "#340055",
          color: "white",

          // display: "flex",
          // flexDirection: "row",
          // justifyContent: "center",
          // alignItems: "center",

          userSelect: "all",

          overflow: "scroll",
        }}
      >
        <pre
          style={{
            margin: 0,
            color: "white",
          }}
        >
          {text}
        </pre>
      </div>

      {question ? (
        <button
          style={{
            width: "20%",
            height: "100%",

            backgroundColor: "darkgreen",
            color: "white",

            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={async () => {
            const navigator = window.navigator;

            if (!navigator) {
              console.error("No navigator found");
              return;
            }

            try {
              await navigator.clipboard.writeText(text);
            } catch (e) {
              // attempt to use execCommand
              try {
                const textArea = document.createElement("textarea");

                textArea.value = text;

                document.body.appendChild(textArea);

                textArea.select();

                document.execCommand("copy");

                document.body.removeChild(textArea);
              } catch (e2) {
                console.error("Error copying text: ", e2);
              }
            }
          }}
        >
          {"Copy"}
        </button>
      ) : null}
    </div>
  );
};

export default QuestionCopySection;
