import React, { useEffect, useRef, useState } from "react";

const DragDropComponent = ({
  id,
  size = 40,

  value,
  optionData = [{}, {}, {}],
  minHeight = null, // optional
  borderRadius = "50%", // optional
}) => {
  const initialPosition =
    optionData.findIndex((optionDatum) => optionDatum.value === value) || 0;

  const containerRef = useRef(null);
  const draggableRef = useRef(null);
  const [position, setPosition] = useState(initialPosition);
  const [isDragging, setIsDragging] = useState(false);

  const optionCount = optionData.length;

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleTouchStart = () => {
    setIsDragging(true);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;

    const clientX =
      event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
    const containerWidth = containerRef.current.offsetWidth;
    const containerLeft = containerRef.current.getBoundingClientRect().left;
    const newLeft = clientX - containerLeft;

    const newPosition = Math.min(
      Math.max(Math.round((newLeft / containerWidth) * optionCount), 0),
      optionCount - 1
    );

    if (newPosition > optionCount - 1) {
      setPosition(optionCount - 1);
      return;
    }

    setPosition(newPosition);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchMove = (event) => {
    handleMouseMove(event);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (!draggableRef.current || !containerRef.current) {
      return;
    }

    const handleMouseMoveWrapper = (event) => handleMouseMove(event);
    const handleMouseUpWrapper = () => handleMouseUp();
    const handleTouchMoveWrapper = (event) => handleTouchMove(event);
    const handleTouchEndWrapper = () => handleTouchEnd();

    document.addEventListener("mousemove", handleMouseMoveWrapper);
    document.addEventListener("mouseup", handleMouseUpWrapper);
    document.addEventListener("touchmove", handleTouchMoveWrapper);
    document.addEventListener("touchend", handleTouchEndWrapper);

    return () => {
      document.removeEventListener("mousemove", handleMouseMoveWrapper);
      document.removeEventListener("mouseup", handleMouseUpWrapper);
      document.removeEventListener("touchmove", handleTouchMoveWrapper);
      document.removeEventListener("touchend", handleTouchEndWrapper);
    };
  }, [isDragging]);

  useEffect(() => {
    // handle position change

    // console.log("position:", position);

    const optionDatum = optionData[position];

    const onSelect = optionDatum?.onSelect;

    if (typeof onSelect === "function") {
      onSelect();
    }
  }, [position]);

  useEffect(() => {
    // no drag
    const $ = window.$;

    const element = $(`#${id}`);

    const preventDrag = (e) => {
      e.preventDefault();
    };

    const preventMobileDrag = (e) => {
      e.preventDefault();
    };

    element.on("dragstart", preventDrag);
    element.on("touchstart", preventMobileDrag);

    return () => {
      element.off("dragstart", preventDrag);
      element.off("touchstart", preventMobileDrag);
    };
  }, []);

  const fullSize = size * optionCount;

  return (
    <div
      ref={containerRef}
      style={{
        display: "flex",
        flexDirection: "row",
        // height: "40px",
        // height: `${size}px`,

        height: minHeight ? `${minHeight}px` : `${size}px`,
        // width: "120px",

        width: `${fullSize}px`,
        // border: "1px solid #000",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      {/* add horizontal line for visual reference */}

      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "3px",
          backgroundColor: "#440000",
          // top: "50%",
          top: "calc(50% - 1.5px)",
        }}
      />

      <div
        id={id}
        ref={draggableRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        style={{
          position: "absolute",
          // width: "40px",
          // height: "40px",
          width: `${size}px`,
          // height: `${size}px`,
          height: "100%",
          backgroundColor: "#8b0000",
          // top: "-40px",
          left: `${(position / optionCount) * fullSize}px`,
          cursor: "pointer",

          borderRadius,
        }}
      ></div>
      {optionData.map((optionDatum, index) => {
        // const isAtPosition = index === position;

        return (
          <div
            key={index}
            style={{
              // width: "40px",
              // height: "40px",
              width: `${size}px`,
              // height: `${size}px`,
              height: "100%",
              // border: isAtPosition ? "2px solid red" : "1px solid #000",
              boxSizing: "border-box",
              // backgroundColor: isAtPosition ? "#ffcccc" : "#fff",
            }}
            onClick={() => setPosition(index)}
            onTouchStart={() => setPosition(index)}
          ></div>
        );
      })}
    </div>
  );
};

export default DragDropComponent;
