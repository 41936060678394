import React from "react";
import { COLOR_2, COLOR_4, Z_INDICES } from "../../../constants";
import { getState, setState } from "../../../reactStateManagement";

const ShowCoordsToggle = () => {
  const showCoords = getState("showCoords");
  const mobile = getState("mobile");

  const fontSize = mobile ? 12 : "unset";

  return (
    <div
      style={{
        width: "50%",
        height: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        // backgroundColor: "purple",

        zIndex: Z_INDICES.TOP_LAYER,
      }}
    >
      <button
        style={{
          width: "100%",
          height: "100%",
          // backgroundColor: "blue",

          backgroundColor: showCoords ? COLOR_4 : COLOR_2,
          color: "white",
          fontSize,
        }}
        onClick={() => {
          setState(["showCoords"], !showCoords);
        }}
      >
        {"Coord"}
      </button>
    </div>
  );
};

export { ShowCoordsToggle };
