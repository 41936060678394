// import { LOCAL_STORAGE_KEYS } from "../../constants";
// import {
//   //getState,
//   setState,
// } from "../../reactStateManagement";
import {
  DEFAULT_START_X_PERCENT_STRING,
  DEFAULT_START_Y_PERCENT_STRING,
  // LOCAL_STORAGE_KEYS,
} from "../../constants";
// import {
//   getXCoordFromXPercentString,
//   getYCoordFromYPercentString,
// } from "../../coordsMapper";
import { setState } from "../../reactStateManagement";
import updateBChar from "./updateBChar";

/*
  {
      "responseData": {
          "poiPassTechnical": "ppt_324lkealweiwqg38469",
          "username": "Poi319e19e",
          "address": "0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
          "public": true,
          "artPoints": 6106.13374318,
          "visibilities": {
              "USERNAME": false,
              "ART_POINTS": false,
              "CHARACTER_PICTURE": false,
              "METAVERSE_PRESENT": false,
              "ADDRESS": false
          },
          "landClaimLevel": 0,
          "referralCode": "nft_item_land_bonus_0a8ef0c0",
          "xPosition": 0,
          "yPosition": 0,
          "referralCodeData": null,
          "crypDollCount": 0,
          "crypDollCrystals": 0,
          "customPixieCount": 0,
          "customPixieCrystals": 0,
          "minitablePixieJarsCount": 0,
          "minitablePixieJarsCrystals": 0,
          "poiPoiCount": 0,
          "poiPoiCrystals": 0,
          "landsCount": 0,
          "landsCrystals": 0,
          "ultraFlaminCount": 0,
          "ultraFlaminCrystals": 0,
          "fantasticFlamingoCount": 0,
          "fantasticFlamingoCrystals": 0,
          "lonelyFrogCount": 3,
          "lonelyFrogCrystals": 90,
          "cryptoChicksCount": 0,
          "cryptoChicksCrystals": 0,
          "gameCharactersCount": 0,
          "gameCharactersCrystals": 0,
          "primePoints": 16.70513057836848,
          "primeModeOn": false,
          "primeTime": 1673217982337,
          "arbitraryData": {
              "openSeaUrl": "https://opensea.io/DogeCutey",
              "backgroundColor": "purple",
              "worldId": "worldId_0_78ed97b6-f782-4257-9bb5-ce5e17e727f8",
              "desktopBorderBackgroundColor": "#1a1a1a",
              "mobileBorderBackgroundColor": "#1a1a1a",
              "borderBackgroundColor": "#1a1a1a",
              "character": "LIBRA",
              "metaverse_respawnData": {
                  "bCharDatum": {
                      "lastDirection": "left",
                      "metaStateA": false,
                      "lastXMomentum": 0,
                      "leftDown": false,
                      "lastYMomentum": 0,
                      "rightDown": false,
                      "jumpDown": false,
                      "lastUpdated": 1726655859616,
                      "xMomentum": 0,
                      "x": "50.000000070996606%",
                      "yMomentum": 0,
                      "y": "0%",
                      "jumpedLastIteration": 0,
                      "lastY": "0%",
                      "lastX": "50.000000070996606%"
                  }
              },
              "characterX": 39.88095238095239,
              "imageUrl": "abc",
              "nftImageUrl": "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/meeting_v2.png",
              "x": 0,
              "y": 0,
              "pathState": {
                  "path": ""
              },
              "text": "🔥🔥🔥NFT Item To The Mars!!!🚀🚀🚀 🔥🔥🔥NFT Item To The Mars!!!🚀🚀🚀 🔥🔥🔥NFT Item To The Mars!!!🚀🚀🚀 🔥🔥🔥NFT Item To The Mars!!!🚀🚀🚀 🔥🔥🔥NFT Item To The Mars!!!🚀🚀🚀 🔥🔥🔥NFT Item To The Mars!!!🚀🚀🚀",
              "state_bot_nameToUnselectedCommand": {}
          },
          "partyLoginToken": "4047d1de3e43dfb854e58fe10221cbdc",
          "hp": 1085.24458582,
          "characterLevel": 400,
          "userId": "78ed97b6-f782-4257-9bb5-ce5e17e727f8",
          "character": {
              "type": "GAME_CHARACTERS",
              "battleBonus": 400,
              "id": "4"
          },
          "battleMode": true,
          "battleLoseCount": 3,
          "battleWinCount": 4
      }
  }
*/

// const updateBCharDatum = ({ existingBCharDatum, keyValuePairs }) => {
//   // const existingBCharDatum = getState("bCharDatum");

//   const newBCharDatum = {
//     ...existingBCharDatum,
//     ...keyValuePairs,
//   };

//   console.log(
//     "setting bCharDatum using userData: " + JSON.stringify(keyValuePairs)
//   );

//   setState(["bCharDatum"], newBCharDatum);
// };

const setBCharDatumToMetaverseState = ({
  userData,

  shouldUpdateCoordinates = true,
  shouldUpdateCharacter = true,
}) => {
  const x =
    userData?.arbitraryData?.metaverse_bCharDatum?.x ||
    DEFAULT_START_X_PERCENT_STRING;
  const y =
    userData?.arbitraryData?.metaverse_bCharDatum?.y ||
    DEFAULT_START_Y_PERCENT_STRING;

  if (shouldUpdateCoordinates) {
    if (x && y) {
      // const existingBCharDatum = getState("bCharDatum");

      // const newBCharDatum = {
      //   ...existingBCharDatum,
      //   x,
      //   y,
      // };

      console.log("setting bCharDatum using userData: " + `x: ${x}, y: ${y}`);

      // setState(["bCharDatum"], newBCharDatum);

      const existingBCharDatum = userData?.arbitraryData?.metaverse_bCharDatum;

      // updateBCharDatum({
      //   existingBCharDatum,
      //   keyValuePairs: {
      //     x,
      //     y,
      //   },
      // });

      updateBChar({
        mutateFunction: (bCharDatum) => {
          const newBCharDatum = {
            ...bCharDatum,
            x,
            y,
          };
          //     console.log(`

          //   setBCharDatumToMetaverseState LOG ${JSON.stringify(
          //     {
          //       x,
          //       y,
          //       xCoord: getXCoordFromXPercentString(x),
          //       yCoord: getYCoordFromYPercentString(y),
          //       newBCharDatum,
          //     },
          //     null,
          //     4
          //   )}
          //   )

          // `);

          return newBCharDatum;
        },

        bBroadcast: true,
        sourceTag: "setBCharDatumToMetaverseState-coordinates",
      });

      // updateBCharDatum({
      //   existingBCharDatum,
      //   keyValuePairs: {
      //     x,
      //     y,
      //   },
      // });

      const respawnData = {
        bCharDatum: existingBCharDatum,
      };

      // localStorage.setItem(
      //   LOCAL_STORAGE_KEYS.RESPAWN_DATA,
      //   JSON.stringify(respawnData)
      // );

      setState(["respawnData"], respawnData);
    }
  }

  if (shouldUpdateCharacter) {
    const character = userData?.arbitraryData?.metaverse_bCharDatum?.character;

    if (character) {
      // const existingCharacter = getState("character");

      // const newCharacter = {
      //   ...existingCharacter,
      //   character,
      // };

      // console.log(
      //   "setting character using userData: " + `character: ${character}`
      // );

      // setState(["character"], newCharacter);

      // updateBCharDatum({
      //   existingBCharDatum: userData?.arbitraryData?.metaverse_bCharDatum,
      //   keyValuePairs: {
      //     character,
      //   },
      // });

      updateBChar({
        mutateFunction: (bCharDatum) => {
          const newBCharDatum = {
            ...bCharDatum,
            character,
          };

          if (x && y) {
            newBCharDatum.x = x;
            newBCharDatum.y = y;
          }

          const fashionBottom =
            userData?.arbitraryData?.metaverse_bCharDatum?.fashionBottom;

          if (fashionBottom) {
            newBCharDatum.fashionBottom = fashionBottom;
          }

          const fashionTop =
            userData?.arbitraryData?.metaverse_bCharDatum?.fashionTop;

          if (fashionTop) {
            newBCharDatum.fashionTop = fashionTop;
          }

          return newBCharDatum;
        },

        bBroadcast: true,
        sourceTag: "setBCharDatumToMetaverseState-character",
      });
    }
  }
};

export default setBCharDatumToMetaverseState;
