import updateBChar from "./updateBChar";

const refreshBCharLastUpdated = async () => {
  //     await updateBChar = async ({
  //   mutateFunction,
  //   bBroadcast = false,
  //   customCompareBCharDatum,
  //   sourceTag,
  //   callback,
  // })

  await updateBChar({
    mutateFunction: (bCharDatum) => {
      return {
        ...bCharDatum,
        lastUpdated: Date.now(),
      };
    },
    bBroadcast: true,
  });
};

export default refreshBCharLastUpdated;
