export const textLengthToData = (textLength, isZoomed) => {
  if (textLength === 1) {
    return {
      duration: 1000 * 3,
      // fontSize: 50,
      fontSize: isZoomed ? 12 : 50,
    };
  } else if (textLength === 2) {
    return {
      duration: 1000 * 3,
      fontSize: isZoomed ? 12 : 40,
    };
  } else if (textLength === 3) {
    return {
      duration: 1000 * 3,
      fontSize: isZoomed ? 12 : 20,
    };
  } else if (textLength <= 10 && textLength >= 0) {
    return {
      duration: 1000 * 4,
      fontSize: isZoomed ? 5 : 20,
    };
  } else if (textLength <= 20 && textLength > 10) {
    return {
      duration: 1000 * 4.5,
      fontSize: isZoomed ? 5 : 18,
    };
    // return 18;
  } else if (textLength <= 30 && textLength > 20) {
    // return 17;
    return {
      duration: 1000 * 4.5,
      fontSize: isZoomed ? 5 : 17,
    };
  } else if (textLength <= 40 && textLength > 30) {
    // return 17;
    return {
      duration: 1000 * 6,
      fontSize: isZoomed ? 4.5 : 17,
    };
  } else if (textLength <= 50 && textLength > 40) {
    // return 17;
    return {
      duration: 1000 * 7.5,
      fontSize: isZoomed ? 4 : 16,
    };
  } else if (textLength <= 60 && textLength > 50) {
    // return 16;
    return {
      duration: 1000 * 7.5,
      fontSize: isZoomed ? 4 : 15,
    };
  } else if (textLength <= 70 && textLength > 60) {
    // return 14;
    return {
      duration: 1000 * 8,
      fontSize: isZoomed ? 4 : 14,
    };
  } else if (textLength <= 80 && textLength > 70) {
    // return 13;
    return {
      duration: 1000 * 9,
      fontSize: isZoomed ? 4 : 13,
    };
  } else if (textLength <= 90 && textLength > 80) {
    // return 12;
    return {
      duration: 1000 * 10,
      fontSize: isZoomed ? 4 : 12,
    };
  } else if (textLength <= 100 && textLength > 90) {
    // return 12;
    return {
      duration: 1000 * 11,
      fontSize: isZoomed ? 4 : 12,
    };
  }
  // } else if (textLength <= 110 && textLength > 100) {
  //   return 11;
  // } else if (textLength <= 120 && textLength > 110) {
  //   return 11;
  // } else if (textLength <= 130 && textLength > 120) {
  //   return 11;
  // }
  // return 11;
  return {
    duration: 1000 * 12,
    // fontSize: 11,
    fontSize: isZoomed ? 4 : 12,
  };
};
