import React from "react";
// import React, {
//   useEffect,
//   useState,
//   // useState
// } from "react";
import {
  // COLOR_0,
  COLOR_1,
  // CHARACTERS,
  // COLOR_2,
  // COLOR_4,
  COLOR_5,
  STANDARD_BUTTON_HEIGHT,
  // Z_INDICES,
} from "../../../constants";
import {
  //getState,
  setState,
} from "../../../reactStateManagement";
// import { getState, setState } from "../../../reactStateManagement";

const backgroundColorPure = "#2f0000";
const disabledTextColor = "#360000";

const MenuPageSelect = ({ menuPageNumber, totalNumberOfPages }) => {
  const setMenuPageNumber = (newMenuPageNumber) => {
    setState(["menuPageNumber"], newMenuPageNumber);
  };

  const onFirstPage = menuPageNumber <= 0;
  const onLastPage = menuPageNumber >= totalNumberOfPages - 1;

  const thereIsMoreThanOnePage = totalNumberOfPages > 1;

  const backgroundColor = thereIsMoreThanOnePage
    ? backgroundColorPure
    : "black";

  return (
    <div
      style={{
        width: "100%",
        height: thereIsMoreThanOnePage ? STANDARD_BUTTON_HEIGHT : 0,

        backgroundColor,
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        zIndex: 2,

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {thereIsMoreThanOnePage ? (
        <button
          style={{
            width: "50%",
            height: "100%",

            backgroundColor,

            // color: COLOR_1,
            color: onFirstPage ? disabledTextColor : COLOR_1,
          }}
          disabled={onFirstPage}
          onClick={() => {
            let newMenuPageNumber = menuPageNumber - 1;

            if (newMenuPageNumber < 0) {
              newMenuPageNumber = 0;
            }

            // console.log(`

            //   MEGA LOG ${JSON.stringify(
            //     {
            //       menuPageNumber,
            //       totalNumberOfPages,
            //       newMenuPageNumber,
            //     },
            //     null,
            //     4
            //   )}
            //   )

            // `);

            setMenuPageNumber(newMenuPageNumber);
          }}
        >
          {"<"}
        </button>
      ) : null}
      {thereIsMoreThanOnePage ? (
        <button
          style={{
            width: "50%",
            height: "100%",

            backgroundColor,

            // color: COLOR_1,
            color: onLastPage ? disabledTextColor : COLOR_1,
          }}
          disabled={onLastPage}
          onClick={() => {
            let newMenuPageNumber = menuPageNumber + 1;

            if (newMenuPageNumber >= totalNumberOfPages) {
              newMenuPageNumber = totalNumberOfPages - 1;
            }

            // console.log(`

            //   MEGA LOG ${JSON.stringify(
            //     {
            //       menuPageNumber,
            //       totalNumberOfPages,
            //       newMenuPageNumber,
            //     },
            //     null,
            //     4
            //   )}
            //   )

            // `);

            setMenuPageNumber(newMenuPageNumber);
          }}
        >
          {">"}
        </button>
      ) : null}
    </div>
  );
};

export { MenuPageSelect };
