import React from "react";
import { setState } from "../../reactStateManagement";
import {
  getLabelFromBBFormId,
  TOTAL_FORM_SCREEN_FORM_TYPES,
  // EVENT_TYPES,
  Z_INDICES,
} from "../../constants";
import { validateForm } from "./validateForm";
import TextFormElement from "./formElements/TextFormElement";
import handleSubmitTFS from "./handleSubmitTFS";
import QuestionCopySection from "./QuestionCopySection";

const close = () => {
  setState(["totalFormScreen"], null);
  // setState(["totalformScreenText"], "");
  setState(["focus"], false);
};

const TotalFormScreen = ({ totalFormScreen }) => {
  const [
    isLocalLoading,
    //setIsLocalLoading
  ] = React.useState(false);

  const [formValues, setFormValues] = React.useState({});
  const [formIsValid, setFormIsValid] = React.useState(false);
  const [formFilter, setFormFilter] = React.useState("");

  // const formIsValid = validateForm(initialFormValues);

  const formElements = totalFormScreen?.formElements || [];
  const formName = totalFormScreen?.formName;

  React.useEffect(() => {
    if (!totalFormScreen) {
      close();

      return;
    }

    if (!formElements || formElements.length === 0) {
      close();

      return;
    }

    if (!formName || typeof formName !== "string") {
      close();

      return;
    }
  }, [totalFormScreen, formElements, formName]);

  React.useEffect(() => {
    const initialFormValues = {};

    for (const formElement of formElements) {
      if (formElement.type === TOTAL_FORM_SCREEN_FORM_TYPES.TEXT) {
        initialFormValues[formElement.formId] = {
          formId: formElement.formId,
          type: TOTAL_FORM_SCREEN_FORM_TYPES.TEXT,
          value: formElement.value,
        };
      }
    }

    setFormValues(initialFormValues);
  }, []);

  React.useEffect(() => {
    setFormIsValid(validateForm(formValues));
  }, [formValues]);

  React.useEffect(() => {
    const $ = window.$;

    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        close();
      }
    };

    $(document).on("keydown", handleKeyDown);

    return () => {
      $(document).off("keydown", handleKeyDown);
    };
  }, []);

  const submitEnabled = formIsValid && !isLocalLoading;

  return (
    <div
      id={"total-form-screen-container"}
      style={{
        position: "absolute",
        top: 0,
        left: 0,

        width: "100%",
        height: "100%",
        zIndex: Z_INDICES.DIALOG_LAYER * 2,
      }}
      // onClick={(e) => {
      //   // check if id is of container
      //   if (e.target.id === "total-form-screen-container") {
      //     close();
      //   }
      // }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: 25,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          backgroundColor: "black",
          color: "white",

          fontSize: 20,
          zIndex: Z_INDICES.DIALOG_LAYER * 2 + 1,

          userSelect: "all",
        }}
      >
        {formName}
      </div>

      <input
        style={{
          position: "absolute",
          top: 25,
          left: 0,

          width: "100%",
          height: 25,

          boxSizing: "border-box",
          border: "none",
          outline: "none",

          textAlign: "center",

          backgroundColor: "rgba(0, 0, 0, 0.6)",
          color: "white",

          zIndex: Z_INDICES.DIALOG_LAYER * 2,
        }}
        placeholder="Search"
        type="text"
        value={formFilter}
        onChange={(e) => {
          setFormFilter(e.target.value);
        }}
        onFocus={() => {
          setState(["focus"], true);
        }}
        onBlur={() => {
          setState(["focus"], false);
        }}
      ></input>

      <button
        style={{
          position: "absolute",
          top: 0,
          right: 0,

          width: 35,
          height: 35,

          backgroundColor: "black",
          color: "white",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          zIndex: Z_INDICES.DIALOG_LAYER * 2 + 1,

          fontSize: 20,
        }}
        onClick={close}
      >
        {"x"}
      </button>

      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,

          width: "100%",
          height: "100%",

          backgroundColor: "rgba(222, 0, 0, 0.6)",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        // onClick={() => {}}
      >
        <div
          className="bb-power-edit"
          id="tfs-form"
          style={{
            position: "absolute",
            top: 50,

            width: "100%",
            height: "80%",
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "space-around",
            // alignItems: "center",

            display: "block",

            overflowY: "scroll",

            // custom scrollbar
            scrollbarWidth: "thin",
            scrollbarColor: "darkred black",
          }}
        >
          {formElements
            .filter((formElement) => {
              if (formFilter === "") {
                return true;
              }

              const formId = formElement.formId || "";

              const label = getLabelFromBBFormId(formId);

              if (label.includes(formFilter)) {
                return true;
              }

              return false;
            })
            .map((formElement, index) => {
              if (formElement.type === TOTAL_FORM_SCREEN_FORM_TYPES.TEXT) {
                return (
                  <div
                    key={index}
                    style={{
                      width: "100%",

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <QuestionCopySection question={formElement.question} />
                    <TextFormElement
                      formValues={formValues}
                      setFormValues={setFormValues}
                      formId={formElement.formId}
                      value={formElement.value}
                    />
                  </div>
                );
              }

              return null;
            })}

          {/* test element for large heights */}
          {Math.abs(1) === 0 ? (
            <div
              style={{
                width: "100%",
                height: 1000,
                backgroundColor: "black",
                opacity: 0.7,
                color: "white",
              }}
            >
              {"test"}
            </div>
          ) : null}

          <button
            style={{
              width: "85%",
              minWidth: 200,
              height: 50,
              backgroundColor: submitEnabled ? "darkgreen" : "gray",

              color: "white",
            }}
            // type="submit"
            disabled={!submitEnabled}
            onClick={async (e) => {
              e.preventDefault();

              console.log("Submitting form with values:", formValues);

              await handleSubmitTFS(formValues);

              close();
            }}
          >
            {"Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TotalFormScreen;
