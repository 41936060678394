import { TOTAL_FORM_SCREEN_FORM_TYPES } from "../../../constants";

export const doSetTextFormValues = (
  setFormValues,
  formValues,
  formId,
  value
) => {
  setFormValues({
    ...formValues,
    [formId]: {
      type: TOTAL_FORM_SCREEN_FORM_TYPES.TEXT,
      value,
      formId,
    },
  });
};
