// import React from "react";
import React, { useEffect } from "react";
import {
  BROVENT_KEYS,
  BROWSER_ONLY_EVENT_TYPES,
  ERASER,
  EVENT_TYPES,
  POWER_MODES_LIST,
} from "../../constants";
import { doBMoveUpdate } from "./doBMoveUpdate";
import getDoPromiseInQueue from "../../utils/getDoPromiseInQueue";
import { getState, setState } from "../../reactStateManagement";
// import { getState } from "../../reactStateManagement";
// import { emitEvent } from "../../utils/emitEvent";
// import getAuth from "../../utils/getAuth";
// import { EVENT_TYPES } from "../../constants";

const doPromiseInQueue = getDoPromiseInQueue();

const getHandleKey = (key, on, mobile) => {
  return () => {
    // console.log(`getHandleKey - ${key} - ${on}`);

    if (mobile) {
      doPromiseInQueue({
        operation: async () => {
          doBMoveUpdate({
            key,
            on,
          });
        },
      });
      return;
    }

    doBMoveUpdate({
      key,
      on,
    });
  };
};

const handleEPress = () => {
  const nObjectPutBackgroundColor = getState("nObjectPutBackgroundColor");

  if (nObjectPutBackgroundColor === ERASER) {
    const lastSetNObjectPutBackgroundColor =
      getState("lastSetNObjectPutBackgroundColor") || null;

    setState(["nObjectPutBackgroundColor"], lastSetNObjectPutBackgroundColor);
  } else {
    setState(["nObjectPutBackgroundColor"], ERASER);
  }
};

const handlePowerModeToggle = () => {
  const powerMode = getState("powerMode");

  let index = POWER_MODES_LIST.findIndex((mode) => mode === powerMode);

  if (index === -1) {
    index = 0;
  }

  const nextIndex = (index + 1) % POWER_MODES_LIST.length;

  const newPowerMode = POWER_MODES_LIST[nextIndex];

  setState(["powerMode"], newPowerMode);
};

const BrowserEventProcessor = ({ mobile }) => {
  useEffect(() => {
    // create custom event listener for custom keydown emit

    const $ = window.$;

    const core = $("body");

    if (!core.length) {
      return;
    }

    const handleRightKeyDown = getHandleKey(
      BROVENT_KEYS.rightDown,
      true,
      mobile
    );
    const handleRightKeyUp = getHandleKey(
      BROVENT_KEYS.rightDown,
      false,
      mobile
    );
    const handleLeftKeyDown = getHandleKey(BROVENT_KEYS.leftDown, true, mobile);
    const handleLeftKeyUp = getHandleKey(BROVENT_KEYS.leftDown, false, mobile);
    const handleUpKeyDown = getHandleKey(BROVENT_KEYS.jumpDown, true, mobile);
    const handleUpKeyUp = getHandleKey(BROVENT_KEYS.jumpDown, false, mobile);
    const handleDownKeyDown = getHandleKey(BROVENT_KEYS.downDown, true, mobile);
    const handleDownKeyUp = getHandleKey(BROVENT_KEYS.downDown, false, mobile);

    const rightDownEvent = EVENT_TYPES.W_MOVE_RIGHT_ON;
    const rightUpEvent = EVENT_TYPES.W_MOVE_RIGHT_OFF;
    const leftDownEvent = EVENT_TYPES.W_MOVE_LEFT_ON;
    const leftUpEvent = EVENT_TYPES.W_MOVE_LEFT_OFF;
    const upDownEvent = EVENT_TYPES.W_MOVE_JUMP_ON;
    const upUpEvent = EVENT_TYPES.W_MOVE_JUMP_OFF;
    const downDownEvent = EVENT_TYPES.W_MOVE_DOWN_ON;
    const downUpEvent = EVENT_TYPES.W_MOVE_DOWN_OFF;
    const ePressEvent = BROWSER_ONLY_EVENT_TYPES.E_PRESS;
    const powerModeToggleEvent = BROWSER_ONLY_EVENT_TYPES.POWER_MODE_TOGGLE;

    core.on(rightDownEvent, handleRightKeyDown);
    core.on(rightUpEvent, handleRightKeyUp);
    core.on(leftDownEvent, handleLeftKeyDown);
    core.on(leftUpEvent, handleLeftKeyUp);
    core.on(upDownEvent, handleUpKeyDown);
    core.on(upUpEvent, handleUpKeyUp);
    core.on(downDownEvent, handleDownKeyDown);
    core.on(downUpEvent, handleDownKeyUp);
    core.on(ePressEvent, handleEPress);
    core.on(powerModeToggleEvent, handlePowerModeToggle);

    return () => {
      core.off(rightDownEvent, handleRightKeyDown);
      core.off(rightUpEvent, handleRightKeyUp);
      core.off(leftDownEvent, handleLeftKeyDown);
      core.off(leftUpEvent, handleLeftKeyUp);
      core.off(upDownEvent, handleUpKeyDown);
      core.off(upUpEvent, handleUpKeyUp);
      core.off(ePressEvent, handleEPress);
      core.off(powerModeToggleEvent, handlePowerModeToggle);
    };
  }, [mobile]);

  return (
    <div
      id="browser-event-processor"
      style={{
        width: 0,
        height: 0,

        backgroundColor: "red",

        position: "absolute",

        top: 0,
        left: 100,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    ></div>
  );
};

export default BrowserEventProcessor;
