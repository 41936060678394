import { CHARACTERS, NONE } from "../../../../../constants";

// const V2 = "V2";

// const GURR = "Gurr";
// const BOI = "Boi";

// const CENSOR = "Censor";

const ATTRIBUTES = {
  types: {
    // GURR: {
    //   trait_type: "Type",
    //   value: GURR,
    // },
    // BOI: {
    //   trait_type: "Type",
    //   value: BOI,
    // },

    LIBRA: {
      trait_type: "Type",
      value: "Libra",
    },

    DOGE: {
      trait_type: "Type",
      value: "Blueberry",
    },
  },

  hats: {
    OG: {
      trait_type: "Hat",
      value: "OG",
    },
  },

  tops: {
    // CENSOR: {
    //   trait_type: "Top",
    //   value: CENSOR,
    // },
    BLUE_HEART_DRESS: {
      trait_type: "Top",
      value: "Blue Heart Dress",
    },
    WHITE_DRESS_SHIRT: {
      trait_type: "Top",
      value: "White Dress Shirt",
    },
    PURPLE_TUBE_TOP: {
      trait_type: "Top",
      value: "Purple Tube Top",
    },
  },

  bottoms: {
    // CENSOR: {
    //   trait_type: "Bottom",
    //   value: CENSOR,
    // },
    PINK_PANTIES: {
      trait_type: "Bottom",
      value: "Hot Pink Undies",
    },
    DESIGNER_JEANS: {
      trait_type: "Bottom",
      value: "Designer Jeans",
    },
    PURPLE_MINISKIRT: {
      trait_type: "Bottom",
      value: "Purple Miniskirt",
    },
  },
};

const getNFTAttributesData = ({
  character,
  fashionHat,
  fashionTop,
  fashionBottom,
  backgroundImageUrl,
}) => {
  let attributes = [
    // {
    //   trait_type: "Version",
    //   value: V2,
    // },
  ];

  // const baseAttributesLength = attributes.length;

  let useBaseImage = false;

  switch (character) {
    case CHARACTERS.DOGE: {
      // attributes.push(ATTRIBUTES.types.GURR);
      attributes.push(ATTRIBUTES.types.LIBRA);

      if (fashionHat) {
        /*
          "fashionTop": {
                "imageUrl": "/images/libra_0_top_0.png",
                "imageUrlMove": "/images/libra_1_top_0.png"
            },
        */

        // match the imageUrl and imageUrlMove to the fashionHat

        switch (fashionHat.imageUrl) {
          case "/images/libra_0_hat_0.png":
            attributes.push(ATTRIBUTES.hats.OG);
            break;
          case NONE:
            break;
          default:
            throw new Error(
              `Fashion Hat ${fashionHat.imageUrl} currently not supported for Libra`
            );
        }
      }

      if (fashionTop) {
        /*
                "fashionTop": {
                    "imageUrl": "/images/libra_0_top_0.png",
                    "imageUrlMove": "/images/libra_1_top_0.png"
                },
            */

        // match the imageUrl and imageUrlMove to the fashionTop

        switch (fashionTop.imageUrl) {
          case "/images/libra_0_top_0.png":
            attributes.push(ATTRIBUTES.tops.BLUE_HEART_DRESS);
            break;
          case NONE:
            // attributes.push(ATTRIBUTES.tops.CENSOR);
            break;
          default:
            throw new Error(
              `Fashion Top ${fashionTop.imageUrl} currently not supported for Libra`
            );
        }
      }

      if (fashionBottom) {
        switch (fashionBottom.imageUrl) {
          case "/images/libra_0_bottom_0.png":
            attributes.push(ATTRIBUTES.bottoms.PINK_PANTIES);
            break;
          case NONE:
            //   attributes.push(ATTRIBUTES.bottoms.CENSOR);
            break;
          default:
            throw new Error(
              `Fashion Bottom ${fashionBottom.imageUrl} currently not supported for Libra`
            );
        }
      }

      const listOfFashionItems = [fashionHat, fashionTop, fashionBottom];

      const listOfFashionItemsThatHaveExplicityBeenSet =
        listOfFashionItems.filter((fashionItem) => fashionItem);

      if (
        // attributes.length === baseAttributesLength + 1
        listOfFashionItemsThatHaveExplicityBeenSet.length === 0
      ) {
        attributes.push(
          ATTRIBUTES.hats.OG,
          ATTRIBUTES.tops.WHITE_DRESS_SHIRT,
          ATTRIBUTES.bottoms.DESIGNER_JEANS
        );

        useBaseImage = true;
      }

      break;
    }
    case CHARACTERS.DOGE_FASHION: {
      // attributes.push(ATTRIBUTES.types.BOI);
      attributes.push(ATTRIBUTES.types.DOGE);

      if (fashionTop) {
        switch (fashionTop.imageUrl) {
          case "/images/dogexfashion_0_top_0.png":
            attributes.push(ATTRIBUTES.tops.PURPLE_TUBE_TOP);
            break;
          case NONE:
            break;
          default:
            throw new Error(
              `Fashion Top ${fashionTop.imageUrl} currently not supported for Doge`
            );
        }
      }

      if (fashionBottom) {
        switch (fashionBottom.imageUrl) {
          case "/images/dogexfashion_0_bottom_0.png":
            attributes.push(ATTRIBUTES.bottoms.PURPLE_MINISKIRT);
            break;
          case NONE:
            // attributes.push(ATTRIBUTES.bottoms.CENSOR);
            break;
          default:
            throw new Error(
              `Fashion Bottom ${fashionBottom.imageUrl} currently not supported for Doge`
            );
        }
      }

      const listOfFashionItems = [fashionHat, fashionTop, fashionBottom];

      const listOfFashionItemsThatHaveExplicityBeenSet =
        listOfFashionItems.filter((fashionItem) => fashionItem);

      if (
        // attributes.length === baseAttributesLength + 1
        listOfFashionItemsThatHaveExplicityBeenSet.length === 0
      ) {
        attributes.push(
          ATTRIBUTES.tops.PURPLE_TUBE_TOP,
          ATTRIBUTES.bottoms.PURPLE_MINISKIRT
        );

        useBaseImage = true;
      }

      // if (attributes.length === baseAttributesLength + 1) {
      //   attributes.push(
      //     ATTRIBUTES.tops.PURPLE_TUBE_TOP,
      //     ATTRIBUTES.bottoms.PURPLE_MINISKIRT
      //   );

      //   useBaseImage = true;
      // }

      break;
    }
    default:
      throw new Error(
        `Character ${character} currently not supported for NFTs`
      );
  }

  switch (backgroundImageUrl) {
    case "/images/base_image_black.png":
      attributes.push({
        trait_type: "Background",
        value: "Black",
      });
      break;
    default:
      throw new Error(
        `Background Image ${backgroundImageUrl} currently not supported for NFTs`
      );
  }

  return {
    attributes,
    useBaseImage,
  };
};

export default getNFTAttributesData;
