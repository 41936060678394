import React from "react";
import {
  getState,
  setState,
  // setState
} from "../../reactStateManagement";
import { Z_INDICES } from "../../constants";
import useGameComponent from "../../utils/effects/useGameComponent";
import "./browserAlertB.css";

const MESSAGE_TIME = 4000;
const FINISH_TIME = 1000;

const BrowserAlertB = () => {
  const browserAlertDataB = getState(["browserAlertDataB"]);
  const [messageHasExpired, setMessageHasExpired] = React.useState(false);

  const id = "browser-alert-b";

  useGameComponent({ id });

  React.useEffect(() => {
    // if

    if (
      !browserAlertDataB ||
      !browserAlertDataB.message ||
      !browserAlertDataB.time
    ) {
      setMessageHasExpired(false);
      return;
    }

    const time = browserAlertDataB.time;

    const checkIfMessageHasExpired = () => {
      const currentTime = Date.now();

      if (currentTime - time > MESSAGE_TIME) {
        setMessageHasExpired(true);
      }
    };

    const interval = setInterval(() => {
      checkIfMessageHasExpired();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [browserAlertDataB]);

  React.useEffect(() => {
    if (messageHasExpired) {
      setState(["browserAlertDataB"], null);
    }
  }, [messageHasExpired]);

  if (!browserAlertDataB || !browserAlertDataB.message) {
    return null;
  }

  const message = browserAlertDataB.message;

  const isFinishMode =
    Date.now() - browserAlertDataB.time > MESSAGE_TIME - FINISH_TIME;

  const className = isFinishMode ? "browser-alert-b-finish" : "";

  return (
    <div
      // id={"browser-alert"}
      className={className}
      id={id}
      style={{
        // width: '100%'
        // height: BROWSER_ALERT_MAX_HEIGHT,
        position: "absolute",
        top: 60,
        left: 0,

        // opacity: 0.8,
        backgroundColor: "transparent",

        zIndex: Z_INDICES.DIALOG_LAYER + 2,

        color: "red",
      }}
      // onClick={(e) => {
      //   if (e.target.id === "browser-alert") {
      //     setState(["browserAlertData"], null);
      //   }
      // }}
    >
      {/* {message + `${Date.now() - browserAlertDataB.time}`} */}
      {message}
    </div>
  );
};

export default BrowserAlertB;
