const compareAudioGhostQueueElements = (a, b) => {
  // if (
  //   a?.messageId === b?.messageId &&
  //   (a?.text === b?.text || a?.audioBufferString === b?.audioBufferString)
  // ) {
  //   return true;
  // }

  // return false;

  const aHasNoMessageId = !a?.messageId;

  if (aHasNoMessageId) {
    return false;
  }

  const messageIdA = a.messageId;

  const bHasNoMessageId = !b?.messageId;

  if (bHasNoMessageId) {
    return false;
  }

  const messageIdB = b.messageId;

  const theyHaveDifferentMessageIds = messageIdA !== messageIdB;

  if (theyHaveDifferentMessageIds) {
    return false;
  }

  const aHasNoText = !a?.text;

  if (aHasNoText) {
    return false;
  }

  const textA = a.text;

  const bHasNoText = !b?.text;

  if (bHasNoText) {
    return false;
  }

  const textB = b.text;

  const theyHaveDifferentTexts = textA !== textB;

  if (theyHaveDifferentTexts) {
    return false;
  }

  const aHasNoAudioBufferString = !a?.audioBufferString;

  if (aHasNoAudioBufferString) {
    return false;
  }

  const audioBufferStringA = a.audioBufferString;

  const bHasNoAudioBufferString = !b?.audioBufferString;

  if (bHasNoAudioBufferString) {
    return false;
  }

  const audioBufferStringB = b.audioBufferString;

  const theyHaveDifferentAudioBufferStrings =
    audioBufferStringA !== audioBufferStringB;

  if (theyHaveDifferentAudioBufferStrings) {
    return false;
  }

  return true;
};

export default compareAudioGhostQueueElements;
