// import React, { useEffect } from "react";
import React from "react";
import { DisplayText } from "../DisplayText";
import { UNIT_WIDTH, Z_1 } from "../nzwConstants";
import { putNObject } from "../NBuildGrid/putNObject";
// import { NWORLD_TOTAL_WIDTH } from "../../../worldConstants";
import {
  getXPixelsFromXDecimalPercent,
  getYPixelsFromYDecimalPercent,
} from "../../../coordsMapper";
import StagingComponent from "../../../StagingComponent";
import { getState, setState } from "../../../reactStateManagement";
import useGameComponent from "../../../utils/effects/useGameComponent";
import {
  CSS_DEFAULT_ANIMATION_TRANSITION,
  ITEM_TYPES,
} from "../../../constants";

/*
Using JQuery, a parent element has a listener that prevents the default for all "contextmenu", "dblclick", and "touchend" events.

I have a child event that I want to allow touch presses and currently they are being blocked by the parent listener.

CardboardBox is a child element that I want to allow touch presses on.
*/
const DEBUG_LOG_OFF = true;

const getHandleClickOrPress = ({
  x,
  y,
  buildMode,
  mobile,
  currentBackgroundColor,
  pressedDownOnBuildPlane,
}) => {
  return (forceDelete = false) => {
    if (!DEBUG_LOG_OFF) {
      console.log(`press ${x}, ${y}`);
    }

    if (!buildMode) {
      return;
    }
    const nObjectPutBackgroundColor = getState("nObjectPutBackgroundColor");

    const attemptingToPutSameColor =
      nObjectPutBackgroundColor === currentBackgroundColor;

    if (
      mobile ||
      (attemptingToPutSameColor && !pressedDownOnBuildPlane) ||
      forceDelete
    ) {
      // delete

      putNObject({
        xDecimalPercent: x,
        yDecimalPercent: y,

        itemType: null,
      });

      return;
    }

    if (!nObjectPutBackgroundColor) {
      // console.log("No nObjectPutBackgroundColor");
      return;
    }

    putNObject({
      xDecimalPercent: x,
      yDecimalPercent: y,

      // itemType: null,
      itemType: ITEM_TYPES.TREE_0,
    });
  };
};

const getIfBackgroundColorIsNonHex = (backgroundColor) => {
  for (let i = 0; i < backgroundColor.length; i++) {
    const char = backgroundColor[i];

    if (char !== "#" && isNaN(parseInt(char, 16))) {
      return true;
    }
  }

  return false;
};

export const CardboardBox = ({
  x,
  minX,
  minY,
  y,
  imageUrl,
  backgroundColor,
  isSelected,
  pressedDownOnBuildPlane,
  mobile,
}) => {
  const buildMode = getState("buildMode");
  const xPixel = getXPixelsFromXDecimalPercent(x);
  const yPixel = getYPixelsFromYDecimalPercent(y);
  const itemX = xPixel - minX;
  const xScientific = x.toExponential(4);
  const handleClickOrPress = getHandleClickOrPress({
    x,
    y,
    buildMode,
    mobile,
    currentBackgroundColor: backgroundColor,
    pressedDownOnBuildPlane,
  });

  const id = `cardboardBox-${xPixel}-${yPixel}`;

  useGameComponent({
    id,
  });

  const bottom = yPixel - minY;

  let backgroundColorToUse;
  let backgroundImageToUse = "unset";
  let imageElement;

  if (imageUrl) {
    imageElement = (
      <img
        src={imageUrl}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",

          position: "absolute",
          left: 0,
          top: 0,
        }}
      />
    );
  } else {
    if (backgroundColor) {
      backgroundColorToUse = backgroundColor;

      const backgroundColorContainsNonHex =
        getIfBackgroundColorIsNonHex(backgroundColor);

      if (backgroundColorContainsNonHex) {
        // use rainbow to indicate non-hex color

        backgroundImageToUse =
          "linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet)";
        backgroundColorToUse = "unset";
      }
    } else {
      backgroundColorToUse = "#8B0000";
    }

    imageElement = null;
  }

  return (
    <div
      id={id}
      // className="slow-vertical-animation"
      style={{
        width: UNIT_WIDTH,
        height: UNIT_WIDTH,

        border: isSelected ? "2px solid lightblue" : "none",
        boxSizing: "border-box",

        // backgroundColor: "brown",
        backgroundColor: backgroundColorToUse,
        backgroundImage: backgroundImageToUse,

        position: "absolute",

        left: itemX,

        // transition: CSS_DEFAULT_ANIMATION_TRANSITION,

        transition: CSS_DEFAULT_ANIMATION_TRANSITION,
        bottom,

        zIndex: Z_1,
      }}
      // onClick={() => {
      //   if (!buildMode) {
      //     return;
      //   }

      //   putNObject({
      //     xDecimalPercent: x,
      //     yDecimalPercent: y,

      //     itemType: null,
      //     // itemType: putObjectMode.itemType,
      //   });
      // }}
      onClick={() => {
        // mobile ? null : handleClickOrPress;

        if (mobile || pressedDownOnBuildPlane) {
          return;
        }

        handleClickOrPress();
      }}
      onTouchEnd={mobile ? handleClickOrPress : null}
      onMouseEnter={() => {
        if (pressedDownOnBuildPlane && buildMode) {
          handleClickOrPress();
        }
      }}
      onMouseDown={() => {
        setState(["pressedDownOnBuildPlane"], true);
      }}
      onMouseUp={() => {
        setState(["pressedDownOnBuildPlane"], null);
      }}
      // onLef
      // on left click
    >
      {imageElement}
      <StagingComponent overrideStagingOff={true}>
        <DisplayText text={xScientific} top={0} left={0} mini={true} />
      </StagingComponent>
    </div>
  );
};
