import React, { useEffect, useState } from "react";
import { setState } from "../../../reactStateManagement";
import {
  getLabelFromBBFormId,
  getValidationDataFromFormId,
  // TOTAL_FORM_SCREEN_FORM_TYPES,
  // bbFilePaths,
  // EVENT_TYPES,
} from "../../../constants";
import { doSetTextFormValues } from "../TFSTool/TFSTool";
const TextFormElement = ({ formValues, setFormValues, formId, value }) => {
  // console.log(`

  //     VALUE VALUE VALUE LOG: ${JSON.stringify(
  //       {
  //         value,
  //       },
  //       null,
  //       4
  //     )}

  // if (!formId) {
  //   return null;
  // }

  // `);
  const [textValue, setTextValue] = useState(value);

  const [isFocused, setIsFocused] = useState(false);

  // replace / with -
  const elementId = String(formId).replace(/\//g, "-").trim().trim("-");

  const label = getLabelFromBBFormId(formId);

  useEffect(() => {
    try {
      // const $ = window.$;

      const formElement = document.getElementById(elementId);

      // console.log("fe");
      // console.log(formElement);

      // const $formElement = $(formElement);

      // if (!$formElement || $formElement.length === 0) {
      //   return;
      // }

      if (!formElement) {
        return;
      }

      setTextValue(value);

      doSetTextFormValues(setFormValues, formValues, formId, value);

      // $formElement.val(value);
    } catch (err) {
      console.error("TextFormElement useEffect error:", err);
    }
  }, [elementId, value, formId]);

  const validationData = getValidationDataFromFormId(formId) || {};

  const min = validationData.min || 1;
  const max = validationData.max || 99999999;

  return (
    <div
      style={{
        width: "100%",
        // height: `${formElementHeightPercentNumber}%`,
        height: 300,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        backgroundColor: "black",
        color: "white",

        borderTop: "1px solid white",
        borderBottom: "1px solid white",

        // opacity: 0.7,
        opacity: isFocused ? 0.8 : 0.4,

        boxSizing: "border-box",
      }}
    >
      <label>{label}</label>
      <textarea
        value={textValue}
        id={elementId}
        type="text"
        name={label}
        minLength={min}
        maxLength={max}
        onChange={(e) => {
          // setFormValues({
          //   ...formValues,
          //   [formId]: {
          //     type: TOTAL_FORM_SCREEN_FORM_TYPES.TEXT,
          //     value: e.target.value,
          //     formId,
          //   },
          // });
          doSetTextFormValues(
            setFormValues,
            formValues,
            formId,
            e.target.value
          );

          setTextValue(e.target.value);
        }}
        style={{
          width: "100%",
          height: "90%",
          backgroundColor: "black",
          color: "white",
          fontSize: "16px",
          border: "none",
          outline: "none",
          resize: "none",
          margin: 0,
          padding: 0,
          //   padding: "0 1em",
          boxSizing: "border-box",
        }}
        onFocus={() => {
          setState(["focus"], true);
          setIsFocused(true);
        }}
        onBlur={() => {
          setState(["focus"], false);
          setIsFocused(false);
        }}
      />
    </div>
  );
};

export default TextFormElement;

/*
VALUE VALUE VALUE
{
    "value": "{\n  \"date\": \"2024-01-01T00:00:00Z\"\n}"
}

Goal, depending on the value, we want to display a date picker or a text area.


use formId to determine what action to take.
  
*/
