import { EVENT_TYPES, TOTAL_FORM_SCREEN_INPUT_TYPES } from "../../constants";
import { setState } from "../../reactStateManagement";
import { emitEvent } from "../../utils/emitEvent";
import getAuth from "../../utils/getAuth";
import { getMTokenData } from "../../utils/mToken";

const handleSubmitTFS = async (formValues) => {
  /*
    Form values sample:
    {
        "Date": {
            "type": "TEXT",
            "value": "Nov 20, 2024
        }
    }
  */

  const mToken = getMTokenData();

  if (!mToken || !mToken.address || !mToken.mToken) {
    console.error("handleSubmitTFS: mToken data not found");

    setState(["browserAlertData"], {
      message: "Failed to submit form data, must be logged in",
    });

    return;
  }

  const results = [];

  for (const formId in formValues) {
    const result = {
      ...formValues[formId],
      formId,
    };

    results.push(result);
  }

  console.log(`
  
  
      handleSubmitTFS LOG: ${JSON.stringify(
        {
          RESULTS: results,
        },
        null,
        4
      )}
  
  
  `);

  const auth = getAuth();

  emitEvent({
    type: EVENT_TYPES.TOTAL_FORM_SCREEN_INPUT,
    data: {
      auth,
      type: TOTAL_FORM_SCREEN_INPUT_TYPES.BROADCAST_BUILDER,
      params: {
        results,
      },
      address: mToken.address,
      mToken: mToken.mToken,
    },
  });
};

export default handleSubmitTFS;
