import { ITEM_TYPES } from "./worldConstants";
export * from "./worldConstants";

const yieldNumberState = {
  value: 0,
};

export const yieldNumber = () => {
  yieldNumberState.value = (yieldNumberState.value + 1) % 1000000;
  return yieldNumberState.value;
};

const HOSTNAME =
  process.env.REACT_APP_CUSTOM_HOSTNAME ||
  (window && window.location && window.location.hostname);

if (!HOSTNAME) {
  // safeguard - should not happen in normal operation
  throw new Error(`HOSTNAME is undefined`);
}

export const PRODUCTION_MODE = HOSTNAME !== "localhost";

export const DOGE_API_BASE_URL = process.env.DOGE_API_BASE_URL
  ? process.env.DOGE_API_BASE_URL
  : "https://x5k6eeugtb.execute-api.us-east-1.amazonaws.com";

// Local Storage Keys
export const LOCAL_STORAGE_KEYS = {
  RESPAWN_DATA: "RESPAWN_DATA",
};

export const MAIN_S3_BUCKET_URL =
  "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com";

// const isProduction = ![
//   "localhost",
//   "127.0.0.1",
//   "host.docker.internal",
//   "172.17.0.0",
//   "172.17.0.1",
//   "172.17.0.2",
// ].includes(HOSTNAME.toLowerCase());

const protocol = process.env.REACT_APP_HTTP_MODE === "true" ? "http" : "https";

// export const SOCKET_SERVER_URL = `https://${HOSTNAME}:8001`;
export const SOCKET_SERVER_URL = `${protocol}://${HOSTNAME}:8001`;

export const DATA_CENTER_ID =
  process.env.REACT_APP_DATA_CENTER_ID || "MISSING-DATA_CENTER_ID";

export const REACT_APP_API_DEBUG_GRID_VIEW_ON =
  process.env.REACT_APP_API_DEBUG_GRID_VIEW_ON;

export const TYPE_POWER_CREDENTIALS = "PowerCredentials";
export const TYPE_PRE_POWER_CREDENTIALS = "PrePowerCredentials";
export const TYPE_POWER_CREDENTIALS_REQUEST_CANCEL =
  "PowerCredentialsRequestCancel";

export const POST_MESSAGE_PREFIX = "nftitem-";

export const NONE = "none";

export const FONT_0 = '"Fredoka", sans-serif';

export const COLOR_0 = "black";
export const COLOR_1 = "white";
export const COLOR_2 = "#380000";
export const COLOR_3 = "#21ec9f";
export const COLOR_4 = "#8b0000"; /*darkred in hex*/
export const COLOR_5 = "#ff0000"; /*red in hex*/
// darker COLOR_2
export const COLOR_6 = "#2d0000";
// loading grey
export const COLOR_7 = "#808080";

export const EVENT = "event";

export const BROWSER_ONLY_EVENT_TYPES = {
  E_PRESS: "E_PRESS",
  POWER_MODE_TOGGLE: "POWER_MODE_TOGGLE",
};

export const EVENT_TYPES = {
  GUEST_JOIN: "GUEST_JOIN",
  GUEST_JOINED: "GUEST_JOINED",

  GUEST_LEAVE: "GUEST_LEAVE",

  CHARACTER_UPDATE: "CHARACTER_UPDATE",

  UPDATE_USER_WITH_SYSTEM_TIME: "UPDATE_USER_WITH_SYSTEM_TIME",

  MOVE: "MOVE",
  W_MOVE_RIGHT_ON: "W_MOVE_RIGHT_ON",
  W_MOVE_RIGHT_OFF: "W_MOVE_RIGHT_OFF",
  W_MOVE_LEFT_ON: "W_MOVE_LEFT_ON",
  W_MOVE_LEFT_OFF: "W_MOVE_LEFT_OFF",
  W_MOVE_JUMP_ON: "W_MOVE_JUMP_ON",
  W_MOVE_JUMP_OFF: "W_MOVE_JUMP_OFF",
  W_MOVE_RIGHT_UP_ON: "W_MOVE_RIGHT_UP_ON",
  W_MOVE_RIGHT_UP_OFF: "W_MOVE_RIGHT_UP_OFF",
  W_MOVE_LEFT_UP_ON: "W_MOVE_LEFT_UP_ON",
  W_MOVE_LEFT_UP_OFF: "W_MOVE_LEFT_UP_OFF",
  W_MOVE_DOWN_ON: "W_MOVE_DOWN_ON",
  W_MOVE_DOWN_OFF: "W_MOVE_DOWN_OFF",

  TELEPORT: "TELEPORT",
  FORCE_SET_RESPAWN: "FORCE_SET_RESPAWN",
  DO_RESPAWN: "DO_RESPAWN",

  W_CHARACTER_DATA: "W_CHARACTER_DATA",
  HELLO_WORLD: "HELLO_WORLD", // special event type for testing

  UPDATE_WORLD_NUMBER: "UPDATE_WORLD_NUMBER",
  UPDATE_WORLD_NUMBER_CLIENT: "UPDATE_WORLD_NUMBER_CLIENT",

  API_REQUEST: "API_REQUEST",
  API_RESPONSE: "API_RESPONSE",

  CREATE_BOT: "CREATE_BOT",
  DELETE_BOT: "DELETE_BOT",
  BOT_DATA: "BOT_DATA",
  GET_BOT_DATA: "GET_BOT_DATA",
  BOT_COMMAND: "BOT_COMMAND",

  CREATE_BOT2: "CREATE_BOT2",
  DELETE_BOT2: "DELETE_BOT2",
  BOT2_DATA: "BOT2_DATA",
  BOT2_INTERACT: "BOT2_INTERACT",
  LIST_BOT2_INTERACTIONS: "LIST_BOT2_INTERACTIONS",
  BOT2_INTERACTIONS: "BOT2_INTERACTIONS",

  SEARCH_BOT6S: "SEARCH_BOT6S",
  BOT6_DATA: "BOT6_DATA",
  BOT6_INTERACT: "BOT6_INTERACT",
  LIST_BOT6_INTERACTIONS: "LIST_BOT6_INTERACTIONS",
  BOT6_INTERACTIONS: "BOT6_INTERACTIONS",
  BOT6_WORLD_DATA: "BOT6_WORLD_DATA",
  LIST_BOT6_INTERACTION_PROCESSING_RECEIPTS:
    "LIST_BOT6_INTERACTION_PROCESSING_RECEIPTS",
  BOT6_INTERACTION_PROCESSING_RECEIPTS: "BOT6_INTERACTION_PROCESSING_RECEIPTS",

  PUT_BOT6_INTERACTION_PROCESSING_RECEIPT:
    "PUT_BOT6_INTERACTION_PROCESSING_RECEIPT",

  REQUEST_ID_EVENT_COMPLETED: "REQUEST_ID_EVENT_COMPLETED",

  CHARACTER_DATA: "CHARACTER_DATA",

  BCHAR_BROADCAST: "BCHAR_BROADCAST",
  BCHAR_DATA: "BCHAR_DATA",

  UPDATE_SETTINGS: "UPDATE_SETTINGS",
  UPDATE_LAND_MAPPINGS: "UPDATE_LAND_MAPPINGS",
  GET_LAND_MAPPINGS: "GET_LAND_MAPPINGS",
  LAND_MAPPINGS: "LAND_MAPPINGS",

  PUT_WORLD_OBJECT: "PUT_WORLD_OBJECT",
  PUT_NWORLD_OBJECT: "PUT_NWORLD_OBJECT",
  PUT_PIXELS: "PUT_PIXELS",
  MINT_NFT: "MINT_NFT",
  MINT_NFT_RESPONSE: "MINT_NFT_RESPONSE",

  GET_WORLD_DATA: "GET_WORLD_DATA",
  WORLD_DATA: "WORLD_DATA",
  GET_NWORLD_DATA: "GET_NWORLD_DATA",
  GET_NWORLD_DATA_MULTIPLE: "GET_NWORLD_DATA_MULTIPLE",
  GET_NWORLD_DATA_MULTIPLE_V2: "GET_NWORLD_DATA_MULTIPLE_V2",
  POWER_GET_NWORLD_DATA: "POWER_GET_NWORLD_DATA",
  POWER_TRIGGER_DELETE_NWORLD_DATA: "POWER_TRIGGER_DELETE_NWORLD_DATA",
  POWER_NWORLD_DATA: "POWER_NWORLD_DATA",
  POWER_NWORLD_DATA_ATOMIC: "POWER_NWORLD_DATA_ATOMIC",
  GET_USER_LAND_DATA: "GET_USER_LAND_DATA",

  NWORLD_DATA: "NWORLD_DATA",

  GET_NFT_LISTINGS: "GET_NFT_LISTINGS",
  NFT_LISTINGS: "NFT_LISTINGS",

  GET_NFT_ACTIONABLES: "GET_NFT_ACTIONABLES",
  NFT_ACTIONABLES: "NFT_ACTIONABLES",

  GET_NFT_SALES: "GET_NFT_SALES",
  NFT_SALES: "NFT_SALES",

  LIST_NFT: "LIST_NFT",

  PURCHASE_NFT: "PURCHASE_NFT",

  CREATE_IMAGE: "CREATE_IMAGE",
  CREATE_IMAGE_RESPONSE: "CREATE_IMAGE_RESPONSE",

  MESSAGE: "MESSAGE",
  MESSAGES: "MESSAGES",

  RELOAD: "RELOAD",

  TRIGGER_BROWSER_ALERT: "TRIGGER_BROWSER_ALERT",
  BROWSER_ALERT: "BROWSER_ALERT",

  SUGGEST_UPDATE_USER_DATA: "SUGGEST_UPDATE_USER_DATA",

  ADMIN_SPAWN_PIGYAN: "ADMIN_SPAWN_PIGYAN",

  ASSIGN_NFT_FASHION: "ASSIGN_NFT_FASHION",

  CUDDLE: "CUDDLE",
  FORCE_CUDDLE: "FORCE_CUDDLE",

  NEWS_ANCHOR_APPLICATION: "NEWS_ANCHOR_APPLICATION",

  SERVER_TRIGGERED_USER_ACTION: "SERVER_TRIGGERED_USER_ACTION",

  RELAY_MESSAGE: "RELAY_MESSAGE",

  MESSAGE_AUDIO_PLAY_COMPLETED: "MESSAGE_AUDIO_PLAY_COMPLETED",

  TOTAL_FORM_SCREEN_INPUT: "TOTAL_FORM_SCREEN_INPUT",
  TOTAL_FORM_SCREEN_OUTPUT: "TOTAL_FORM_SCREEN_OUTPUT",
};

export const BROWSER_ALERT_TYPES = {
  B: "B",
};

export const SPECIAL_SELF_USER_ID = "SELF-USER-ID";

export const Z_INDICES = {
  // dialog layer
  // used for dialog boxes
  DIALOG_LAYER: 4000000,

  NEW_ZOOM_WORLD_LAYER: 2323232,

  // top layer
  // used for menu items
  TOP_LAYER: 1000,

  // middle layer
  // used for world and characters
  MIDDLE_LAYER: 500,

  MIDDLE_LAYER_A: 501,
  MIDDLE_LAYER_B: 502,
  MIDDLE_LAYER_C: 503,

  // bottom layer
  BOTTOM_LAYER: 0,
};

export const TOP_FACTOR = 0.8;
export const BOTTOM_FACTOR = 1 - TOP_FACTOR;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : "https://mathbitcoin.com";

// Dims
export const MOBILE_WIDTH_THRESHOLD = 700;

export const BOTTOM_SECTION_HEIGHT_MOBILE = 75;
export const BOTTOM_SECTION_HEIGHT_DESKTOP = 150;
export const MESSAGE_BOX_WIDTH = 175;
export const COORDS_HEIGHT = 40;
export const SLIDE_MENU_WIDTH = 290;

export const STANDARD_BUTTON_HEIGHT = 40;

// Dialog

export const DIALOG_TYPES = {
  EVENT_SENDER: "EVENT_SENDER",
};

// Browser Event (brovent)
export const BROVENT_TYPES = {
  TOTAL_MAP_REFRESH: "TOTAL_MAP_REFRESH",
};

export const BROVENT_KEYS = {
  rightDown: "rightDown",
  // rightUp: "rightUp",
  leftDown: "leftDown",
  // leftUp: "leftUp",
  jumpDown: "jumpDown",
  // jumpUp: "jumpUp",
  downDown: "downDown",
};

// World

// const GROUND = "GROUND";

// export const UNIT_TYPES = {
//   GROUND,
// };

// export const WORLD_NUMBER_TO_DATA = {
//   0: {
//     w: 4,
//     h: 4,
//     unitNumberToData: {
//       0: {
//         unitType: GROUND,
//       },
//       1: {
//         unitType: GROUND,
//       },
//       2: {
//         unitType: GROUND,
//       },
//       3: {
//         unitType: GROUND,
//       },
//       4: {
//         unitType: GROUND,
//       },
//       5: {
//         unitType: GROUND,
//       },
//       6: {
//         unitType: GROUND,
//       },
//       7: {
//         unitType: GROUND,
//       },
//       8: {
//         unitType: GROUND,
//       },
//       9: {
//         unitType: GROUND,
//       },
//     },
//   },
// };

// export const ITEM_TYPES = {
//   TREE_0: "tree0",
//   BIRCH_TREE_0: "birchTree0",
// };

// export const ITEM_TYPE_TO_DATA = {
//   // tree
//   tree0: {
//     allowedOnUnitTypes: [GROUND],
//   },

//   // birch tree
//   birchTree0: {
//     allowedOnUnitTypes: [GROUND],
//   },
// };

// WORLD DISPLAY ONLY
export const CSS_DEFAULT_ANIMATION_TRANSITION = "all 0.09s ease";
export const PRE_RENDER_WIDTH = 300;
// number of pixels to render before and after the screen

export const ERASER = "ERASER";

export const POWER_MODES = {
  MOON: "MOON",
  X: "X",
  POWER: "POWER",
};

export const POWER_MODES_LIST = [
  POWER_MODES.X,
  POWER_MODES.POWER,
  POWER_MODES.MOON,
];

export const EXECUTIVE_BOX_MODES = {
  IMAGE_TO_PIXELS: "IMAGE_TO_PIXELS",
  ADV_BUILD: "ADV_BUILD",
  MARKETPLACE: "MARKETPLACE",
  WEARABLES: "WEARABLES",
  BOT222: "BOT222",
  BOT6: "BOT6",
  MINT: "MINT",
  CHARACTER_SELECT: "CHARACTER_SELECT",
};

export const MARKETPLACE_MODES = {
  SELL: "SELL",
};

// Bot6
export const BOT6_PAGE_MODES = {
  COMMAND_CENTER: "COMMAND_CENTER",
};

export const BOT6_MAX_TEXT_INPUT_LENGTH = 140;

export const BOT6_INTERACT_MODES = {
  EXPLORE: "EXPLORE",
  BUILD: "BUILD",
};
// botoScreenCaptureModeType
export const BOT6_SCREEN_CAPTURE_MODE_TYPES = {
  COORDINATE_GRID: "COORDINATE_GRID",
};

export const BOT6_PROMPT_TYPES = {
  BUILD: "BUILD",
};

export const BOT6_COMMANDS = {
  MOVE: "move",
  TALK: "talk",
  PUT_PIXEL: "putPixel",
};

// Characters

export const CHARACTERS = {
  EXPLORE: "EXPLORE", // Identity element character
  DOGE: "DOGE",
  ELON: "ELON",
  ZUCC: "ZUCC",
  BEARA: "BEARA",
  DOGE_FASHION: "DOGE_FASHION",
  AQUA: "AQUA",
  PEPE: "PEPE",
  DOGE2: "DOGE2",
  KK: "KK",
  PIGYAN: "PIGYAN", // NPC
};

export const PLAYABLE_CHARACTERS = {
  [CHARACTERS.EXPLORE]: CHARACTERS.EXPLORE,
  [CHARACTERS.DOGE]: CHARACTERS.DOGE,
  [CHARACTERS.ELON]: CHARACTERS.ELON,
  [CHARACTERS.ZUCC]: CHARACTERS.ZUCC,
  [CHARACTERS.BEARA]: CHARACTERS.BEARA,
  [CHARACTERS.AQUA]: CHARACTERS.AQUA,
  [CHARACTERS.DOGE_FASHION]: CHARACTERS.DOGE_FASHION,
  [CHARACTERS.PEPE]: CHARACTERS.PEPE,
  // [CHARACTERS.KK]: CHARACTERS.KK,
  [CHARACTERS.DOGE2]: CHARACTERS.DOGE2,
};

export const CHARACTERS_ARRAY = Array.from(new Set(Object.values(CHARACTERS)));

export const PLAYABLE_CHARACTERS_ARRAY = Array.from(
  new Set(Object.values(PLAYABLE_CHARACTERS))
);

const SELECTABLE_CHARACTERS_SET = new Set(Object.values(PLAYABLE_CHARACTERS));
// remove identity element character
SELECTABLE_CHARACTERS_SET.delete(CHARACTERS.EXPLORE);
export const SELECTABLE_CHARACTERS_ARRAY = Array.from(
  SELECTABLE_CHARACTERS_SET
);

export const FUN_CHARACTERS = [
  CHARACTERS.DOGE_FASHION,
  CHARACTERS.DOGE,
  CHARACTERS.AQUA,
  // CHARACTERS.PEPE,
  CHARACTERS.DOGE2,
];

const ZUCC_IMAGE_0 =
  "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/zucc_0.png";
const ZUCC_IMAGE_0_R =
  "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/zucc_0_r.png";

// const BEARA_IMAGE_0 =
//   "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/beara_0.png";

// const BEARA_IMAGE_0_R =
//   "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/beara_0_r.png";

const PIGYAN_IMAGE_0 =
  "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/pigyan_0.png";

const PIGYAN_IMAGE_0_R =
  "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/pigyan_0_r.png";

// const IMAGE_STORAGE_BASE_URL =
//   "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/";

const IMAGE_STORAGE_BASE_URL = "/images/";

const getStandardCharacterImageUrl = (characterStorageName, tag) => {
  return `${IMAGE_STORAGE_BASE_URL}${characterStorageName}_${tag}.png`;
};

const TT = {
  BOTTOM: "bottom",
  TOP: "top",
  HAT: "hat",
};

const fashionUrls = (
  characterStorageName,
  tagType,
  tagNumber,
  {
    back = false,
    compatibilities = null,

    requireSameLocationCensor = false,
  } = {
    back: false,
    compatibilities: null,
    requireSameLocationCensor: false,
  }
) => {
  // return getStandardCharacterImageUrl(
  //   DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
  //   `${move ? "1" : "0"}_${tagType}_${tagNumber}`
  // );

  const urls = {
    imageUrl: getStandardCharacterImageUrl(
      characterStorageName,
      `0_${tagType}_${tagNumber}`
    ),
    imageUrlMove: getStandardCharacterImageUrl(
      characterStorageName,
      `1_${tagType}_${tagNumber}`
    ),
  };

  if (back) {
    urls.imageUrlBack = getStandardCharacterImageUrl(
      characterStorageName,
      `0_${tagType}_${tagNumber}_back`
    );
    urls.imageUrlMoveBack = getStandardCharacterImageUrl(
      characterStorageName,
      `1_${tagType}_${tagNumber}_back`
    );
  }

  if (compatibilities) {
    urls.compatibilities = compatibilities;
  }

  if (requireSameLocationCensor) {
    urls.requireSameLocationCensor = true;
  }

  return urls;
};

const DOGE_STORAGE_CHARACTER_STORAGE_NAME = "libra"; // doge === default
const DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME = "dogexfashion";
const D2 = "doge2";
const AQ = "aqua";
const KK = "kk";

const NULL_IMAGE_URL = "/images/null.png";

export const characterToData = {
  [CHARACTERS.EXPLORE]: {
    imageUrlA: NULL_IMAGE_URL,
    imageUrlMove: NULL_IMAGE_URL,
  },

  [CHARACTERS.DOGE]: {
    // imageUrlA:
    //   "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/nftitem_doge_miniskirt_8_a.png",
    // imageUrlMove:
    //   "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/nftitem_doge_miniskirt_8a_a.png",
    // imageUrlA: "/images/mimi_0.png",
    // imageUrlMove: "/images/mimi_1.png",
    imageUrlA: "/images/libra_0.png",
    // imageUrlMove: "/images/libra_1.png",
    imageUrlMove: "/images/libra_1.png",

    wardrobe: {
      base: getStandardCharacterImageUrl(
        DOGE_STORAGE_CHARACTER_STORAGE_NAME,
        "0_base"
      ),
      baseMove: getStandardCharacterImageUrl(
        DOGE_STORAGE_CHARACTER_STORAGE_NAME,
        "1_base"
      ),

      hats: [
        {
          imageUrl: getStandardCharacterImageUrl(
            DOGE_STORAGE_CHARACTER_STORAGE_NAME,
            "0_hat_0"
          ),
          imageUrlMove: getStandardCharacterImageUrl(
            DOGE_STORAGE_CHARACTER_STORAGE_NAME,
            "1_hat_0"
          ),
        },
      ],

      tops: [
        {
          imageUrl: getStandardCharacterImageUrl(
            DOGE_STORAGE_CHARACTER_STORAGE_NAME,
            "0_top_0"
          ),
          imageUrlMove: getStandardCharacterImageUrl(
            DOGE_STORAGE_CHARACTER_STORAGE_NAME,
            "1_top_0"
          ),
        },
      ],

      bottoms: [
        {
          imageUrl: getStandardCharacterImageUrl(
            DOGE_STORAGE_CHARACTER_STORAGE_NAME,
            "0_bottom_0"
          ),
          // imageUrlXOffset: 22.9,
          // imageUrlXOffset: 2.9,
          imageUrlXOffset: 0,
          imageUrlMove: getStandardCharacterImageUrl(
            DOGE_STORAGE_CHARACTER_STORAGE_NAME,
            "1_bottom_0"
          ),
          imageUrlXOffsetMove: 0,
        },
      ],

      bottomCensors: [
        {
          imageUrl: getStandardCharacterImageUrl(
            DOGE_STORAGE_CHARACTER_STORAGE_NAME,
            "0_censorxbottom_0"
          ),
          imageUrlMove: getStandardCharacterImageUrl(
            DOGE_STORAGE_CHARACTER_STORAGE_NAME,
            "1_censorxbottom_0"
          ),
        },
      ],

      topCensors: [
        {
          imageUrl: getStandardCharacterImageUrl(
            DOGE_STORAGE_CHARACTER_STORAGE_NAME,
            "0_censorxtop_0"
          ),
          imageUrlMove: getStandardCharacterImageUrl(
            DOGE_STORAGE_CHARACTER_STORAGE_NAME,
            "1_censorxtop_0"
          ),
        },
      ],
    },

    fun: {
      flexDenominator: 3000,
      flexModulus: 4,
      imageUrlFun0: getStandardCharacterImageUrl(
        DOGE_STORAGE_CHARACTER_STORAGE_NAME,
        "0_fun_0"
      ),
      imageUrlFun1: getStandardCharacterImageUrl(
        DOGE_STORAGE_CHARACTER_STORAGE_NAME,
        "0_fun_1"
      ),

      imageUrlFunXCalculus0: getStandardCharacterImageUrl(
        DOGE_STORAGE_CHARACTER_STORAGE_NAME,
        "0_funxcalculus_0"
      ),
      imageUrlFunXCalculus1: getStandardCharacterImageUrl(
        DOGE_STORAGE_CHARACTER_STORAGE_NAME,
        "0_funxcalculus_1"
      ),

      imageUrlFunXCalculus0R: getStandardCharacterImageUrl(
        DOGE_STORAGE_CHARACTER_STORAGE_NAME,
        "0_funxcalculusxr_0"
      ),

      imageUrlFunXCalculus1R: getStandardCharacterImageUrl(
        DOGE_STORAGE_CHARACTER_STORAGE_NAME,
        "0_funxcalculusxr_1"
      ),
    },
  },

  [CHARACTERS.KK]: {
    imageUrlA: getStandardCharacterImageUrl(KK, "0"),
    imageUrlMove: getStandardCharacterImageUrl(KK, "1"),
    imageUrlARight: getStandardCharacterImageUrl(KK, "0"),
    imageUrlMoveRight: getStandardCharacterImageUrl(KK, "1"),
  },

  [CHARACTERS.ELON]: {
    imageUrlA:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/elon_0.png",
    imageUrlMove:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/elon_1.png",
    imageUrlARight:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/elon_0_r.png",
    imageUrlMoveRight:
      "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/elon_1_r.png",
  },
  [CHARACTERS.ZUCC]: {
    // imageUrlA:
    //   "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/zucc_0.png",
    // imageUrlMove:
    //   "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/zucc_1.png",
    // imageUrlARight:
    //   "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/zucc_0_r.png",
    // imageUrlMoveRight:
    //   "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/zucc_1_r.png",
    imageUrlA: ZUCC_IMAGE_0,
    imageUrlMove: ZUCC_IMAGE_0,
    imageUrlARight: ZUCC_IMAGE_0_R,
    imageUrlMoveRight: ZUCC_IMAGE_0_R,
  },

  [CHARACTERS.BEARA]: {
    imageUrlA: "/images/beara_0.png",
    imageUrlMove: "/images/beara_0.png",
    // imageUrlARight: BEARA_IMAGE_0,
    // imageUrlMoveRight: BEARA_IMAGE_0,

    wardrobe: {
      base: "/images/beara_0.png",
      baseMove: "/images/beara_0.png",

      tops: [
        {
          imageUrl: "/images/beara_0_top_0.png",
          imageUrlMove: "/images/beara_0_top_0.png",
        },
        {
          imageUrl: "/images/beara_0_top_1.png",
          imageUrlMove: "/images/beara_0_top_1.png",
        },
      ],

      bottoms: [
        {
          imageUrl: "/images/beara_0_bottom_0.png",
          imageUrlMove: "/images/beara_0_bottom_0.png",
        },
        {
          imageUrl: "/images/beara_0_bottom_1.png",
          imageUrlMove: "/images/beara_0_bottom_1.png",
        },
      ],

      bottomCensors: [
        {
          // no censor needed
          imageUrl: "/images/null.png",
          imageUrlMove: "/images/null.png",
        },
      ],
    },
  },

  [CHARACTERS.AQUA]: {
    imageUrlA: getStandardCharacterImageUrl("aqua", "0"),
    imageUrlMove: getStandardCharacterImageUrl("aqua", "1"),
    imageUrlARight: getStandardCharacterImageUrl("aqua", "0"),
    imageUrlMoveRight: getStandardCharacterImageUrl("aqua", "1"),

    wardrobe: {
      base: getStandardCharacterImageUrl("aqua", "0_base"),
      baseMove: getStandardCharacterImageUrl("aqua", "1_base"),
      tops: [
        {
          imageUrl: getStandardCharacterImageUrl("aqua", "0_top_0"),
          imageUrlMove: getStandardCharacterImageUrl("aqua", "1_top_0"),
        },
        {
          imageUrl: getStandardCharacterImageUrl("aqua", "0_top_1"),
          imageUrlMove: getStandardCharacterImageUrl("aqua", "1_top_1"),
        },
        {
          imageUrl: getStandardCharacterImageUrl("aqua", "0_top_2"),
          imageUrlMove: getStandardCharacterImageUrl("aqua", "1_top_2"),
        },
        // {
        //   imageUrl: getStandardCharacterImageUrl("aqua", "0_top_3"),
        //   imageUrlMove: getStandardCharacterImageUrl("aqua", "1_top_3"),
        // },
        fashionUrls(AQ, TT.TOP, 3),

        fashionUrls(AQ, TT.TOP, 4, {
          back: true,
          compatibilities: [
            getStandardCharacterImageUrl("aqua", "0_bottom_1"),
            getStandardCharacterImageUrl("aqua", "0_bottom_3"),
            getStandardCharacterImageUrl("aqua", "0_bottom_5"),
            getStandardCharacterImageUrl("aqua", "0_bottom_7"),
          ],
        }),

        // fashionUrls(AQ, TT.TOP, 8, {
        //   back: true,
        // }),
        fashionUrls(AQ, TT.TOP, 10, {
          compatibilities: [],
        }),
      ],
      bottoms: [
        {
          imageUrl: getStandardCharacterImageUrl("aqua", "0_bottom_0"),
          imageUrlMove: getStandardCharacterImageUrl("aqua", "1_bottom_0"),
          compatibilities: [
            getStandardCharacterImageUrl("aqua", "0_top_0"),
            getStandardCharacterImageUrl("aqua", "0_top_1"),
            getStandardCharacterImageUrl("aqua", "0_top_2"),
            getStandardCharacterImageUrl("aqua", "0_top_3"),
          ],
        },

        {
          imageUrl: getStandardCharacterImageUrl("aqua", "0_bottom_1"),
          imageUrlMove: getStandardCharacterImageUrl("aqua", "1_bottom_1"),
          compatibilities: [
            getStandardCharacterImageUrl("aqua", "0_top_0"),
            getStandardCharacterImageUrl("aqua", "0_top_1"),
            getStandardCharacterImageUrl("aqua", "0_top_2"),
            getStandardCharacterImageUrl("aqua", "0_top_3"),
            getStandardCharacterImageUrl("aqua", "0_top_4"),
          ],
        },

        {
          imageUrl: getStandardCharacterImageUrl("aqua", "0_bottom_2"),
          imageUrlMove: getStandardCharacterImageUrl("aqua", "1_bottom_2"),
          compatibilities: [
            getStandardCharacterImageUrl("aqua", "0_top_0"),
            getStandardCharacterImageUrl("aqua", "0_top_1"),
            getStandardCharacterImageUrl("aqua", "0_top_2"),
            getStandardCharacterImageUrl("aqua", "0_top_3"),
          ],
        },

        {
          imageUrl: getStandardCharacterImageUrl("aqua", "0_bottom_3"),
          imageUrlMove: getStandardCharacterImageUrl("aqua", "1_bottom_3"),
          compatibilities: [
            getStandardCharacterImageUrl("aqua", "0_top_0"),
            getStandardCharacterImageUrl("aqua", "0_top_1"),
            getStandardCharacterImageUrl("aqua", "0_top_2"),
            getStandardCharacterImageUrl("aqua", "0_top_3"),
            getStandardCharacterImageUrl("aqua", "0_top_4"),
          ],
        },

        fashionUrls(AQ, TT.BOTTOM, 5, {
          compatibilities: [
            getStandardCharacterImageUrl("aqua", "0_top_0"),
            getStandardCharacterImageUrl("aqua", "0_top_1"),
            getStandardCharacterImageUrl("aqua", "0_top_2"),
            getStandardCharacterImageUrl("aqua", "0_top_3"),
            getStandardCharacterImageUrl("aqua", "0_top_4"),
          ],
        }),
        fashionUrls(AQ, TT.BOTTOM, 6, {
          compatibilities: [
            getStandardCharacterImageUrl("aqua", "0_top_0"),
            getStandardCharacterImageUrl("aqua", "0_top_1"),
            getStandardCharacterImageUrl("aqua", "0_top_2"),
            getStandardCharacterImageUrl("aqua", "0_top_3"),
          ],
        }),
        fashionUrls(AQ, TT.BOTTOM, 7, {
          compatibilities: [
            getStandardCharacterImageUrl("aqua", "0_top_0"),
            getStandardCharacterImageUrl("aqua", "0_top_1"),
            getStandardCharacterImageUrl("aqua", "0_top_2"),
            getStandardCharacterImageUrl("aqua", "0_top_3"),
            getStandardCharacterImageUrl("aqua", "0_top_4"),
          ],
        }),

        // fashionUrls(AQ, TT.BOTTOM, 4, {
        //   requireSameLocationCensor: true,
        // }),
      ],
      bottomCensors: [
        {
          imageUrl: getStandardCharacterImageUrl("aqua", "0_censorxbottom_0"),
          imageUrlMove: getStandardCharacterImageUrl(
            "aqua",
            "1_censorxbottom_0"
          ),
        },
      ],
      topCensors: [
        {
          imageUrl: getStandardCharacterImageUrl("aqua", "0_censorxtop_0"),
          imageUrlMove: getStandardCharacterImageUrl("aqua", "1_censorxtop_0"),
        },
      ],
    },
    fun: {
      // flexDenominator: 1000,
      // flexModulus: 2,

      getIfFun: ({ now }) => {
        // now is unix time

        // alternate every 0.25 seconds
        // return now % 500 < 250;

        // alternate every 0.5 seconds
        return now % 1000 < 500;
      },

      imageUrlFun0: getStandardCharacterImageUrl("aqua", "0_fun_0"),
      imageUrlFun1: getStandardCharacterImageUrl("aqua", "0_fun_1"),

      imageUrlFunXCalculus0: getStandardCharacterImageUrl(
        "aqua",
        "0_funxcalculus_0"
      ),
      imageUrlFunXCalculus1: getStandardCharacterImageUrl(
        "aqua",
        "0_funxcalculus_1"
      ),

      imageUrlFunXCalculus0R: getStandardCharacterImageUrl(
        "aqua",
        "0_funxcalculusxr_0"
      ),

      imageUrlFunXCalculus1R: getStandardCharacterImageUrl(
        "aqua",
        "0_funxcalculusxr_1"
      ),
    },
  },

  [CHARACTERS.PIGYAN]: {
    imageUrlA: PIGYAN_IMAGE_0,
    imageUrlMove: PIGYAN_IMAGE_0,
    imageUrlARight: PIGYAN_IMAGE_0_R,
    imageUrlMoveRight: PIGYAN_IMAGE_0_R,
  },

  [CHARACTERS.DOGE_FASHION]: {
    imageUrlA: getStandardCharacterImageUrl(
      DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
      "0"
    ),
    imageUrlMove: getStandardCharacterImageUrl(
      DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
      "1"
    ),
    imageUrlARight: getStandardCharacterImageUrl(
      DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
      "0"
    ),
    imageUrlMoveRight: getStandardCharacterImageUrl(
      DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
      "1"
    ),

    wardrobe: {
      base: getStandardCharacterImageUrl(
        DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
        "0_base"
      ),
      baseMove: getStandardCharacterImageUrl(
        DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
        "1_base"
      ),
      tops: [
        {
          imageUrl: getStandardCharacterImageUrl(
            DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
            "0_top_0"
          ),
          imageUrlMove: getStandardCharacterImageUrl(
            DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
            "1_top_0"
          ),
        },
      ],
      bottoms: [
        {
          imageUrl: getStandardCharacterImageUrl(
            DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
            "0_bottom_0"
          ),
          imageUrlMove: getStandardCharacterImageUrl(
            DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
            "1_bottom_0"
          ),
        },
      ],
      bottomCensors: [
        {
          imageUrl: getStandardCharacterImageUrl(
            DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
            "0_censorxbottom_0"
          ),
          imageUrlMove: getStandardCharacterImageUrl(
            DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
            "1_censorxbottom_0"
          ),
        },
      ],
      // bottomCensorsR: [
      //   {
      //     imageUrl: getStandardCharacterImageUrl(
      //       DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
      //       "0_censorxbottomr_0"
      //     ),
      //     imageUrlMove: getStandardCharacterImageUrl(
      //       DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
      //       "1_censorxbottomr_0"
      //     ),
      //   },
      // ],
    },

    fun: {
      imageUrlFun0: getStandardCharacterImageUrl(
        DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
        "0_fun_0"
      ),
      imageUrlFun1: getStandardCharacterImageUrl(
        DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
        "0_fun_1"
      ),

      imageUrlFunXCalculus0: getStandardCharacterImageUrl(
        DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
        "0_funxcalculus_0"
      ),
      imageUrlFunXCalculus1: getStandardCharacterImageUrl(
        DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
        "0_funxcalculus_1"
      ),

      imageUrlFunXCalculus0R: getStandardCharacterImageUrl(
        DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
        "0_funxcalculusxr_0"
      ),

      imageUrlFunXCalculus1R: getStandardCharacterImageUrl(
        DOGE_FASHION_STORAGE_CHARACTER_STORAGE_NAME,
        "0_funxcalculusxr_1"
      ),
    },
  },

  [CHARACTERS.PEPE]: {
    imageUrlA: "/images/pepe_0.png",
    imageUrlMove: "/images/pepe_1.png",
    // imageUrlARight: "/images/pepe_0_r.png",

    wardrobe: {
      base: "/images/pepe_0.png",
      baseMove: "/images/pepe_1.png",

      bottoms: [
        {
          imageUrl: "/images/pepe_0_bottom_0.png",
          imageUrlMove: "/images/pepe_0_bottom_0.png",
        },
      ],
      bottomCensors: [
        {
          // is wearing speedo by default - no need for censor
          imageUrl: "/images/null.png",
          imageUrlMove: "/images/null.png",
        },
      ],
    },
  },

  [CHARACTERS.DOGE2]: {
    imageUrlA: "/images/doge2_0.png",
    imageUrlMove: "/images/doge2_1.png",

    wardrobe: {
      base: "/images/doge2_0_base.png",
      baseMove: "/images/doge2_1_base.png",

      // hats: [],

      tops: [
        fashionUrls(D2, TT.TOP, 0),
        fashionUrls(D2, TT.TOP, 1),
        fashionUrls(D2, TT.TOP, 2),
        fashionUrls(D2, TT.TOP, 3),
        fashionUrls(D2, TT.TOP, 4, {
          back: true,
        }),
      ],

      bottoms: [
        // {
        //   imageUrl: "/images/doge2_0_bottom_0.png",
        //   imageUrlMove: "/images/doge2_1_bottom_0.png",
        // },
        fashionUrls(D2, TT.BOTTOM, 0),
        fashionUrls(D2, TT.BOTTOM, 1),
        fashionUrls(D2, TT.BOTTOM, 2),
        fashionUrls(D2, TT.BOTTOM, 3),
        // fashionUrls(D2, TT.BOTTOM, 4, {
        //   requireSameLocationCensor: true,
        // }),
      ],

      bottomCensors: [
        {
          imageUrl: "/images/doge2_0_censorxbottom_0.png",
          imageUrlMove: "/images/doge2_1_censorxbottom_0.png",
        },
      ],
    },

    fun: {
      imageUrlFun0: "/images/doge2_0_fun_0.png",
      imageUrlFun1: "/images/doge2_0_fun_1.png",

      imageUrlFunXCalculus0: "/images/doge2_0_funxcalculus_0.png",
      imageUrlFunXCalculus1: "/images/doge2_0_funxcalculus_1.png",

      imageUrlFunXCalculus0R: "/images/doge2_0_funxcalculusxr_0.png",

      imageUrlFunXCalculus1R: "/images/doge2_0_funxcalculusxr_1.png",
    },
  },
};

const topFashionUrlsThatRequiresTopCensor = [];
const topMoveFashionUrlsThatRequiresTopCensor = [];

Object.values(characterToData).forEach((characterData) => {
  if (characterData.wardrobe) {
    if (characterData.wardrobe.tops) {
      for (const top of characterData.wardrobe.tops) {
        if (top.requireSameLocationCensor) {
          topFashionUrlsThatRequiresTopCensor.push(top.imageUrl);
          topMoveFashionUrlsThatRequiresTopCensor.push(top.imageUrlMove);
        }
      }
    }
  }
});

const bottomFashionUrlsThatRequiresBottomCensor = [];
const bottomMoveFashionUrlsThatRequiresBottomCensor = [];

Object.values(characterToData).forEach((characterData) => {
  if (characterData.wardrobe) {
    if (characterData.wardrobe.bottoms) {
      for (const bottom of characterData.wardrobe.bottoms) {
        if (bottom.requireSameLocationCensor) {
          bottomFashionUrlsThatRequiresBottomCensor.push(bottom.imageUrl);
          bottomMoveFashionUrlsThatRequiresBottomCensor.push(
            bottom.imageUrlMove
          );
        }
      }
    }
  }
});

export {
  topFashionUrlsThatRequiresTopCensor,
  topMoveFashionUrlsThatRequiresTopCensor,
  bottomFashionUrlsThatRequiresBottomCensor,
  bottomMoveFashionUrlsThatRequiresBottomCensor,
};

export const RIGHT = "right";
export const LEFT = "left";

/* Inventory */
export const ITEMS = {
  PICKAXE: {
    name: "Pickaxe",
    image: "/images/item_pickaxe.png",
  },

  BRICK: {
    name: "Brick",
    image: "/images/item_brick.png",
    itemType: ITEM_TYPES.BRICK,
  },
};

export const CUDDLE_CONSTANTS = {
  DOGExDOGE_FASHIONxxx0: {
    imageUrl: "/images/dogexdogefashion_0a.png",
    imageUrl2: "/images/dogexdogefashion_0b.png",
    imageUrlCensor: "/images/dogexdogefashioncensor_0a.png",
    imageUrl2Censor: "/images/dogexdogefashioncensor_0b.png",
  },
};

export const TOTAL_FORM_SCREEN_FORM_TYPES = {
  TEXT: "TEXT",
};

export const TOTAL_FORM_SCREEN_INPUT_TYPES = {
  // create a news broadcast using human input forms and AI
  BROADCAST_BUILDER: "BROADCAST_BUILDER",
};

export const TOTAL_FORM_SCREEN_OUTPUT_TYPES = {
  FORM_CONFIGURATION: "FORM_CONFIGURATION",
};

export const BROADCAST_BUILDER_FORM_LABELS = {
  DATE: "Date",
  DATE_PROCESSED: "Date Processed",
  CHOSEN_CHARACTER: "Chosen Character",
  CHOSEN_CHARACTER_PROCESSED: "Chosen Character Processed",
  NEWS_LANDSCAPE: "News Landscape",
  NEWS_LANDSCAPE_PROCESSED: "News Landscape Processed",
  TRENDING_TOPICS: "Trending Topics",
  TRENDING_TOPICS_PROCESSED: "Trending Topics Processed",
  TRENDING_TOPICS_COUNT: "Trending Topics Count",
  TRENDING_TOPICS_COUNT_PROCESSED: "Trending Topics Count Processed",
  TRENDING_TOPICS_DETAIL_LEVEL: "Trending Topics Detail Level",
  TRENDING_TOPICS_DETAIL_LEVEL_PROCESSED:
    "Trending Topics Detail Level Processed",
};

export const BB_ROOT_FOLDER =
  process.env.REACT_APP_BB_ROOT_FOLDER || "/app/dataToBeSaved/bb";

// export const bbFilePaths = {
//   // Date
//   date: `${BB_ROOT_FOLDER}/date.txt`,
//   dateProcessed: `${BB_ROOT_FOLDER}/dateProcessed.json`,
//   // Trending Topics
//   trendingTopics: `${BB_ROOT_FOLDER}/trendingTopics.txt`,
//   // assume 3, finite enough to be hardcoded
//   trendingTopicsProcessed0: `${BB_ROOT_FOLDER}/trendingTopicsProcessed0.json`,
//   trendingTopicsProcessed1: `${BB_ROOT_FOLDER}/trendingTopicsProcessed1.json`,
//   trendingTopicsProcessed2: `${BB_ROOT_FOLDER}/trendingTopicsProcessed2.json`,
//   // Trending Topics Info
//   trendingTopicsInfo0a: `${BB_ROOT_FOLDER}/trendingTopicsInfo0a.txt`,
//   trendingTopicsInfo0b: `${BB_ROOT_FOLDER}/trendingTopicsInfo0b.txt`,
//   trendingTopicsInfo0c: `${BB_ROOT_FOLDER}/trendingTopicsInfo0c.txt`,
//   trendingTopicsInfo1a: `${BB_ROOT_FOLDER}/trendingTopicsInfo1a.txt`,
//   trendingTopicsInfo1b: `${BB_ROOT_FOLDER}/trendingTopicsInfo1b.txt`,
//   trendingTopicsInfo1c: `${BB_ROOT_FOLDER}/trendingTopicsInfo1c.txt`,
//   trendingTopicsInfo2a: `${BB_ROOT_FOLDER}/trendingTopicsInfo2a.txt`,
//   trendingTopicsInfo2b: `${BB_ROOT_FOLDER}/trendingTopicsInfo2b.txt`,
//   trendingTopicsInfo2c: `${BB_ROOT_FOLDER}/trendingTopicsInfo2c.txt`,
// };

const getRegexMatch = (propKey, regex) => {
  const match = propKey.match(regex);
  if (match) {
    return match;
  }

  return null;
};

const getTrendingTopicsProcessedMatch = (propKey) => {
  return getRegexMatch(propKey, /^trendingTopicsProcessed(\d+)$/);
};

const getTrendTopicsProcessedFormIdMatch = (formId) => {
  // match ${BB_ROOT_FOLDER}/trendingTopicsProcessedX.json
  const regexString = `${BB_ROOT_FOLDER}/trendingTopicsProcessed(\\d+).json`;
  return getRegexMatch(formId, new RegExp(regexString));
};

const getTrendingTopicsInfoMatch = (propKey) => {
  // return getRegexMatch(propKey, /^trendingTopicsInfo(\d+)([a-z])$/);

  const regexString = `${BB_ROOT_FOLDER}/trendingTopicsInfo(\\d+)([a-z]).txt`;

  return getRegexMatch(propKey, new RegExp(regexString));
};

export const bbFilePaths = new Proxy(
  {
    // Date
    date: `${BB_ROOT_FOLDER}/date.txt`,
    dateProcessed: `${BB_ROOT_FOLDER}/dateProcessed.json`,
    // Chosen Character
    chosenCharacter: `${BB_ROOT_FOLDER}/chosenCharacter.txt`,
    chosenCharacterProcessed: `${BB_ROOT_FOLDER}/chosenCharacterProcessed.json`,
    // News Landscape
    newsLandscape: `${BB_ROOT_FOLDER}/newsLandscape.txt`,
    newsLandscapeProcessed: `${BB_ROOT_FOLDER}/newsLandscapeProcessed.json`,
    // Trending Topics Count
    trendingTopicsCount: `${BB_ROOT_FOLDER}/trendingTopicsCount.txt`,
    trendingTopicsCountProcessed: `${BB_ROOT_FOLDER}/trendingTopicsCountProcessed.json`,
    // Trending Topics Detail Level
    trendingTopicsDetailLevel: `${BB_ROOT_FOLDER}/trendingTopicsDetailLevel.txt`,
    trendingTopicsDetailLevelProcessed: `${BB_ROOT_FOLDER}/trendingTopicsDetailLevelProcessed.json`,
    // Trending Topics
    trendingTopics: `${BB_ROOT_FOLDER}/trendingTopics.txt`,
  },
  {
    get(target, propKey, receiver) {
      // Match properties like 'dateProcessed'
      if (propKey in target) {
        return Reflect.get(target, propKey, receiver);
      }

      // Match properties like 'trendingTopicsProcessedX'
      const processedMatch = getTrendingTopicsProcessedMatch(propKey);
      if (processedMatch) {
        const index = processedMatch[1];
        return `${BB_ROOT_FOLDER}/trendingTopicsProcessed${index}.json`;
      }

      // Match properties like 'trendingTopicsInfoXLetter'
      const infoMatch = getTrendingTopicsInfoMatch(propKey);
      if (infoMatch) {
        const index = infoMatch[1];
        const letter = infoMatch[2];
        return `${BB_ROOT_FOLDER}/trendingTopicsInfo${index}${letter}.txt`;
      }

      // If property doesn't match any pattern, return undefined or handle accordingly
      return undefined;
    },
  }
);

// export const getLabelFromBBFormId = (formId) => {
//   if (formId === bbFilePaths.date) {
//     return BROADCAST_BUILDER_FORM_LABELS.DATE;
//   }

//   if (formId === bbFilePaths.dateProcessed) {
//     return BROADCAST_BUILDER_FORM_LABELS.DATE_PROCESSED;
//   }

//   if (formId === bbFilePaths.trendingTopics) {
//     return BROADCAST_BUILDER_FORM_LABELS.TRENDING_TOPICS;
//   }

//   const trendingTopicsProcessedFormIdMatch =
//     getTrendTopicsProcessedFormIdMatch(formId);

//   if (trendingTopicsProcessedFormIdMatch) {
//     const index = trendingTopicsProcessedFormIdMatch[1];
//     return `Trending Topics Processed ${index}`;
//   }

//   return null;
// };

export const getValueBasedOnFormId = (
  formId,
  {
    dateResult,
    dateProcessedResult,
    chosenCharacter,
    chosenCharacterProcessed,
    newsLandscape,
    newsLandscapeProcessed,
    trendingTopicsCount,
    trendingTopicsCountProcessed,
    trendingTopicsDetailLevel,
    trendingTopicsDetailLevelProcessed,
    trendingTopicsResult,
    getTrendingTopicsProcessedFormIdMatchResult,
    getTrendingTopicsInfoMatchResult,
  }
) => {
  if (formId === bbFilePaths.date) {
    return dateResult;
  }

  if (formId === bbFilePaths.dateProcessed) {
    return dateProcessedResult;
  }

  if (formId === bbFilePaths.chosenCharacter) {
    return chosenCharacter;
  }

  if (formId === bbFilePaths.chosenCharacterProcessed) {
    return chosenCharacterProcessed;
  }

  if (formId === bbFilePaths.newsLandscape) {
    return newsLandscape;
  }

  if (formId === bbFilePaths.newsLandscapeProcessed) {
    return newsLandscapeProcessed;
  }

  if (formId === bbFilePaths.trendingTopicsCount) {
    return trendingTopicsCount;
  }

  if (formId === bbFilePaths.trendingTopicsCountProcessed) {
    return trendingTopicsCountProcessed;
  }

  if (formId === bbFilePaths.trendingTopicsDetailLevel) {
    return trendingTopicsDetailLevel;
  }

  if (formId === bbFilePaths.trendingTopicsDetailLevelProcessed) {
    return trendingTopicsDetailLevelProcessed;
  }

  if (formId === bbFilePaths.trendingTopics) {
    return trendingTopicsResult;
  }

  const trendingTopicsProcessedFormIdMatch =
    getTrendTopicsProcessedFormIdMatch(formId);

  if (trendingTopicsProcessedFormIdMatch) {
    const index = trendingTopicsProcessedFormIdMatch[1];
    return getTrendingTopicsProcessedFormIdMatchResult(index);
  }

  const trendingTopicsInfoMatch = getTrendingTopicsInfoMatch(formId);

  if (trendingTopicsInfoMatch) {
    const index = trendingTopicsInfoMatch[1];
    const letter = trendingTopicsInfoMatch[2];
    return getTrendingTopicsInfoMatchResult(index, letter);
  }

  return null;
};

export const getLabelFromBBFormId = (formId) => {
  return getValueBasedOnFormId(formId, {
    dateResult: BROADCAST_BUILDER_FORM_LABELS.DATE,
    dateProcessedResult: BROADCAST_BUILDER_FORM_LABELS.DATE_PROCESSED,
    chosenCharacter: BROADCAST_BUILDER_FORM_LABELS.CHOSEN_CHARACTER,
    chosenCharacterProcessed:
      BROADCAST_BUILDER_FORM_LABELS.CHOSEN_CHARACTER_PROCESSED,
    newsLandscape: BROADCAST_BUILDER_FORM_LABELS.NEWS_LANDSCAPE,
    newsLandscapeProcessed:
      BROADCAST_BUILDER_FORM_LABELS.NEWS_LANDSCAPE_PROCESSED,
    trendingTopicsCount: BROADCAST_BUILDER_FORM_LABELS.TRENDING_TOPICS_COUNT,
    trendingTopicsCountProcessed:
      BROADCAST_BUILDER_FORM_LABELS.TRENDING_TOPICS_COUNT_PROCESSED,
    trendingTopicsDetailLevel:
      BROADCAST_BUILDER_FORM_LABELS.TRENDING_TOPICS_DETAIL_LEVEL,
    trendingTopicsDetailLevelProcessed:
      BROADCAST_BUILDER_FORM_LABELS.TRENDING_TOPICS_DETAIL_LEVEL_PROCESSED,
    trendingTopicsResult: BROADCAST_BUILDER_FORM_LABELS.TRENDING_TOPICS,
    getTrendingTopicsProcessedFormIdMatchResult: (index) =>
      `Trending Topics Processed ${index}`,
    getTrendingTopicsInfoMatchResult: (index, letter) =>
      `Trending Topics Info ${index}${letter}`,
  });
};

export const getValidationDataFromFormId = (formId) => {
  return getValueBasedOnFormId(formId, {
    dateResult: {
      min: 1,
      max: 100,
    },
    dateProcessedResult: {
      min: 1,
      max: 2500,
    },
    trendingTopicsResult: {
      min: 1,
      max: 6000000,
    },
    getTrendingTopicsProcessedFormIdMatchResult: () => {
      return {
        min: 1,
        max: 6000000,
      };
    },
    getTrendingTopicsInfoMatchResult: () => {
      return {
        min: 1,
        max: 6000000,
      };
    },
  });
};
/*
  const scaleOptionData = [
    // {
    //   onSelect: () => {
    //     setState(["scale"], 7);
    //   },
    // },
    {
      onSelect: () => {
        setState(["scale"], 0.125);
      },
      value: 0.125,
    },
    {
      onSelect: () => {
        setState(["scale"], 0.25);
      },
      value: 0.25,
    },
    {
      onSelect: () => {
        setState(["scale"], 0.5);
      },
      value: 0.5,
    },
    {
      onSelect: () => {
        setState(["scale"], 1);
      },
      value: 1,
    },
    {
      onSelect: () => {
        // console.log("setting scale to 2");
        setState(["scale"], 2);
      },
      value: 2,
    },
    {
      onSelect: () => {
        // console.log("setting scale to 3");
        setState(["scale"], 3);
      },
      value: 3,
    },

    {
      onSelect: () => {
        // console.log("setting scale to 4");
        setState(["scale"], 4);
      },
      value: 4,
    },

    // {},
  ]
*/

// extract zoom levels from above commented out code

export const ZOOM_LEVELS = {
  MACRO_OF_MACRO: 0.125,
  MACRO_POWER: 0.25,
  MACRO: 0.5,
  NORMAL: 1,
  MICRO: 2,
  MICRO_POWER: 3,
  MICRO_OF_MICRO: 4,
  NANO: 6,
};
