const getCoords = ({
  isZoomed,
  settings,
  w,
  h,
  smolText,

  // TODO: move to local constants
  BORDER_RADIUS,
  BORDER_RADIUS_2,
  Z_1,
}) => {
  let coords;

  if (isZoomed)
    coords = {
      position: "absolute",
      top: settings.yOffset + 5,
      right: w - settings.xOffset + 2,
      maxWidth: w * 0.65,
      width: "200%",

      minHeight: 26,

      borderTopLeftRadius: BORDER_RADIUS_2,
      borderTopRightRadius: BORDER_RADIUS_2,
      borderBottomLeftRadius: BORDER_RADIUS_2,
      borderBottomRightRadius: BORDER_RADIUS_2,

      backgroundColor: "white",

      color: "black",

      zIndex: Z_1 + 2,

      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",

      wordWrap: "break-word",
      wordBreak: "break-word",

      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: smolText ? 2 : 5.7,
      paddingRight: smolText ? 2 : 4.5,
    };
  else {
    coords = {
      position: "absolute",
      bottom: h - settings.yOffset,
      right: w - settings.xOffset,
      maxWidth: 2 * w,
      width: "200%",

      borderTopLeftRadius: BORDER_RADIUS,
      borderTopRightRadius: BORDER_RADIUS,
      borderBottomLeftRadius: BORDER_RADIUS,
      borderBottomRightRadius: 0,

      backgroundColor: "white",

      color: "black",

      zIndex: Z_1 + 2,

      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",

      wordWrap: "break-word",
      wordBreak: "break-word",

      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: smolText ? 7.5 : 8,
    };
  }

  return coords;
};

export default getCoords;
