// import {
// //   // EVENT,
// //   EVENT_TYPES,
// // } from "../../constants.mjs";
// import setValue from "../../dino/setValue.mjs";
import { EVENT_TYPES } from "../../constants";
import {
  getState,
  setState,
  // setState,
  // oldeFashionedStateManagement,
} from "../../reactStateManagement";
// import delay from "../delay";
// import delay from "../delay";
import { emitEvent } from "../emitEvent";
import getAuth from "../getAuth";
import getIfBCharDatumDiff from "./getIfBCharDatumDiff";
// import getDoPromiseInQueue from "../getDoPromiseInQueue";
// import { emitEvent } from "../emitEvent.mjs";

// import { getWCharacterData } from "./getWCharacterData.mjs";

// const doPromiseInQueue = getDoPromiseInQueue();

/*
    BCHAR: {
        "x": "49.99999998049723%",
        "y": "0%",
        "lastUpdated": 1728814409972,
        "metaStateA": false,
        "xMomentum": 0,
        "lastX": "49.99999998049723%",
        "lastY": "0%",
        "lastXMomentum": 0,
        "lastYMomentum": 0,
        "character": "AQUA",
        "fashionBottom": {
          "imageUrlMove": "/images/aqua_1_bottom_1.png",
          "imageUrl": "/images/aqua_0_bottom_1.png"
        },
        "fashionTop": {
          "imageUrlMove": "/images/aqua_1_top_0.png",
          "imageUrl": "/images/aqua_0_top_0.png"
        },
        "address": "0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E"
      }
    */

// TODO: move comparison to a separate function

const twoLevelDeepCopy = (obj) => {
  const newObj = {
    ...obj,
  };

  for (const key in obj) {
    const value = obj[key];

    if (typeof value === "object") {
      newObj[key] = {
        ...value,
      };
    }
  }

  return newObj;
};

const updateBCharCore = ({
  // cterData,
  mutateFunction,
  bBroadcast,
  customCompareBCharDatum,
  callback,

  // sourceTag = "no-source-tag",
}) => {
  const bCharDatum = getState("bCharDatum");

  // const originalBCharDatum = twoLevelDeepCopy(bCharDatum);
  const originalBCharDatum =
    typeof customCompareBCharDatum !== "undefined"
      ? customCompareBCharDatum
      : twoLevelDeepCopy(bCharDatum);

  let newBCharDatum = mutateFunction(bCharDatum);

  if (newBCharDatum === bCharDatum) {
    newBCharDatum = {
      ...newBCharDatum,
    };
  }

  const newBCharDatumCopy = twoLevelDeepCopy(newBCharDatum);

  if (!newBCharDatum) {
    return;
  }

  // if (newBCharDatum.x !== "50%") {
  //   console.log(
  //     "SETTING BCHAR DATUM OTHER: ",
  //     JSON.stringify(newBCharDatum, null, 2)
  //   );
  // }

  // if (sourceTag !== "bEventLoop") {
  //   console.log("SETTING BCHAR DATUM: " + sourceTag);
  // }
  // await delay(100);

  // console.log(stateValue);
  // // will log 'banana'

  // stateSetter("apple");

  setState(["bCharDatum"], newBCharDatum);

  // stateSetter(newBCharDatum);

  if (bBroadcast) {
    const auth = getAuth(true);

    if (!auth) {
      return;
    }

    // TODO: only broadcast if the bCharDatum has changed

    // console.log("BCHAR: " + JSON.stringify(newBCharDatum, null, 2));

    const diff = getIfBCharDatumDiff(originalBCharDatum, newBCharDatumCopy);

    // if (diff) {
    //   console.log(
    //     "USING CUSTOM: " + !!customCompareBCharDatum + ", DIFF: " + diff
    //   );
    // }

    if (diff) {
      emitEvent({
        type: EVENT_TYPES.BCHAR_BROADCAST,
        data: {
          auth,
          bCharDatum: newBCharDatum,
        },
      });

      setState(["lastBroadcastedBCharDatum"], newBCharDatum);
    }
  }
  // else {
  //   const diff = getIfBCharDatumDiff(originalBCharDatum, newBCharDatumCopy);

  //   if (diff) {
  //     console.log(
  //       "non-broadcasted bCharDatum diff: " + diff,
  //       customCompareBCharDatum
  //     );
  //   }
  // }

  if (callback) {
    callback();
  }
};

const updateBChar = async ({
  mutateFunction,
  bBroadcast = false,
  customCompareBCharDatum,
  sourceTag,
  callback,
}) => {
  // await doPromiseInQueue({
  //   operation: async () => {
  updateBCharCore({
    mutateFunction,
    bBroadcast,
    customCompareBCharDatum,
    sourceTag,
    callback,
  });
  // },
  // });
};

export default updateBChar;
