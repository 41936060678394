import setArbitraryBCharDatumKeyValuePair from "../bChar/setArbitraryBCharDatumKeyValuePair";

const doSelectCharacter = async ({
  character,
  fashionBottom = null,
  fashionTop = null,
  fashionHat = null,
}) => {
  await setArbitraryBCharDatumKeyValuePair({
    keyValuePairs: [
      { key: "character", value: character },
      {
        key: "fashionBottom",
        value: fashionBottom,
      },
      {
        key: "fashionTop",
        value: fashionTop,
      },
      {
        key: "fashionHat",
        value: fashionHat,
      },
      {
        key: "fun",
        value: null,
      },
    ],
  });
};

export default doSelectCharacter;
