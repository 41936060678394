import React from "react";

export const BotScreenCaptureUser = () => {
  return (
    <div
      style={{
        color: "white",
        textAlign: "center",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {"U"}
      <br />
      {"S"}
      <br />
      {"E"}
      <br />
      {"R"}
    </div>
  );
};

export default BotScreenCaptureUser;
