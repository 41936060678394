import React from "react";
import {
  ITEM_TYPES,
  POWER_MODES,
  // EVENT_TYPES,
  Z_INDICES,
  ZOOM_LEVELS,
} from "../../../constants";
import useGameComponent from "../../../utils/effects/useGameComponent";
import DragDropComponent from "../../../commonComponents/DragDropComponent";
import { getState, setState } from "../../../reactStateManagement";
import scan from "../../../utils/world/scan";
// import AttackButton from "../../AttackButton/AttackButton";
import Spacer from "../../../commonComponents/Spacer";
import StagingComponent from "../../../StagingComponent";
import GameComponentButton from "../../../commonComponents/GameComponentButton";

const MIN_TIME_BETWEEN_SCANS = 1000 * 10; // 10 seconds

const LEFT_SIDE_WIDTH_PERCENT_DECIMAL = 0.625;
const RIGHT_SIDE_WIDTH_PERCENT_DECIMAL =
  1 - LEFT_SIDE_WIDTH_PERCENT_DECIMAL - 0.0;

const NeoControls2 = ({ windowWidth }) => {
  const id = "neo-controls-2";

  const mobile = getState("mobile");
  const unreadMessages = getState("unreadMessages");

  useGameComponent({
    id,
  });

  const width = windowWidth / 2;

  const leftSideWidth = width * LEFT_SIDE_WIDTH_PERCENT_DECIMAL;

  const rightSideWidth = width * RIGHT_SIDE_WIDTH_PERCENT_DECIMAL;

  const scaleOptionData = [
    // {
    //   onSelect: () => {
    //     setState(["scale"], 7);
    //   },
    // },
    {
      onSelect: () => {
        setState(["scale"], ZOOM_LEVELS.MACRO_OF_MACRO);
      },
      value: ZOOM_LEVELS.MACRO_OF_MACRO,
    },
    {
      onSelect: () => {
        setState(["scale"], ZOOM_LEVELS.MACRO_POWER);
      },
      value: ZOOM_LEVELS.MACRO_POWER,
    },
    {
      onSelect: () => {
        setState(["scale"], ZOOM_LEVELS.MACRO);
      },
      value: ZOOM_LEVELS.MACRO,
    },
    {
      onSelect: () => {
        setState(["scale"], ZOOM_LEVELS.NORMAL);
      },
      value: ZOOM_LEVELS.NORMAL,
    },
    {
      onSelect: () => {
        // console.log("setting scale to 2");
        setState(["scale"], ZOOM_LEVELS.MICRO);
      },
      value: ZOOM_LEVELS.MICRO,
    },
    {
      onSelect: () => {
        // console.log("setting scale to 3");
        setState(["scale"], ZOOM_LEVELS.MICRO_POWER);
      },
      value: ZOOM_LEVELS.MICRO_POWER,
    },

    {
      onSelect: () => {
        // console.log("setting scale to 4");
        setState(["scale"], ZOOM_LEVELS.MICRO_OF_MICRO);
      },
      value: ZOOM_LEVELS.MICRO_OF_MICRO,
    },

    // {},
  ];

  if (!mobile) {
    scaleOptionData.push({
      onSelect: () => {
        // console.log("setting scale to 6");
        setState(["scale"], ZOOM_LEVELS.NANO);
      },
      value: ZOOM_LEVELS.NANO,
    });
  }
  const lastScanTime = getState("lastScanTime");

  const scanActive = Date.now() - lastScanTime > MIN_TIME_BETWEEN_SCANS;

  let timeUntilNextScanInSeconds;

  if (!scanActive) {
    timeUntilNextScanInSeconds = Math.ceil(
      (MIN_TIME_BETWEEN_SCANS - (Date.now() - lastScanTime)) / 1000
    );
  }

  const doScan = async () => {
    if (!scanActive) {
      return;
    }

    setState(["lastScanTime"], Date.now());
    await scan();
  };

  const doSelectBuildMode = () => {
    const buildMode = getState("buildMode");

    if (buildMode) {
      setState(["buildMode"], false);
      setState(["putObjectMode"], false);
    } else {
      setState(["buildMode"], true);

      // temporary
      setState(["putObjectMode"], {
        itemType: ITEM_TYPES.TREE_0,
      });
    }
  };

  const toggleChatMode = () => {
    const messageMode = getState("messageMode");

    setState(["messageMode"], !messageMode);
  };

  const loginMode = getState("loginMode");
  const toggleMenu = () => {
    // loginMode is menu (incorrectly named)

    setState(["loginMode"], !loginMode);
  };

  return (
    <div
      id={id}
      style={{
        // width: "50%",
        width,
        height: "100%",
        // backgroundColor: "beige",
        // backgroundColor: "pink",

        // position: "absolute",
        // bottom: 0,
        // left: (windowWidth - MESSAGE_BOX_WIDTH) / 2,
        // right: 0,

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",

        zIndex: Z_INDICES.TOP_LAYER,
      }}
    >
      <div
        id={id}
        style={{
          width: leftSideWidth,
          height: "100%",
          // backgroundColor: "beige",
          // backgroundColor: "purple",

          // position: "absolute",
          // bottom: 0,
          // left: (windowWidth - MESSAGE_BOX_WIDTH) / 2,
          // right: 0,

          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          // alignItems: "flex-start",
          // justifyContent: mobile ? "flex-start" : "flex-end",
          // justifyContent: mobile ? "flex-end" : "flex-end",
          alignItems: mobile ? "flex-start" : "flex-end",

          zIndex: Z_INDICES.TOP_LAYER,
        }}
      >
        <DragDropComponent
          id={"drag-drop-component-power-mode"}
          size={Math.min(leftSideWidth / 3, 40)}
          optionData={[
            {
              onSelect: () => {
                setState(["powerMode"], POWER_MODES.MOON);
              },
              value: POWER_MODES.MOON,
            },
            {
              onSelect: () => {
                setState(["powerMode"], POWER_MODES.X);
              },
              value: POWER_MODES.X,
            },
            {
              onSelect: () => {
                setState(["powerMode"], POWER_MODES.POWER);
              },
              value: POWER_MODES.POWER,
            },
          ]}
          value={getState("powerMode")}
        />
        <DragDropComponent
          id={"drag-drop-component-scale"}
          size={
            // leftSideWidth / 4
            Math.min(leftSideWidth / scaleOptionData.length, 40)
          }
          optionData={scaleOptionData}
          minHeight={40}
          borderRadius={5}
          value={getState("scale")}
        />

        <div
          style={{
            height: 25,
            width: "100%",
            // backgroundColor: "red",

            display: "flex",
            flexDirection: "row",
            // justifyContent: "flex-start",
            justifyContent: mobile ? "flex-start" : "flex-end",
            alignItems: "center",
          }}
        >
          <div
            style={{
              order: 2,
              height: "100%",
              width: 60,
              maxWidth: "50%",
              // backgroundColor: "red",
            }}
          >
            <button
              style={{
                height: "100%",
                width: "100%",

                backgroundColor: scanActive ? "darkred" : "#400000",

                color: scanActive ? "black" : "darkgray",
              }}
              onClick={doScan}
              onTouchStart={doScan}
              disabled={!scanActive}
            >
              {scanActive ? "SCAN" : String(timeUntilNextScanInSeconds)}
              {/* {"SCAN"} */}
            </button>
          </div>
          <div
            style={{
              order: 1,
              height: "100%",
              width: 60,
              maxWidth: "50%",
              // backgroundColor: "red",
            }}
          >
            <button
              style={{
                height: "100%",
                width: "100%",

                backgroundColor: "darkred",
                // backgroundColor: "purple",

                color: "black",
              }}
              onClick={doSelectBuildMode}
              onTouchStart={doSelectBuildMode}
            >
              {"BUILD"}
            </button>
          </div>
        </div>
      </div>
      <div
        id={id}
        style={{
          width: rightSideWidth,
          height: "100%",
          // backgroundColor: "beige",
          // backgroundColor: "violet",

          // position: "absolute",
          // bottom: 0,
          // left: (windowWidth - MESSAGE_BOX_WIDTH) / 2,
          // right: 0,

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",

          zIndex: Z_INDICES.TOP_LAYER,
        }}
      >
        <div
          style={{
            height: 25,
            width: "100%",
            // backgroundColor: "red",

            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              height: "100%",
              width: 75,
              maxWidth: "40%",
              // backgroundColor: "red",
            }}
          >
            {
              // badge={unreadMessages > 0 ? unreadMessages : undefined}

              unreadMessages > 0 ? (
                <div
                  style={{
                    position: "absolute",
                    top: -15,
                    // right: 0,
                    right: mobile ? -5 : 0,
                    width: 20,
                    height: 20,
                    backgroundColor: "red",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  {unreadMessages}
                </div>
              ) : null
            }
            <button
              style={{
                height: "100%",

                width: "100%",
                // width: 75,
                // maxWidth: "40%",
                backgroundColor: "darkred",
                color: "black",
              }}
              onClick={toggleChatMode}
              onTouchStart={toggleChatMode}
            >
              {/* {"C"} */}

              {mobile ? "C" : "CHAT"}
            </button>
          </div>
          <div
            style={{
              position: "relative",
              height: "100%",
              width: 75,
              maxWidth: "40%",
              // backgroundColor: "red",
            }}
          >
            <button
              style={{
                height: "100%",

                // width: 75,
                width: "100%",
                // maxWidth: "40%",
                // backgroundColor: "darkred",

                backgroundColor: loginMode ? "#CD0000" : "darkred",
                color: "black",
              }}
              onClick={toggleMenu}
              onTouchStart={toggleMenu}
            >
              {/* {"M"} */}
              {mobile ? "M" : "MENU"}
            </button>
          </div>
        </div>

        <div
          style={{
            // height: 60,
            height: mobile ? 60 : 80,
            width: "100%",
            backgroundColor: "black",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // flexDirection: "row",
            // flexDirection: mobile ? "column" : "row",
            // // justifyContent: "flex-end",
            // justifyContent: mobile ? "center" : "flex-end",
            alignItems: "center",
          }}
        >
          {/* <AttackButton bottom={0} /> */}
          {/* <Spacer width={15} /> */}
          {/* <Spacer width={mobile ? 0 : 25} /> */}

          <StagingComponent
            productionElement={<Spacer width={mobile ? 0 : 25} />}
            overrideStagingOff={true}
          >
            {/* <button
              style={{
                height: "100%",

                // width: 75,
                width: "100%",
                // maxWidth: "40%",
                // backgroundColor: "darkred",

                backgroundColor: loginMode ? "#CD0000" : "darkred",
                color: "black",
              }}
              onClick={toggleMenu}
              onTouchStart={toggleMenu}
            >
              {"i"}
            </button> */}

            <GameComponentButton
              id={"inventory-button"}
              onClick={() => {
                // console.log("inventory button clicked");

                const inventoryMode = getState("inventoryMode");

                setState(["inventoryMode"], !inventoryMode);
              }}
              style={{
                color: "black",

                height: 25,
                backgroundColor: "darkred",

                width: 25,
              }}
            >
              {"i"}
            </GameComponentButton>
          </StagingComponent>
        </div>
      </div>
    </div>
  );
};

export { NeoControls2 };
