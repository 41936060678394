import refreshBk from "../../../../utils/bChar/refreshBk";

const MULTI_BK_REFRESH_TIMES = [0, 25, 50, 100, 200, 500, 750, 1000, 1500];

const multiRefreshBroadcastKey = () => {
  /*
    Refresh BK multiple times in a row 
    to ensure that the character is updated
  */

  for (const time of MULTI_BK_REFRESH_TIMES) {
    setTimeout(() => {
      // console.log("multiRefreshBroadcastKey - time", time);
      refreshBk();
    }, time);
  }
};

export default multiRefreshBroadcastKey;
