import React, { useEffect } from "react";
import {
  bottomFashionUrlsThatRequiresBottomCensor,
  NONE,
  STANDARD_BUTTON_HEIGHT,
  topFashionUrlsThatRequiresTopCensor,
} from "../../../../constants";
import { getState, setState } from "../../../../reactStateManagement";
import updateBChar from "../../../../utils/bChar/updateBChar";
import getFashionImage from "../../../../utils/character/getFashionImage";
import setArbitraryBCharDatumKeyValuePair from "../../../../utils/bChar/setArbitraryBCharDatumKeyValuePair";
import FashionSelectNFTMode from "./FashionSelectNFTMode/FashionSelectNFTMode";
// import StagingComponent from "../../../../StagingComponent";
// import html2canvas from "html2canvas";

/*
    Sample Data Format - assume all image urls are valid

  [CHARACTERS.DOGE_FASHION]: {
    imageUrlA: "imageA",
    imageUrlMove: "imageMove",
    imageUrlARight: "imageARight",
    imageUrlMoveRight: "imageMoveRight",
    wardrobe: {
      base: "base",
      baseMove: "baseMove",
      tops: [
        {
          imageUrl: "top_0",
          imageUrlMove: "top_1",
        },
      ],
      bottoms: [
        {
          imageUrl: 'bottoms_a',
          imageUrlMove: 'bottoms_b'
        },
      ],
    },
*/

// function ImageOverlay() {
//   const [canvas, setCanvas] = useState(null);

//   useEffect(() => {
//     const img1 = new Image();
//     const img2 = new Image();

//     img1.src = 'path/to/first/png';
//     img2.src = 'path/to/second/png';

//     img1.onload = () => {
//       const c = document.createElement('canvas');
//       const ctx = c.getContext('2d');
//       c.width = img1.width;
//       c.height = img1.height;
//       ctx.drawImage(img1, 0, 0);

//       img2.onload = () => {
//         ctx.globalAlpha = 0.5;
//         ctx.drawImage(img2, 0, 0);
//         setCanvas(c);
//       };
//     };
//   }, []);

// }
// const getFashionImage = ({ baseImageUrl, overlayImageUrls = [] }) => {
//   const canvas = document.createElement("canvas");
//   const ctx = canvas.getContext("2d");

//   const baseImage = new Image();
//   baseImage.src = baseImageUrl;

//   baseImage.onload = () => {
//     canvas.width = baseImage.width;
//     canvas.height = baseImage.height;

//     ctx.drawImage(baseImage, 0, 0);

//     overlayImageUrls.forEach((overlayImageUrl) => {
//       const overlayImage = new Image();
//       overlayImage.src = overlayImageUrl;

//       overlayImage.onload = () => {
//         ctx.drawImage(overlayImage, 0, 0);
//       };
//     });
//   };

//   return canvas;
// };

const getImage = async ({
  fashionHatImageUrl,
  fashionTopImageUrl,
  fashionTopImageUrlBack,
  fashionBottomImageUrl,
  fashionBottomImageUrlXOffset,
  characterDatum,
}) => {
  if (!fashionHatImageUrl && !fashionTopImageUrl && !fashionBottomImageUrl) {
    return characterDatum.imageUrlA;
  }

  const preBaseImageUrls = [];

  if (fashionTopImageUrlBack) {
    preBaseImageUrls.push(fashionTopImageUrlBack);
  }

  //   return characterDatum.wardrobe.base;
  const baseImageUrl = characterDatum.wardrobe.base;

  const TEST_OVERRIDE = false;

  if (TEST_OVERRIDE) {
    return baseImageUrl;
  }

  const overlayImageUrls = [];
  const eighteenPlusMode = getState("eighteenPlusMode");

  if (fashionBottomImageUrl && fashionBottomImageUrl !== NONE) {
    overlayImageUrls.push({
      xOffset: fashionBottomImageUrlXOffset,
      url: fashionBottomImageUrl,
    });

    if (!eighteenPlusMode) {
      if (
        bottomFashionUrlsThatRequiresBottomCensor.includes(
          fashionBottomImageUrl
        )
      ) {
        const bottomCensorImageUrl =
          characterDatum.wardrobe?.bottomCensors?.[0]?.imageUrl;
        if (bottomCensorImageUrl) {
          overlayImageUrls.push(bottomCensorImageUrl);
        }
      }
    }
  } else if (!fashionBottomImageUrl || fashionBottomImageUrl === NONE) {
    if (!eighteenPlusMode) {
      const bottomCensorImageUrl =
        characterDatum.wardrobe?.bottomCensors?.[0]?.imageUrl;
      if (bottomCensorImageUrl) {
        overlayImageUrls.push(bottomCensorImageUrl);
      }
    }
  }

  if (fashionTopImageUrl && fashionTopImageUrl !== NONE) {
    overlayImageUrls.push(fashionTopImageUrl);

    if (!eighteenPlusMode) {
      if (topFashionUrlsThatRequiresTopCensor.includes(fashionTopImageUrl)) {
        const topCensorImageUrl =
          characterDatum.wardrobe?.topCensors?.[0]?.imageUrl;
        if (topCensorImageUrl) {
          overlayImageUrls.push(topCensorImageUrl);
        }
      }
    }
  } else if (!fashionTopImageUrl || fashionTopImageUrl === NONE) {
    if (!eighteenPlusMode) {
      const topCensorImageUrl =
        characterDatum.wardrobe?.topCensors?.[0]?.imageUrl;
      if (topCensorImageUrl) {
        overlayImageUrls.push(topCensorImageUrl);
      }
    }
  }

  if (fashionHatImageUrl && fashionHatImageUrl !== NONE) {
    overlayImageUrls.push(fashionHatImageUrl);
  }

  const fashionImage = await getFashionImage({
    preBaseImageUrls,
    baseImageUrl,
    overlayImageUrls,
  });

  return fashionImage;
};

const FashionDisplay = ({ bCharDatum, characterDatum, height }) => {
  const [image, setImage] = React.useState(null);

  const fashionHatImageUrl = bCharDatum.fashionHat?.imageUrl;
  const fashionTopImageUrl = bCharDatum.fashionTop?.imageUrl;
  const fashionTopImageUrlBack = bCharDatum.fashionTop?.imageUrlBack;
  const fashionBottomImageUrl = bCharDatum.fashionBottom?.imageUrl;
  const fashionBottomImageUrlXOffset = bCharDatum.fashionBottom?.xOffset;

  useEffect(() => {
    getImage({
      fashionHatImageUrl,
      fashionTopImageUrl,
      fashionTopImageUrlBack,
      fashionBottomImageUrl,
      fashionBottomImageUrlXOffset,
      characterDatum,
    })
      .then((i) => {
        // setImage(i);

        // const fullUrl = `${window.location.origin}${i}`;

        // console.log(`

        //     MEGA LOG ${JSON.stringify(
        //       {
        //         fullUrl,
        //       },
        //       null,
        //       4
        //     )}
        //     )

        // `);

        setImage(i);
      })
      .catch((e) => {
        console.error("Error in getImage");
        console.error(e);
      });
  }, [
    fashionHatImageUrl,
    fashionTopImageUrl,
    fashionBottomImageUrl,

    characterDatum,
  ]);

  // const DEV_MODE = true;
  const DEV_MODE = false;

  return (
    <div
      style={{
        position: "relative",

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        height: height,

        // background: "green",

        background: DEV_MODE ? "green" : "black",
      }}
    >
      {DEV_MODE ? (
        <div
          style={{
            height: (height - 35) / 2,
            width: "100%",
            fontSize: 12,
          }}
        >
          {JSON.stringify(bCharDatum, null, 4)}
        </div>
      ) : null}
      <div
        style={{
          height: (height - 35) / (DEV_MODE ? 2 : 1),
          width: "100%",

          background: DEV_MODE ? "orange" : "black",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          alt="character-with-fashion"
          src={image}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
      </div>
      <button
        style={{
          position: "absolute",

          bottom: 0,
          left: 0,

          width: 125,
          maxWidth: "60%",
          height: 35,

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background: "blue",
          color: "beige",
        }}
        onClick={() => {
          updateBChar({
            mutateFunction: (bCharDatum) => {
              // const newBCharDatum = {
              //   ...bCharDatum,
              // };

              delete bCharDatum.fashionHat;
              delete bCharDatum.fashionTop;
              delete bCharDatum.fashionBottom;

              return bCharDatum;
            },
            bBroadcast: true,
          });
        }}
      >
        {"Default"}
      </button>

      {/* <StagingComponent overrideStagingOff={false}> */}
      <button
        style={{
          position: "absolute",

          bottom: 0,
          right: 0,

          width: 125,
          maxWidth: "35%",
          height: 35,

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background: "blue",
          color: "beige",
        }}
        onClick={() => {
          const fashionSelectNFTMode = getState("fashionSelectNFTMode");

          setState(["fashionSelectNFTMode"], !fashionSelectNFTMode);
        }}
      >
        {"NFT"}
      </button>
      {/* </StagingComponent> */}
    </div>
  );
};

const getTitleFromKey = (key) => {
  switch (key) {
    case "hats":
      return "Hat";
    case "tops":
      return "Top";
    case "bottoms":
      return "Bottom";

    default:
      return key;
  }
};

const WardrobeButton = ({ text = "", bCharDatumKey, value }) => {
  return (
    <button
      style={{
        width: STANDARD_BUTTON_HEIGHT,
        height: STANDARD_BUTTON_HEIGHT,

        background: "blue",

        borderSizing: "border-box",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: "black",
      }}
      onClick={() => {
        // updateBChar({
        //   mutateFunction: (bCharDatum) => {
        //     return {
        //       ...bCharDatum,
        //       [bCharDatumKey]: value,
        //     };
        //   },
        //   bBroadcast: true,
        // });

        const originalValue = {
          ...value,
        };

        const valueToUse = {
          ...value,
        };

        delete valueToUse.compatibilities;

        const keyValuePairs = [
          {
            key: bCharDatumKey,
            value: valueToUse,
          },
          // {
          //   key: "fun",
          //   value: null,
          // }
        ];

        if (originalValue.compatibilities) {
          const bCharDatum = getState("bCharDatum");
          if (
            bCharDatum.fashionBottom &&
            bCharDatumKey !== "fashionBottom" &&
            !originalValue.compatibilities.includes(
              bCharDatum.fashionBottom.imageUrl
            )
          ) {
            keyValuePairs.push({
              key: "fashionBottom",
              value: null,
            });
          }

          if (
            bCharDatum.fashionTop &&
            bCharDatumKey !== "fashionTop" &&
            !originalValue.compatibilities.includes(
              bCharDatum.fashionTop.imageUrl
            )
          ) {
            keyValuePairs.push({
              key: "fashionTop",
              value: null,
            });
          }
        }

        if (value && (value.imageUrl !== NONE || value.imageUrlMove !== NONE)) {
          keyValuePairs.push({
            key: "fun",
            value: null,
          });
        }

        setArbitraryBCharDatumKeyValuePair({
          // key: bCharDatumKey,
          // value,
          keyValuePairs,
        });
      }}
    >
      {text}
    </button>
  );
};

const ButtonRow = ({ title, items, bCharDatumKey }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",

        // width: "100%",

        width: (items.length + 1) * STANDARD_BUTTON_HEIGHT + 75,
        height: STANDARD_BUTTON_HEIGHT,

        background: "black",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: 75,
          height: "100%",

          background: "yellow",
          color: "black",
        }}
      >
        {/* {title} */}
        {getTitleFromKey(title)}
      </div>

      <WardrobeButton
        text="x"
        bCharDatumKey={bCharDatumKey}
        value={{
          imageUrl: NONE,
          imageUrlMove: NONE,
        }}
      />

      {items.map((item, index) => {
        const value = {
          imageUrl: item.imageUrl,
          imageUrlMove: item.imageUrlMove,
          xOffset: item.imageUrlXOffset,
          xOffsetMove: item.imageUrlXOffsetMove,
        };

        if (item.imageUrlBack) {
          value.imageUrlBack = item.imageUrlBack;
        }

        if (item.imageUrlMoveBack) {
          value.imageUrlMoveBack = item.imageUrlMoveBack;
        }

        if (item.compatibilities) {
          value.compatibilities = item.compatibilities;
        }

        return (
          <WardrobeButton
            key={index}
            bCharDatumKey={bCharDatumKey}
            value={value}
          />
        );
      })}

      <div
        style={{
          width: 0,
          height: "100%",

          // background: "hotpink",

          // rainbow for testing

          background:
            "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",

          //

          // display: "flex",
        }}
      />
    </div>
  );
};

const clothingKeyData = [
  {
    key: "hats",
    bCharDatumKey: "fashionHat",
  },
  {
    key: "tops",
    bCharDatumKey: "fashionTop",
  },

  {
    key: "bottoms",
    bCharDatumKey: "fashionBottom",
  },
];

const FashionSelectMode = ({ characterDatum, height }) => {
  const bCharDatum = getState("bCharDatum");

  const fashionSelectNFTMode = getState("fashionSelectNFTMode");

  const fashionButtonRows = [];
  let content;
  let fashionDisplayHeight; //=
  let contentSectionHeight;

  if (fashionSelectNFTMode) {
    contentSectionHeight = 4 * STANDARD_BUTTON_HEIGHT;

    content = (
      <FashionSelectNFTMode
        height={contentSectionHeight}
        characterDatum={characterDatum}
        bCharDatum={bCharDatum}
      />
    );
    fashionDisplayHeight = height - contentSectionHeight;
  } else {
    // for (const key in characterDatum.wardrobe) {
    // for (const key of clothingKeys) {
    for (const { key, bCharDatumKey } of clothingKeyData) {
      if (characterDatum.wardrobe[key]) {
        const items = characterDatum.wardrobe[key];
        fashionButtonRows.push(
          <ButtonRow
            key={key}
            title={key}
            // items={items}

            // concat for testing
            items={
              items
              // .concat(items)
              // .concat(items)
              // .concat(items)
              // .concat(items)
            }
            bCharDatumKey={bCharDatumKey}
          />
        );
      }
    }

    content = null;
    // fashionDisplayHeight =
    //   height - fashionButtonRows.length * STANDARD_BUTTON_HEIGHT;

    contentSectionHeight =
      fashionButtonRows.length * STANDARD_BUTTON_HEIGHT + 15;
    fashionDisplayHeight = height - contentSectionHeight;
  }
  // const fashionDisplayHeight = height - 2 * STANDARD_BUTTON_HEIGHT;

  const contentStyle = content
    ? {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }
    : {
        display: "block",
        overflowX: "scroll",

        // red scroll bar against black background

        // background:
        scrollbarColor: "red black",
        // scrollbarWidth: "5
      };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        height: height,

        // background: "pink",
      }}
    >
      <FashionDisplay
        height={fashionDisplayHeight}
        characterDatum={characterDatum}
        bCharDatum={bCharDatum}
      />

      <div
        style={{
          position: "relative",
          // width: "100%",
          width: "100%",
          height: contentSectionHeight,

          ...contentStyle,
        }}
      >
        {content || fashionButtonRows}
      </div>
      {/* {characterDatum?.wardrobe?.tops ? (
        <ButtonRow
          title="Top"
          items={characterDatum.wardrobe.tops}
          bCharDatumKey="fashionTop"
        />
      ) : null}
      {characterDatum?.wardrobe?.bottoms ? (
        <ButtonRow
          title="Bottom"
          items={characterDatum.wardrobe.bottoms || []}
          bCharDatumKey="fashionBottom"
        />
      ) : null} */}
    </div>
  );
};

export default FashionSelectMode;
