import getDoPromiseInQueue from "./getDoPromiseInQueue";

const createExecutionQueue = (N) => {
  let currentExecutionCount = 0;

  const doPromiseInQueue = getDoPromiseInQueue();

  return async (fn, timeout = 1000 * 60 /* tag = "" */) => {
    if (currentExecutionCount >= N) {
      // console.log(
      //   "Queue is full, ignoring function " + tag ? "with tag: " + tag : ""
      // );
      return;
    }

    currentExecutionCount++;
    // console.log("running function in executionQueue:");
    // console.log(`currentExecutionCount: ${currentExecutionCount}`);
    await doPromiseInQueue({
      operation: async () => {
        let timeoutId;
        try {
          //   await fn();
          await Promise.race([
            new Promise((resolve, reject) => {
              timeoutId = setTimeout(() => {
                console.log("timeout in executionQueue");
                const error = new Error("timeout in executionQueue");
                reject(error);
              }, timeout);
            }),
            Promise.resolve().then(async () => {
              await fn();
            }),
          ]);
        } catch (error) {
          console.log("error in executionQueue:");
          console.log(error);
        } finally {
          clearTimeout(timeoutId);
          currentExecutionCount--;
        }
      },
    });
  };
};

export default createExecutionQueue;
