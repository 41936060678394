import { TOTAL_FORM_SCREEN_OUTPUT_TYPES } from "../../../../../constants";
import { setState } from "../../../../../reactStateManagement";

const handleTotalFormScreenOutput = async (event) => {
  const type = event?.data?.type;

  switch (type) {
    case TOTAL_FORM_SCREEN_OUTPUT_TYPES.FORM_CONFIGURATION: {
      const formElements = event?.data?.formElements;
      const formName = event?.data?.formName;

      if (
        !formElements ||
        !Array.isArray(formElements) ||
        formElements.length === 0
      ) {
        console.warn("handleTotalFormScreenOutput: invalid formElements");
        return;
      }

      if (!formName || typeof formName !== "string") {
        console.warn("handleTotalFormScreenOutput: invalid formName");
        return;
      }

      setState(["totalFormScreen"], {
        formName,
        formElements,
      });

      break;
    }
    default:
      console.warn("handleTotalFormScreenInput: unknown type:", type);
      break;
  }
};

export default handleTotalFormScreenOutput;
