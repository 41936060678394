import {
  BROVENT_TYPES,
  BROWSER_ALERT_TYPES,
  EVENT_TYPES,
  // PRODUCTION_MODE,
} from "../../../../constants.js";
import { getState, setState } from "../../../../reactStateManagement.js";
// import { emitEvent } from "../../../emitEvent.js";
import { setGuestTokenData } from "../../../guestToken.js";
import { messagesScrollDown } from "../../../visual/messagesScrollDown.js";
import handleApiResponse from "./handleApiResponse.js";
import getDoPromiseInQueue from "../../../getDoPromiseInQueue.js";
import { addEventToEventSenderEvents } from "../../addEventToEventSenderEvents.js";
// import {
//   DEFAULT_START_X_PERCENT_STRING,
//   DEFAULT_START_Y_PERCENT_STRING,
// } from "../../../../worldConstants.js";
import handleBCharData from "./handleBCharData.js";
import { cachePowerGetNWorldData } from "../../../world/cachePowerGetNWorldData.js";
import {
  getNWorldPartitionDataFromXandYDecimalPercent,
  getXDecimalPercentFromXPixel,
  getXPixelsFromXPercentString,
  getYDecimalPercentFromYPixel,
  getYPixelsFromYPercentString,
} from "../../../../coordsMapper.js";
import handlePowerNWorldData from "./handlePowerNWorldData.js";
import { handleBot2Data } from "./handleBot2Data.js";
import { handleBot2Interactions } from "./handleBot2Interactions.js";
import { handleBot6Data } from "./handleBot6Data.js";
import { handleBot6Interactions } from "./handleBot6Interactions.js";
import { handleBot6WorldData } from "./handleBot6WorldData.js";
import handleCreateImageResponse from "./handleCreateImageResponse.js";
import { handleRequestIdEventCompleted } from "./handleRequestIdEventCompleted.js";
import { handleBot6InteractionProcessingReceipts } from "./handleBot6InteractionProcessingReceipts.js";
import handleTeleport from "./handleTeleport.js";
import { setRespawn } from "../../../bChar/setRespawn.js";
import handleDoRespawn from "./handleDoRespawn.js";
import { getUserData } from "../../../../api/getUserData.js";
import delay from "../../../delay.js";
import { getMTokenData } from "../../../mToken.js";
import handleForceCuddle from "./handleForceCuddle.js";
import handleServerTriggeredUserAction from "./handleSTUA/handleSTUA.js";
import handleTotalFormScreenOutput from "./handleTotalFormScreenOutput/index.js";

// const DEBUG_LOG_ON = false;
// const DEBUG_LOG_ON = true;

const DEFAULT_START_X_DECIMAL_PERCENT = 0.5;
const DEFAULT_START_Y_DECIMAL_PERCENT = 0;

const characterDataDoPromiseInQueue = getDoPromiseInQueue(); // characterDataDoPromiseInQueue
const wCharacterDataDoPromiseInQueue = getDoPromiseInQueue();
// const nWorldDataDoPromiseInQueue = getDoPromiseInQueue();
const nWorldDataDoPromiseInQueue2 = getDoPromiseInQueue();
const nWorldDataDoPromiseInQueue2Atomic = getDoPromiseInQueue();
const botDataDoPromiseInQueue = getDoPromiseInQueue();
const worldDataDoPromiseInQueue = getDoPromiseInQueue();

// import { getEvent } from "../../../utils/getEvent";

export const handleEventCore = async (
  event
  // client // socket.io client
) => {
  const ignoreEventTypes = new Set([
    EVENT_TYPES.CHARACTER_DATA,
    EVENT_TYPES.W_CHARACTER_DATA,
    EVENT_TYPES.BOT_DATA,
    EVENT_TYPES.BCHAR_DATA,
  ]);

  if (getState("eventSenderEventsShowCharacterData")) {
    ignoreEventTypes.delete(EVENT_TYPES.CHARACTER_DATA);
  }
  // eventSenderEventsShowWCharacterData;
  if (getState("eventSenderEventsShowWCharacterData")) {
    ignoreEventTypes.delete(EVENT_TYPES.W_CHARACTER_DATA);
  }

  if (getState("eventSenderEventsShowBotData")) {
    ignoreEventTypes.delete(EVENT_TYPES.BOT_DATA);
  }

  if (getState("eventSenderEventsShowBCharData")) {
    ignoreEventTypes.delete(EVENT_TYPES.BCHAR_DATA);
  }

  addEventToEventSenderEvents(event, {
    ignoreEventTypes: Array.from(ignoreEventTypes),
  });

  switch (event.type) {
    case EVENT_TYPES.GUEST_JOINED: {
      // console.log("INCOMING GUEST_JOINED:");
      // console.log(event);
      setGuestTokenData({
        userId: event.data.userId,
        password: event.data.password,
      });

      const worldNumber = event.data.worldNumber;

      if (Number.isInteger(worldNumber)) {
        setState(["worldNumber"], worldNumber);
      }

      // emitEvent({
      //   data: {
      //     direction: Math.PI / 2, // in radians
      //     distance: 0,
      //     auth: {
      //       userId: event.data.userId,
      //       password: event.data.password,
      //     },
      //   },
      //   type: EVENT_TYPES.MOVE,
      // });

      // emitEvent({
      //   data: {
      //     auth: {
      //       userId: event.data.userId,
      //       password: event.data.password,
      //     },
      //   },
      //   type: EVENT_TYPES.GET_WORLD_DATA,
      // });

      const DATA_GET_TEMPORARILY_DISABLED = false;
      // const DATA_GET_TEMPORARILY_DISABLED = true;

      if (!DATA_GET_TEMPORARILY_DISABLED) {
        // emitEvent({
        //   data: {
        //     auth: {
        //       userId: event.data.userId,
        //       password: event.data.password,
        //     },
        //     xPartitionDecimalPercent: DEFAULT_START_X_DECIMAL_PERCENT,
        //     yPartitionDecimalPercent: DEFAULT_START_Y_DECIMAL_PERCENT,
        //   },
        //   type: EVENT_TYPES.GET_NWORLD_DATA_MULTIPLE_V2,
        // });
        // emitEvent({
        //   data: {
        //     auth: {
        //       userId: event.data.userId,
        //       password: event.data.password,
        //     },
        //     xPartitionDecimalPercent: DEFAULT_START_X_DECIMAL_PERCENT,
        //     yPartitionDecimalPercent: DEFAULT_START_Y_DECIMAL_PERCENT,
        //   },
        //   type: EVENT_TYPES.POWER_GET_NWORLD_DATA,
        // });

        cachePowerGetNWorldData({
          providedAuth: {
            userId: event.data.userId,
            password: event.data.password,
          },
          xPartitionDecimalPercent: DEFAULT_START_X_DECIMAL_PERCENT,
          yPartitionDecimalPercent: DEFAULT_START_Y_DECIMAL_PERCENT,
          skipCache: true,
        });
      }
      break;
    }
    case EVENT_TYPES.CHARACTER_DATA: {
      // console.log("CHARACTER_DATA");

      await characterDataDoPromiseInQueue({
        operation: async () => {
          const characterData = event.data;

          // remove duplicate based on userId

          // const characterDataNoDupes = [];

          // const userIds = {};

          // for (let i = 0; i < characterData.length; i++) {
          //   const characterDatum = characterData[i];

          //   if (!userIds[characterDatum.userId]) {
          //     characterDataNoDupes.push(characterDatum);
          //     userIds[characterDatum.userId] = true;
          //   }
          // }

          // use map instead
          const userIdToCharacterData = {};

          for (let i = 0; i < characterData.length; i++) {
            const characterDatum = characterData[i];

            userIdToCharacterData[characterDatum.userId] = characterDatum;
          }

          const characterDataNoDupes = Object.values(userIdToCharacterData);

          characterDataNoDupes.sort((a, b) => {
            return a.lastUpdated - b.lastUpdated;
          });

          setState(["characterData"], characterDataNoDupes);
        },
      });
      break;
    }
    case EVENT_TYPES.W_CHARACTER_DATA: {
      // alert("W_CHARACTER_DATA");

      const TEST_DISABLED = true;

      if (TEST_DISABLED) {
        return;
      }

      const wCharacterData = event.data;

      await wCharacterDataDoPromiseInQueue({
        operation: async () => {
          /*
  EXAMPLE {
    "wCharacterData": [
        {
            "userId": "y7RF0R5y8UyRQmg8AAAB",
            "x": "0%",
            "y": "0%",
            "metaStateA": false,
            "lastUpdated": 1708313436000,
            "fingerprint": 2760113618
        }
    ]
}
      */
          //   const characterDatum = characterData[i];

          //   if (!userIds[characterDatum.userId]) {
          //     characterDataNoDupes.push(characterDatum);
          //     userIds[characterDatum.userId] = true;
          //   }
          // }

          const newUserIdToWCharacterData = {};

          for (let i = 0; i < wCharacterData.length; i++) {
            const wCharacterDatum = wCharacterData[i];

            newUserIdToWCharacterData[wCharacterDatum.userId] = wCharacterDatum;
          }

          const wCharacterDataNoDupes = Object.values(
            newUserIdToWCharacterData
          );

          wCharacterDataNoDupes.sort((a, b) => {
            return a.lastUpdated - b.lastUpdated;
          });

          // console.log(`

          //     MEGA LOG: ${JSON.stringify(
          //       {
          //         wCharacterDataNoDupes,
          //       },
          //       null,
          //       4
          //     )}

          // `);

          setState(["wCharacterData"], wCharacterDataNoDupes);
        },
      });

      break;
    }

    case EVENT_TYPES.BCHAR_DATA: {
      handleBCharData(event.data);
      break;
    }
    case EVENT_TYPES.MINT_NFT_RESPONSE: {
      if (event.data.success) {
        // alert("NFT mint request successful");

        setState(["browserAlertData"], {
          message:
            "NFT mint request successful - " +
            "If your NFT contains any human figures, " +
            "they will be under review for approval. ", // +
          // "No depictions of violence, hate speech, or real-world people are allowed.",
        });
        return;
      }

      break;
    }
    case EVENT_TYPES.BROWSER_ALERT: {
      const MAX_ALERT_MESSAGE_LENGTH = 1000;

      let message = event.data.message;

      if (typeof message === "string" && message.length > 0) {
        if (message.length > MAX_ALERT_MESSAGE_LENGTH) {
          message = message.substring(0, MAX_ALERT_MESSAGE_LENGTH) + "...";
        }

        // alert(message);
        // const TEST_PATH_A_ONLY = true;
        const TEST_PATH_A_ONLY = false;

        if (!TEST_PATH_A_ONLY && event.data.type === BROWSER_ALERT_TYPES.B) {
          setState(["browserAlertDataB"], {
            message,
            time: Date.now(),
          });

          return;
        }

        setState(["browserAlertData"], {
          message,
        });
      }

      break;
    }
    case EVENT_TYPES.POWER_NWORLD_DATA: {
      await handlePowerNWorldData(event);

      // await nWorldDataDoPromiseInQueue2({
      //   operation: async () => {
      //     const partitionId = event.data.partitionId;
      //     const nWorldData = event.data.nWorldData;

      //     if (DEBUG_LOG_ON && !PRODUCTION_MODE) {
      //       console.log(`

      //       POWER_NWORLD_DATA LOG ${JSON.stringify(
      //         {
      //           partitionId,
      //           nWorldData: nWorldData.length,
      //         },
      //         null,
      //         4
      //       )}
      //       )

      //     `);
      //     }

      //     // partitionIdToNWorldData[partitionId] = nWorldData;

      //     const newPartitionIdToNWorldData = {
      //       ...partitionIdToNWorldData,
      //       [partitionId]: nWorldData,
      //     };

      //   },
      // });
      break;
    }
    case EVENT_TYPES.POWER_TRIGGER_DELETE_NWORLD_DATA: {
      // console.log(`

      //   POWER TRIGGER LOG1${JSON.stringify(
      //     {
      //       partitionId: event.data.partitionId,
      //     },

      //     null,
      //     4
      //   )}
      //   )

      // `);

      await nWorldDataDoPromiseInQueue2({
        operation: async () => {
          //     console.log(`

          //   POWER TRIGGER LOG ${JSON.stringify(
          //     {
          //       partitionId: event.data.partitionId,
          //     },

          //     null,
          //     4
          //   )}
          //   )

          // `);

          const partitionIdToNWorldData = getState("partitionIdToNWorldData");

          const newPartitionIdToNWorldData = {
            ...partitionIdToNWorldData,
          };

          delete newPartitionIdToNWorldData[event.data.partitionId];

          setState(["partitionIdToNWorldData"], newPartitionIdToNWorldData);

          // emitEvent({
          //   type: EVENT_TYPES.POWER_GET_NWORLD_DATA,
          //   data: {
          //     auth,

          //     xPartitionDecimalPercent,
          //     yPartitionDecimalPercent,
          //   },
          // });
        },
      });

      break;
    }
    case EVENT_TYPES.POWER_NWORLD_DATA_ATOMIC: {
      await nWorldDataDoPromiseInQueue2Atomic({
        operation: async () => {
          const add = event.data.add;
          const remove = event.data.remove;

          const nWorldData = getState("nWorldData");

          for (const datum of add) {
            // check by key if it already exists, if not, add it, if so, update it

            const existingDatum = nWorldData.find(
              (nWorldDatum) => nWorldDatum.key === datum.key
            );

            if (existingDatum) {
              // update it
              existingDatum.xDecimalPercent = datum.xDecimalPercent;
              existingDatum.yDecimalPercent = datum.yDecimalPercent;
              existingDatum.itemType = datum.itemType;
              existingDatum.backgroundColor = datum.backgroundColor;
            } else {
              // add it
              nWorldData.push(datum);
            }
          }

          // sample put datum
          /*
            {
              key: "worldElement0x7",
              xDecimalPercent: 0.5,
              yDecimalPercent: 0,
              itemType: "ITEM_TYPE_0",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }
          */

          for (const datum of remove) {
            const existingDatumIndex = nWorldData.findIndex((nWorldDatum) => {
              const keysMatch = nWorldDatum.key === datum.key;

              if (keysMatch) {
                return true;
              }

              // xDecimalPercent;
              // yDecimalPercent;
              // xDecimalPercentQuantized;
              // yDecimalPercentQuantized;

              // check both types of dps

              const nonQuantizedMatch =
                nWorldDatum.xDecimalPercent === datum.xDecimalPercent &&
                nWorldDatum.yDecimalPercent === datum.yDecimalPercent;

              if (nonQuantizedMatch) {
                return true;
              }

              const quantizedMatch =
                nWorldDatum.xDecimalPercentQuantized ===
                  datum.xDecimalPercent &&
                nWorldDatum.yDecimalPercentQuantized === datum.yDecimalPercent;

              if (quantizedMatch) {
                return true;
              }

              return false;
            });

            if (existingDatumIndex !== -1) {
              nWorldData.splice(existingDatumIndex, 1);
            }
          }
          // sample remove datum
          /*
            {
              key: "worldElement0x7",
            }
          */

          setState(["nWorldData"], nWorldData);

          // const allAtomicPuts = [];

          // for (const datum of add) {
          //   allAtomicPuts.push(datum);
          // }

          // const allAtomicRemoves = [];

          // for (const datum of remove) {
          //   allAtomicRemoves.push(datum);
          // }

          // combine (upgrade)

          const allAtomicOperations = [];

          for (const datum of add) {
            allAtomicOperations.push({
              type: "add",
              datum,
            });
          }

          for (const datum of remove) {
            allAtomicOperations.push({
              type: "remove",
              datum,
            });
          }

          // sample data (add and remove)
          /*
            [
              {
                type: "add",
                datum: {
                  key: "worldElement0x7",
                  xDecimalPercent: 0.5,
                  yDecimalPercent: 0,
                  itemType: "ITEM_TYPE_0",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              {
                type: "remove",
                datum: {
                  key: "worldElement0x7",
                },
              },
            ]
          */

          // const newLocalUpdateQueuePutsBrowser = [
          //   ...localUpdateQueuePutsBrowser,
          //   ...allAtomicPuts,
          // ];

          // const newLocalUpdateQueueRemovesBrowser = [
          //   ...localUpdateQueueRemovesBrowser,
          //   ...allAtomicRemoves,
          // ];

          /*

          
            MEGA LOG {
    "LAST LOCAL UPDATE QUEUE COMBINED BROWSER ENTRY": {
        "type": "remove",
        "datum": {
            "key": "object0x0x0D5000000199x0D000000000175",
            "xDecimalPercent": 0.5000000199,
            "yDecimalPercent": 1.75e-10,
            "xDecimalPercentQuantized": 0.5000000199,
            "yDecimalPercentQuantized": 1.7500000000000002e-10
        }
    }
}
          
          */

          const localUpdateQueueCombinedBrowser = getState(
            "localUpdateQueueCombinedBrowser"
          );

          // const newLocalUpdateQueueCombinedBrowser = [
          //   ...localUpdateQueueCombinedBrowser,
          //   ...allAtomicOperations,
          // ];
          // instead of a pure combine want it such that
          // if there is an existing key from the new in the old
          // remove the old and add the new

          const newLocalUpdateQueueCombinedBrowser = [];

          for (let i = 0; i < localUpdateQueueCombinedBrowser.length; i++) {
            const existingDatum = localUpdateQueueCombinedBrowser[i];

            const existingKey = existingDatum.datum.key;

            let found = false;

            for (let j = 0; j < allAtomicOperations.length; j++) {
              const newDatum = allAtomicOperations[j].datum;

              const newKey = newDatum.key;

              if (existingKey === newKey) {
                found = true;

                break;
              }
            }

            if (!found) {
              newLocalUpdateQueueCombinedBrowser.push(existingDatum);
            }
          }

          for (let i = 0; i < allAtomicOperations.length; i++) {
            const newDatum = allAtomicOperations[i].datum;

            newLocalUpdateQueueCombinedBrowser.push({
              type: allAtomicOperations[i].type,
              datum: newDatum,
            });
          }

          // console.log(`

          //   MEGA LOG ${JSON.stringify(
          //     {
          //       "LAST LOCAL UPDATE QUEUE COMBINED BROWSER ENTRY":
          //         newLocalUpdateQueueCombinedBrowser[
          //           newLocalUpdateQueueCombinedBrowser.length - 1
          //         ],
          //     },
          //     null,
          //     4
          //   )}
          //   )

          // `);

          setState(
            ["localUpdateQueueCombinedBrowser"],
            newLocalUpdateQueueCombinedBrowser
          );

          const combinedBrowserActionLength =
            newLocalUpdateQueueCombinedBrowser.length;

          // const MAX_BROWSER_ACTIONS = 25;
          const MAX_BROWSER_ACTIONS = 10000;

          if (combinedBrowserActionLength > MAX_BROWSER_ACTIONS) {
            console.log(`
            
            
              MEGA LOG ${JSON.stringify(
                {
                  "AUTO TRIGGER TOTAL MAP REFRESH": true,
                },
                null,
                4
              )}
              )
            
            
            `);

            const $ = window.$;

            $(window).trigger("brovent", {
              broventType: BROVENT_TYPES.TOTAL_MAP_REFRESH,
            });

            setTimeout(() => {
              // get xDecimalPercent and yDecimalPercent
              // from first add or remove

              const bCharDatum = getState("bCharDatum");

              // getXPixelsFromXPercentString
              // getXDecimalPercentFromXPixel

              let x = "50%";
              let y = "0%";

              if (bCharDatum) {
                x = bCharDatum.x;
                y = bCharDatum.y;
              }

              const xPixels = getXPixelsFromXPercentString(x);
              const xDecimalPercent = getXDecimalPercentFromXPixel(xPixels);

              const yPixels = getYPixelsFromYPercentString(y);
              const yDecimalPercent = getYDecimalPercentFromYPixel(yPixels);

              const worldNumber = 0;
              const levelNumber = 0;

              const { xPartitionDecimalPercent, yPartitionDecimalPercent } =
                getNWorldPartitionDataFromXandYDecimalPercent(
                  worldNumber,
                  levelNumber,
                  xDecimalPercent,
                  yDecimalPercent
                );

              cachePowerGetNWorldData({
                xPartitionDecimalPercent,
                yPartitionDecimalPercent,
                skipCache: true,
              });
            }, 1250);
          }
        },
      });
      break;
    }
    case EVENT_TYPES.BOT_DATA: {
      await botDataDoPromiseInQueue({
        operation: async () => {
          const botData = event.data.botData;

          // remove duplicate based on botId
          const botIdToBotData = {};

          for (let i = 0; i < botData.length; i++) {
            const botDatum = botData[i];

            botIdToBotData[botDatum.botId] = botDatum;
          }

          const botDataNoDupes = Object.values(botIdToBotData);

          botDataNoDupes.sort((a, b) => {
            return a.lastUpdated - b.lastUpdated;
          });

          setState(["botData"], botDataNoDupes);
        },
      });

      break;
    }
    case EVENT_TYPES.BOT2_DATA: {
      const eventData = event.data;

      handleBot2Data(eventData);

      break;
    }
    case EVENT_TYPES.BOT6_DATA: {
      const eventData = event.data;

      handleBot6Data(eventData);

      break;
    }
    case EVENT_TYPES.BOT6_WORLD_DATA: {
      const eventData = event.data;

      handleBot6WorldData(eventData);

      break;
    }
    case EVENT_TYPES.BOT2_INTERACTIONS: {
      const eventData = event.data;

      handleBot2Interactions({
        bot2InteractionsResult: eventData,
      });

      break;
    }
    case EVENT_TYPES.BOT6_INTERACTIONS: {
      const eventData = event.data;

      handleBot6Interactions({
        bot6InteractionsResult: eventData,
      });

      break;
    }

    case EVENT_TYPES.BOT6_INTERACTION_PROCESSING_RECEIPTS: {
      const data = event.data;
      handleBot6InteractionProcessingReceipts({
        data,
      });

      break;
    }

    case EVENT_TYPES.REQUEST_ID_EVENT_COMPLETED: {
      const data = event.data;
      handleRequestIdEventCompleted({ data });
      break;
    }

    // WORLD === MAP
    case EVENT_TYPES.WORLD_DATA: {
      // console.log("CHARACTER_DATA");

      await worldDataDoPromiseInQueue({
        operation: async () => {
          /*
{
    "data": {
      

      remove: [
        {
          key: "worldElement0x7",
        },
    },
    "datacontenttype": "application/json; charset=utf-8",
    "id": "event-1707229088027",
    "type": "WORLD_DATA",
    "time": 1707229088027
  }
          */

          const existingMapData = getState("mapData");

          const keyToMapData = {};

          for (let i = 0; i < existingMapData.length; i++) {
            const mapDatum = existingMapData[i];

            keyToMapData[mapDatum.key] = mapDatum;
          }

          const newMapData = event.data.add;

          for (let i = 0; i < newMapData.length; i++) {
            const mapDatum = newMapData[i];

            keyToMapData[mapDatum.key] = mapDatum;
          }

          for (let i = 0; i < event.data.remove.length; i++) {
            const key = event.data.remove[i].key;

            delete keyToMapData[key];
          }

          const updatedMapData = Object.values(keyToMapData);

          setState(["mapData"], updatedMapData);
        },
      });
      break;
    }
    case EVENT_TYPES.LAND_MAPPINGS: {
      const landMappings = event.data.landMappings;

      setState(["landMappings"], landMappings);

      break;
    }
    case EVENT_TYPES.UPDATE_WORLD_NUMBER_CLIENT: {
      // console.log("UPDATE_WORLD_NUMBER_CLIENT");

      const worldNumber = event.data.worldNumber;

      // setState(["mapData"], []);

      setState(["worldNumber"], worldNumber);

      // emitEvent({
      //   type: EVENT_TYPES.GET_WORLD_DATA,
      // });

      break;
    }
    case EVENT_TYPES.NFT_LISTINGS: {
      const nftListings = event.data.nftListings;

      // sample

      /*
        "nftListings": [
          {
              "metadata": {
                  "name": "Basic Item 222",
                  "description": "Basic item",
                  "image": "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/sword_1.png",
                  "external_url": "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/sword_1.png",
                  "attributes": [
                      {
                          "value": "Basic Item",
                          "trait_type": "Type"
                      }
                  ]
              },
              "tokenId": "222",
              "lastUpdated": 1717547437018,
              "smartContractAddress": "0xc3844c3aea8458e21bdeee7ba6ed328637fef8cb",
              "symbol": "NFIUF",
              "secondarySortKey": 1717547437018,
              "blockNumber": "57776414",
              "url": "https://mathbitcoin.com/new-world/flamin/222.json",
              "name": "NFT Item UltraFlamin",
              "partitionKey": "marketplaceNftV3XXXXXX0xc3844c3aea8458e21bdeee7ba6ed328637fef8cbXXXXXX222",
              "expirationTime": 1720139437018,
              "possibleSpam": false,
              "secondaryPartitionKey": "marketplaceNftV3XXXXXX0xfcf42185fb42cfbad2fc3c1ac0d87ecef37f4185",
              "price": 0.05,
              "type": "MARKETPLACE_NFT_V3",
              "ownerAddress": "0xfcf42185fb42cfbad2fc3c1ac0d87ecef37f4185"
          }
      ]
    */
      console.log(`received ${nftListings.length} NFT listing(s)`);

      setState(["marketplaceNFTListings"], nftListings);
      break;
    }
    case EVENT_TYPES.NFT_ACTIONABLES: {
      const nftActionables = event.data.nftActionables;

      console.log(`received ${nftActionables.length} NFT actionable(s)`);

      // console.log(`

      //   MEGA LOG ${JSON.stringify(
      //     {
      //       nftActionables,
      //     },
      //     null,
      //     4
      //   )}
      //   )

      // `);

      setState(["marketplaceNFTActionables"], nftActionables);
      break;
    }
    case EVENT_TYPES.NFT_SALES: {
      const nftSales = event.data.nftSales;

      console.log(`received ${nftSales.length} NFT sale(s)`);

      setState(["marketplaceNFTSales"], nftSales);
      break;
    }
    case EVENT_TYPES.MESSAGES: {
      const messages = event.data;

      // console.log("INCOMING MESSAGES:");
      // console.log(messages);

      const existingMessages = getState("messages");

      setState(["messages"], messages);

      const numberOfNewMessages = messages.length - existingMessages.length;

      // set unreadMessages if not in messageMode

      const unreadMessagesNotFirstShot = getState("unreadMessagesNotFirstShot");
      const messageMode = getState("messageMode");

      if (
        !messageMode &&
        unreadMessagesNotFirstShot &&
        numberOfNewMessages > 0
      ) {
        const unreadMessages = getState("unreadMessages");

        setState(["unreadMessages"], unreadMessages + numberOfNewMessages);
      } else {
        setState(["unreadMessages"], 0);
        setState(["unreadMessagesNotFirstShot"], true);
      }

      messagesScrollDown();
      break;
    }
    case EVENT_TYPES.CREATE_IMAGE_RESPONSE: {
      await handleCreateImageResponse(event);

      break;
    }
    case EVENT_TYPES.API_RESPONSE: {
      // console.log("API_RESPONSE");

      const apiResponse = event.data;

      await handleApiResponse(
        apiResponse.resource,
        apiResponse.error,
        apiResponse.data
      );

      break;
    }
    case EVENT_TYPES.RELOAD: {
      console.log("⚠️Reload requested⚠️");

      window.location.reload();

      break;
    }
    case EVENT_TYPES.SERVER_TRIGGERED_USER_ACTION: {
      handleServerTriggeredUserAction(event.data);
      break;
    }
    case EVENT_TYPES.TELEPORT: {
      console.log(`teleporting: ${JSON.stringify(event.data)}`);
      await handleTeleport(event);
      break;
    }
    case EVENT_TYPES.FORCE_SET_RESPAWN: {
      // console.error("FORCE_SET_RESPAWN - not implemented yet");
      console.log("force set respawn event data:");
      console.log(event.data);

      await setRespawn({
        x: event?.data?.x,
        y: event?.data?.y,
      });

      break;
    }
    case EVENT_TYPES.DO_RESPAWN: {
      // console.error("DO_RESPAWN - not implemented yet");
      console.log("do respawn event data:");
      console.log(JSON.stringify(event.data, null, 4));

      await handleDoRespawn();

      break;
    }
    case EVENT_TYPES.SUGGEST_UPDATE_USER_DATA: {
      const mTokenData = getMTokenData();

      if (!mTokenData) {
        console.log("SUGGEST_UPDATE_USER_DATA: no mTokenData");
        return;
      }

      await getUserData({
        addToState: true,
      });

      await delay(2000);

      await getUserData({
        addToState: true,
      });

      await delay(5000);

      await getUserData({
        addToState: true,
      });

      break;
    }
    case EVENT_TYPES.FORCE_CUDDLE: {
      await handleForceCuddle(event);

      break;
    }
    case EVENT_TYPES.UPDATE_USER_WITH_SYSTEM_TIME: {
      const systemTime = event.data.systemTime;

      if (!systemTime) {
        console.log("UPDATE_USER_WITH_SYSTEM_TIME: no systemTime");
        return;
      }

      const now = Date.now();

      const systemTimeOffset = now - systemTime;

      // console.log(
      //   "temporary report2: " +
      //     JSON.stringify(
      //       {
      //         now,
      //         systemTime,
      //         systemTimeOffset,
      //       },
      //       null
      //     )
      // );

      // i.e. client time = system time + systemTimeOffset

      setState(["systemTimeOffset"], systemTimeOffset);
      break;
    }
    case EVENT_TYPES.TOTAL_FORM_SCREEN_OUTPUT: {
      await handleTotalFormScreenOutput(event);

      break;
    }
    default: {
      console.log("handleEventCore: unknown event type:");
      console.log(event);
      break;
    }
  }
};
