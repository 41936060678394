import { useEffect } from "react";
import { getState, setState } from "../../reactStateManagement";
import {
  characterToData,
  NONE,
  // PLAYABLE_CHARACTERS_ARRAY,
  CHARACTERS,
  // RIGHT,
  SPECIAL_SELF_USER_ID,
} from "../../constants";
import getFashionImage from "../character/getFashionImage";

const FASHION_CHARACTERS_ARRAY = [
  CHARACTERS.DOGE,
  CHARACTERS.DOGE_FASHION,
  CHARACTERS.AQUA,
  CHARACTERS.PEPE,
  CHARACTERS.DOGE2,
  CHARACTERS.BEARA,
];

const useCharactersImages = () => {
  const wCharacterData = getState("wCharacterData");
  const bCharDatum = getState("bCharDatum");
  const eighteenPlusMode = getState("eighteenPlusMode");
  // const buttonIsPressedRight = getState("buttonIsPressedRight");
  // const buttonIsPressedLeft = getState("buttonIsPressedLeft");

  const allCharData = [
    ...wCharacterData,
    {
      ...bCharDatum,
      userId: SPECIAL_SELF_USER_ID,
    },
  ];

  // PLAYABLE_CHARACTERS_ARRAY

  const playableCustomFashionCharData = allCharData
    .filter((characterDatum) => {
      if (!characterDatum.character) {
        return true;
      }

      return FASHION_CHARACTERS_ARRAY.includes(characterDatum.character);
    })
    .filter((characterDatum) => {
      if (
        characterDatum.fashionTop ||
        characterDatum.fashionBottom ||
        characterDatum.fashionHat
      ) {
        return true;
      }

      return false;
    });

  // const combinedUserIds = playableCustomFashionCharData
  //   .map((characterDatum) => characterDatum.userId)
  //   .join("_");

  const combinedUserDataToCompare =
    playableCustomFashionCharData
      .map((characterDatum) => {
        const userId = characterDatum.userId;
        const fashionHatString = characterDatum.fashionHat
          ? JSON.stringify(characterDatum.fashionHat)
          : "NONE";

        const fashionTopString = characterDatum.fashionTop
          ? JSON.stringify(characterDatum.fashionTop)
          : "NONE";
        const fashionBottomString = characterDatum.fashionBottom
          ? JSON.stringify(characterDatum.fashionBottom)
          : "NONE";

        return `${userId}_${fashionHatString}_${fashionTopString}_${fashionBottomString}`;

        // const lastDirection = characterDatum.lastDirection;
        // const rightDown = characterDatum.rightDown;
        // const leftDown = characterDatum.leftDown;
        // const xMomentum = characterDatum.xMomentum;

        // return `${userId}_${fashionTopString}_${fashionBottomString}_${lastDirection}_${rightDown}_${leftDown}`;
        // return `${userId}_${fashionTopString}_${fashionBottomString}_${xMomentum}`;
      })
      .join("_") || "";

  useEffect(() => {
    // console.log("Triggered useCharacterImages: " + combinedUserDataToCompare);
    /*
    Sample Data Format - assume all image urls are valid

  [CHARACTERS.DOGE_FASHION]: {
    imageUrlA: "imageA",
    imageUrlMove: "imageMove",
    imageUrlARight: "imageARight",
    imageUrlMoveRight: "imageMoveRight",
    wardrobe: {
      base: "base",
      baseMove: "baseMove",
      tops: [
        {
          imageUrl: "top_0",
          imageUrlMove: "top_1",
        },
      ],
      bottoms: [
        {
          imageUrl: 'bottoms_a',
          imageUrlMove: 'bottoms_b'
        },
      ],
    },
*/

    /*
{
    "x": "49.99999999313752%",
    "y": "0%",
    "lastUpdated": 1727355710738,
    "metaStateA": false,
    "xMomentum": 0,
    "lastX": "49.99999999313752%",
    "lastXMomentum": 0,
    "lastYMomentum": 0,
    "character": "DOGE_FASHION",
    "address": "0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
    "jumpDown": false,
    "leftDown": false,
    "rightDown": false,
    "lastDirection": "left",
    "yMomentum": 0,
    "jumpedLastIteration": 0,
    "lastY": "0%",
    "fashionBottom": {
        "imageUrl": "/images/dogexfashion_0_bottom_0.png",
        "imageUrlMove": "/images/dogexfashion_1_bottom_0.png"
    },
    "userId": "SELF-USER-ID"
}
    */

    // getFashionImage

    typeof getFashionImage;
    typeof setState;

    let fashionPromises = [];

    for (const cd of playableCustomFashionCharData) {
      // console.log("CD:", cd);

      // const wasRightLastDirection = cd.lastDirection === RIGHT;

      //   useraIdToFashionData[characterDatum.userId] = {};

      const characterDataConstants = characterToData[cd.character];

      if (characterDataConstants) {
        const baseImageUrl = characterDataConstants.wardrobe.base;
        const baseImageUrlMove = characterDataConstants.wardrobe.baseMove;

        if (baseImageUrl && baseImageUrlMove) {
          const preBaseImageUrls = [];
          const preBaseImageUrlsMove = [];
          const overlayImageUrls = [];
          const overlayImageUrlsMove = [];

          if (cd.fashionTop?.imageUrlBack) {
            preBaseImageUrls.push(cd.fashionTop.imageUrlBack);
          }

          if (cd.fashionTop?.imageUrlMoveBack) {
            preBaseImageUrlsMove.push(cd.fashionTop.imageUrlMoveBack);
          }

          if (
            cd.fashionBottom &&
            cd.fashionBottom.imageUrl &&
            cd.fashionBottom.imageUrl !== NONE
          ) {
            if (cd.fashionBottom.xOffset) {
              overlayImageUrls.push({
                url: cd.fashionBottom.imageUrl,
                xOffset: cd.fashionBottom.xOffset || 0,
              });
            } else {
              overlayImageUrls.push(cd.fashionBottom.imageUrl);
            }
          } else if (
            (!cd.fashionBottom || cd.fashionBottom.imageUrl === NONE) &&
            !eighteenPlusMode
          ) {
            overlayImageUrls.push(
              characterDataConstants.wardrobe.bottomCensors[0].imageUrl
            );
          }

          if (
            cd.fashionBottom &&
            cd.fashionBottom.imageUrlMove &&
            cd.fashionBottom.imageUrlMove !== NONE
          ) {
            if (cd.fashionBottom.xOffsetMove) {
              overlayImageUrlsMove.push({
                url: cd.fashionBottom.imageUrlMove,
                xOffset: cd.fashionBottom.xOffsetMove || 0,
              });
            } else {
              overlayImageUrlsMove.push(cd.fashionBottom.imageUrlMove);
            }

            overlayImageUrlsMove.push(cd.fashionBottom.imageUrlMove);
          } else if (
            (!cd.fashionBottom || cd.fashionBottom.imageUrlMove === NONE) &&
            !eighteenPlusMode
          ) {
            overlayImageUrlsMove.push(
              characterDataConstants.wardrobe.bottomCensors[0].imageUrlMove
            );
          }

          if (
            cd.fashionTop &&
            cd.fashionTop.imageUrl &&
            cd.fashionTop.imageUrl !== NONE
          ) {
            overlayImageUrls.push(cd.fashionTop.imageUrl);
          } else if (
            (!cd.fashionTop || cd.fashionTop.imageUrl === NONE) &&
            !eighteenPlusMode
          ) {
            if (characterDataConstants?.wardrobe?.topCensors?.[0]) {
              overlayImageUrls.push(
                characterDataConstants.wardrobe.topCensors[0].imageUrl
              );
            }
          }

          if (
            cd.fashionTop &&
            cd.fashionTop.imageUrlMove &&
            cd.fashionTop.imageUrlMove !== NONE
          ) {
            overlayImageUrlsMove.push(cd.fashionTop.imageUrlMove);
          } else if (
            (!cd.fashionTop || cd.fashionTop.imageUrlMove === NONE) &&
            !eighteenPlusMode
          ) {
            if (characterDataConstants?.wardrobe?.topCensors?.[0]) {
              overlayImageUrlsMove.push(
                characterDataConstants.wardrobe.topCensors[0].imageUrlMove
              );
            }
          }

          if (
            cd.fashionHat &&
            cd.fashionHat.imageUrl &&
            cd.fashionHat.imageUrl !== NONE
          ) {
            overlayImageUrls.push(cd.fashionHat.imageUrl);
          }

          if (
            cd.fashionHat &&
            cd.fashionHat.imageUrlMove &&
            cd.fashionHat.imageUrlMove !== NONE
          ) {
            overlayImageUrlsMove.push(cd.fashionHat.imageUrlMove);
          }

          const fashionPromise = Promise.all([
            Promise.resolve(cd.userId),
            getFashionImage({
              preBaseImageUrls,
              baseImageUrl,
              overlayImageUrls,
            }).catch((e) => {
              console.error("Error in getFashionImage - stationary:");
              console.error(e);

              throw e;
            }),
            getFashionImage({
              preBaseImageUrls: preBaseImageUrlsMove,
              baseImageUrl: baseImageUrlMove,
              overlayImageUrls: overlayImageUrlsMove,
            }).catch((e) => {
              console.error("Error in getFashionImage - move:");
              console.error(e);
              console.error({
                baseImageUrlMove,
                overlayImageUrlsMove,
              });
            }),
          ]);

          fashionPromises.push(fashionPromise);
        }
      }
    }

    Promise.all(fashionPromises)
      .then((fashionData) => {
        // typeof fashionData;
        // console.log("Fashion Data:");
        // console.log(fashionData);

        // const userIdToFashionData = getState("userIdToFashionData") || {};
        const userIdToFashionData = {};

        const newUserIdToFashionData = {
          ...userIdToFashionData,
        };

        for (const fd of fashionData) {
          const userId = fd[0];
          const imageUrl = fd[1];
          const imageUrlMove = fd[2];

          newUserIdToFashionData[userId] = {
            imageUrl,
            imageUrlMove,
          };
        }

        setState(["userIdToFashionData"], newUserIdToFashionData);
      })
      .catch((e) => {
        console.error("Fashion Images Error:");
        console.error(e);
      });

    // setState(["userIdToFashionData"], userIdToFashionData);
  }, [combinedUserDataToCompare, eighteenPlusMode]);
};

export default useCharactersImages;
