import React from "react";
import {
  CHARACTERS,
  ITEMS,
  // CHARACTERS,
  Z_INDICES,
} from "../../constants";
import { getState } from "../../reactStateManagement";
import GameComponentButton from "../../commonComponents/GameComponentButton";
import doEquipItem from "../../utils/character/doEquipItem";
// import updateBChar from "../../utils/bChar/updateBChar";
// import { getState } from "../../reactStateManagement";
// import { getMTokenData } from "../../../utils/mToken";

const SIZE_UNIT = 40;

const INVENTORY_BAR_HEIGHT_PERCENT_DECIMAL = 0.8;

const DEFAULT_ITEMS = [
  //ITEMS.PICKAXE,
  ITEMS.BRICK,
];

const InventorySlot = ({ item, bCharDatum, index }) => {
  const itemIsEquipped = item && bCharDatum?.equippedItem?.name === item.name;

  return (
    <GameComponentButton
      id={`inventorySlot-${item?.name || "empty"}-${index}`}
      style={{
        position: "relative",

        width: SIZE_UNIT,
        height: SIZE_UNIT,

        // backgroundColor: "transparent",
        backgroundColor: itemIsEquipped ? "darkblue" : "transparent",

        color: "gold",
        border: `1px solid gold`,
        boxSizing: "border-box",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        if (itemIsEquipped) {
          doEquipItem({ item: null });
          return;
        }

        doEquipItem({ item });
      }}
    >
      {/* {item.testValue} */}
      {item ? (
        <img
          src={item.image}
          alt={item.name}
          style={{
            maxWidth: SIZE_UNIT,
            maxHeight: SIZE_UNIT,
          }}
        />
      ) : null}
    </GameComponentButton>
  );
};

const Inventory = ({ bottom, windowHeight = 0, bCharDatum }) => {
  const inventoryMode = getState("inventoryMode");
  const isExploreMode = bCharDatum.character === CHARACTERS.EXPLORE;

  if (!inventoryMode || isExploreMode) {
    return null;
  }

  //{ bottom = 0 }
  // const menuOpen = getState("loginMode");
  // const bCharDatum = getState("bCharDatum");

  // const nzwHeight

  // const computedHeight = nzwH * INVENTORY_BAR_HEIGHT_PERCENT_DECIMAL;

  // // const computedBottom = bottom + SIZE_UNIT;
  // const topPure = (nzwH - computedHeight) / 2;

  // const top = topPure / scale;

  const slots = [];

  for (let i = 0; i < 6; i++) {
    const item = DEFAULT_ITEMS[i];

    slots.push(
      <InventorySlot key={i} item={item} bCharDatum={bCharDatum} index={i} />
    );
  }

  const computedMetaverseHeight = windowHeight - bottom;
  const computedHeight =
    computedMetaverseHeight * INVENTORY_BAR_HEIGHT_PERCENT_DECIMAL;

  const computedBottom = bottom + SIZE_UNIT;

  return (
    <div
      style={{
        position: "absolute",

        bottom: computedBottom,
        left: SIZE_UNIT,

        width: SIZE_UNIT * 1,
        height: computedHeight,

        backgroundColor: "transparent",
        // border: `1px solid green`,
        // boxSizing: "border-box",

        zIndex: Number.MAX_SAFE_INTEGER || Z_INDICES.NEW_ZOOM_WORLD_LAYER * 2,

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <div
        style={{
          position: "relative",

          // top,
          // left: SIZE_UNIT,

          width: "100%",
          height: SIZE_UNIT * 6,

          backgroundColor: "transparent",
          border: `1px solid gold`,

          boxSizing: "border-box",

          zIndex: Number.MAX_SAFE_INTEGER || Z_INDICES.NEW_ZOOM_WORLD_LAYER * 2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {slots}
      </div>
    </div>
  );
};

export default Inventory;
