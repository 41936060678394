import { textLengthToData } from "./textLengthToData";

const getModifiedDuration = ({ lastFreshUserMessageAudioBufferMetadata }) => {
  let modifiedDuration;

  if (lastFreshUserMessageAudioBufferMetadata) {
    // duration = 1000 * 60;

    /*

              {
            "time": 4001,
            "type": "sentence",
            "start": 61,
            "end": 73,
            "value": "He got toad!"
          },*.

    */

    // TODO: do not treat this as a special case
    if (
      lastFreshUserMessageAudioBufferMetadata.metadata.length === 1 &&
      lastFreshUserMessageAudioBufferMetadata.metadata[0].time === 0 &&
      lastFreshUserMessageAudioBufferMetadata.metadata[0].value.length > 0
    ) {
      modifiedDuration = textLengthToData(
        lastFreshUserMessageAudioBufferMetadata.metadata[0].value.length
      ).duration;
    } else {
      // TODO: modularize this as getDurationFromlastFreshUserMessageAudioBufferMetadata
      let lastSentenceObject = { time: 0, value: "" };

      for (
        let i = 0;
        i < lastFreshUserMessageAudioBufferMetadata.metadata.length;
        i++
      ) {
        const sentence = lastFreshUserMessageAudioBufferMetadata.metadata[i];
        if (sentence.time > lastSentenceObject.time) {
          lastSentenceObject = sentence;
        }
      }

      if (lastSentenceObject && lastSentenceObject.time) {
        const highestTime = lastSentenceObject.time;
        const lastSentence = lastSentenceObject.value;

        modifiedDuration =
          highestTime + textLengthToData(lastSentence.length).duration;
      }
    }
  }

  // const TEST_LONG_DURATION = true;
  const TEST_LONG_DURATION = false;

  if (TEST_LONG_DURATION) {
    modifiedDuration = 1000 * 69;
  }

  return modifiedDuration;
};

export { getModifiedDuration };
