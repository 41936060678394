import React, { useEffect } from "react";
import { CHARACTERS, Z_INDICES } from "../../constants";
import updateBChar from "../../utils/bChar/updateBChar";
import { getState } from "../../reactStateManagement";
// import { getMTokenData } from "../../../utils/mToken";

const SIZE_UNIT = 40;

const AttackButton = ({ bottom = 0 }) => {
  const menuOpen = getState("loginMode");
  const bCharDatum = getState("bCharDatum");

  useEffect(() => {
    // no drag
    const $ = window.$;

    const attackButton = $("#attack-button-image");

    const preventDrag = (e) => {
      e.preventDefault();
    };

    const preventMobileDrag = (e) => {
      e.preventDefault();
    };

    attackButton.on("dragstart", preventDrag);
    attackButton.on("touchstart", preventMobileDrag);

    return () => {
      attackButton.off("dragstart", preventDrag);
      attackButton.off("touchstart", preventMobileDrag);
    };
  }, []);

  const isExploreMode = bCharDatum.character === CHARACTERS.EXPLORE;

  if (isExploreMode) {
    return null;
  }

  if (menuOpen) {
    return null;
  }

  // const mTokenData = getMTokenData();

  // if (!mTokenData) {
  //   return null;
  // }

  const userData = getState("userData");

  if (!userData) {
    return null;
  }

  const mobile = getState("mobile");

  const right = mobile ? SIZE_UNIT / 4 : SIZE_UNIT / 2;

  const attack = () => {
    updateBChar({
      mutateFunction: (bCharDatum) => {
        // bCharDatum.attack = null; //temp
        // bCharDatum.attack = Date.now();

        const systemTimeOffset = getState("systemTimeOffset");

        // client time = system time + systemTimeOffset
        // system time = client time - systemTimeOffset

        const now = Date.now();

        const attack = now - systemTimeOffset;

        // console.log("Temp report:");
        // console.log(
        //   JSON.stringify(
        //     {
        //       now,
        //       systemTimeOffset,
        //       attack,
        //     },
        //     null,
        //     4
        //   )
        // );

        bCharDatum.attack = attack;

        return bCharDatum;
      },
      bBroadcast: true,
    });
  };

  let bottomVal = SIZE_UNIT / 4 + bottom * 1 + SIZE_UNIT / 2;

  if (!mobile) {
    bottomVal = bottomVal + SIZE_UNIT / 6;
  }

  return (
    <button
      style={{
        position: "absolute",

        bottom: bottomVal,
        right,

        width: SIZE_UNIT * 1,
        height: SIZE_UNIT,

        backgroundColor: "red",
        borderRadius: "50%",

        // maxHeight: 420,

        boxSizing: "border-box",

        zIndex: Number.MAX_SAFE_INTEGER || Z_INDICES.NEW_ZOOM_WORLD_LAYER * 2,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={attack}
      onTouchStart={attack}
    >
      {/* {"🗡️"} */}

      <img
        id={"attack-button-image"}
        src="https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/attack_button_0.png"
        alt="sword button"
        style={{
          width: "100%",
          height: "100%",

          userSelect: "none",
        }}
      />
    </button>
  );
};

export default AttackButton;
