import React from "react";
// import React, { useState } from "react";
import {
  COLOR_0,
  // COLOR_1,
  // COLOR_4,
  // COLOR_5,
  // STANDARD_BUTTON_HEIGHT,
  // EVENT_TYPES
  characterToData,
  // CHARACTERS,
  // CHARACTERS_ARRAY,
  // PLAYABLE_CHARACTERS as CHARACTERS,
  // PLAYABLE_CHARACTERS_ARRAY as CHARACTERS_ARRAY,
  // PRODUCTION_MODE,
  // DEFAULT_START_X_PERCENT_STRING,
  // DEFAULT_START_Y_PERCENT_DECIMAL,
} from "../../../../constants";
import doSelectCharacter from "../../../../utils/character/doSelectCharacter";
typeof COLOR_0;

const SetToFashionSelectModeButton = ({ character, setFashionSelectMode }) => {
  // if (PRODUCTION_MODE) {
  //   return null;
  // }

  const characterData = characterToData[character];

  if (!characterData?.wardrobe) {
    return null;
  }

  return (
    <button
      style={{
        width: "100%",
        height: "100%",

        backgroundColor: "green",
        color: "white",
      }}
      onClick={async () => {
        setFashionSelectMode(true);

        await doSelectCharacter({ character });
      }}
    >
      {"Select Fashion"}
    </button>
  );
};

export default SetToFashionSelectModeButton;
