import React from "react";
// import { DisplayText } from "./DisplayText";
import {
  //UNIT_WIDTH,
  Z_1,
} from "../nzwConstants";
import {
  NWORLD_STANDARD_USER_HEIGHT_PERCENT_DECIMAL,
  NWORLD_STANDARD_USER_WIDTH_PERCENT_DECIMAL,
  NWORLD_TOTAL_WIDTH,
  NWORLD_TOTAL_HEIGHT,
} from "../../../worldConstants";
import {
  CHARACTERS,
  characterToData,
  CSS_DEFAULT_ANIMATION_TRANSITION,
  FUN_CHARACTERS,
  RIGHT,
} from "../../../constants";
import BotScreenCaptureUser from "./BotScreenCaptureUser";
import FireContent from "./FireContent";
import { getState } from "../../../reactStateManagement";
import EquippedItemContent from "./EquippedItemContent";
import ChatBubble from "./ChatBubble/ChatBubble";

const mimiImageStationary = characterToData[CHARACTERS.DOGE].imageUrlA;
const mimiImageMove = characterToData[CHARACTERS.DOGE].imageUrlMove;

const playerNumberToImageData = {
  // 0: {
  //   stationary:
  //     "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/nftitem_doge_miniskirt_8_a.png",
  //   motion:
  //     "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/nftitem_doge_miniskirt_8a_a.png",
  // },
  // 1: {
  //   stationary:
  //     "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/nftitem_doge_miniskirt_8_b.png",
  //   motion:
  //     "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/nftitem_doge_miniskirt_8a_b.png",
  // },
  0: {
    stationary: mimiImageStationary,
    motion: mimiImageMove,
  },
  1: {
    stationary: mimiImageStationary,
    motion: mimiImageMove,
  },
};

const FUN_TIME = 1000 * 60 * 60;

const getCuddleImageUrl = ({ cuddleImageUrl, cuddleImageUrl2 }) => {
  const now = Date.now();

  // alternate between the two images every 1 second

  const isCuddleImageUrl = now % 2000 < 1000;

  if (isCuddleImageUrl) {
    return cuddleImageUrl;
  }

  return cuddleImageUrl2;
};

const getPlayerImageData = ({
  userId,
  index,
  character,
  fashion,
  fun,
  eighteenPlusMode,
  rightDown,
  wasRightLastDirection,
}) => {
  if (character) {
    const now = Date.now();
    if (FUN_CHARACTERS.includes(character) && fun && now - fun < FUN_TIME) {
      const rMode = rightDown || wasRightLastDirection;

      let flexActionTime = false;

      const customGetIfFun = characterToData[character].fun.getIfFun;

      if (customGetIfFun) {
        //
        flexActionTime = customGetIfFun({
          now,
        });
        // console.log("Custom get if fun:", flexActionTime);
      } else {
        const funDenominator =
          characterToData[character].fun.flexDenominator || 1000;
        const funMod = characterToData[character].fun.flexModulus || 2;

        // const flexActionTime = !(Math.floor(now / 3000) % 4 === 0);
        flexActionTime = !(Math.floor(now / funDenominator) % funMod === 0);
      }

      if (flexActionTime) {
        if (eighteenPlusMode) {
          return {
            stationary: characterToData[character].fun.imageUrlFun1,
            motion: characterToData[character].fun.imageUrlFun1,
          };
        }

        if (rMode) {
          return {
            stationary: characterToData[character].fun.imageUrlFunXCalculus1R,
            motion: characterToData[character].fun.imageUrlFunXCalculus1R,
          };
        }

        return {
          stationary: characterToData[character].fun.imageUrlFunXCalculus1,
          motion: characterToData[character].fun.imageUrlFunXCalculus1,
        };
      } else {
        // return eighteenPlusMode
        //   ? {
        //       stationary:
        //         characterToData[character].fun.imageUrlFun0,
        //       motion: characterToData[character].fun.imageUrlFun0,
        //     }
        //   : {
        //       stationary:
        //         characterToData[character].fun
        //           .imageUrlFunXCalculus0,
        //       motion:
        //         characterToData[character].fun
        //           .imageUrlFunXCalculus0,
        //     };

        if (eighteenPlusMode) {
          return {
            stationary: characterToData[character].fun.imageUrlFun0,
            motion: characterToData[character].fun.imageUrlFun0,
          };
        }

        if (rMode) {
          return {
            stationary: characterToData[character].fun.imageUrlFunXCalculus0R,
            motion: characterToData[character].fun.imageUrlFunXCalculus0R,
          };
        }

        return {
          stationary: characterToData[character].fun.imageUrlFunXCalculus0,
          motion: characterToData[character].fun.imageUrlFunXCalculus0,
        };
      }
    }

    const userIdToFashionData = getState("userIdToFashionData");
    if (
      [
        CHARACTERS.ELON,
        CHARACTERS.ZUCC,
        CHARACTERS.BEARA,
        CHARACTERS.AQUA,
        CHARACTERS.EXPLORE,
        CHARACTERS.PIGYAN,
        CHARACTERS.DOGE_FASHION,
        CHARACTERS.PEPE,
        CHARACTERS.KK,
        CHARACTERS.DOGE2,
      ].includes(character)
    ) {
      if (fashion) {
        const fashionData = userIdToFashionData[userId];

        if (fashionData && fashionData.imageUrl) {
          return {
            stationary: fashionData.imageUrl,
            motion: fashionData.imageUrlMove,
          };
        }

        // const PLACEHOLDER_IMAGE_URL =
        //   "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/attack_button_0.png";
        const PLACEHOLDER_IMAGE_URL = "/images/null.png";

        return {
          stationary: PLACEHOLDER_IMAGE_URL,
          motion: PLACEHOLDER_IMAGE_URL,
        };
      }

      return {
        // stationary: characterToData[CHARACTERS.ELON].imageUrlA,
        // motion: characterToData[CHARACTERS.ELON].imageUrlMove,
        stationary: characterToData[character].imageUrlA,
        motion: characterToData[character].imageUrlMove,
      };
    } else if (character === CHARACTERS.DOGE) {
      const fashionData = userIdToFashionData[userId];

      if (fashionData && fashionData.imageUrl) {
        return {
          stationary: fashionData.imageUrl,
          motion: fashionData.imageUrlMove,
        };
      }

      return {
        stationary: playerNumberToImageData[index].stationary,
        motion: playerNumberToImageData[index].motion,
      };
    }
  }

  const playerImageData = playerNumberToImageData[index];

  if (!playerImageData) {
    return playerNumberToImageData[1];
  }

  return playerImageData;
};

export const NCharBase = ({
  userId,
  address,
  frenXPixels,
  xMin,
  xPixels,
  yPixels,
  index = 0,
  lastMessage,
  lastMessageTime,
  rightDown,
  leftDown,
  lastDirection,
  minY,
  botoScreenCaptureMode,
  character,
  attack = null,
  fun = null,
  fashion,
  equippedItem,
  eighteenPlusMode = false,
  cuddleImageUrl,
  cuddleImageUrl2,
  messages,
  scale,
}) => {
  const localUserId = userId || "0";
  let w;
  let h;
  let left;
  let bottom;
  let newImageUrl;
  let transform;

  let userContent;
  let equippedItemContent;

  const wasRightLastDirection = lastDirection === RIGHT;
  w = NWORLD_STANDARD_USER_WIDTH_PERCENT_DECIMAL * NWORLD_TOTAL_WIDTH;
  h = NWORLD_STANDARD_USER_HEIGHT_PERCENT_DECIMAL * NWORLD_TOTAL_HEIGHT;
  bottom = yPixels - minY;
  if (typeof frenXPixels === "number" && typeof xMin === "number") {
    left = frenXPixels - xMin - w / 2;
  } else {
    left = `calc(50% - ${w / 2}px)`;
  }

  if (!cuddleImageUrl || !cuddleImageUrl2) {
    const playerImageData = getPlayerImageData({
      index,
      userId,
      character,
      fashion,
      fun,
      eighteenPlusMode,
      rightDown,
      wasRightLastDirection,
    });

    const leftOrRightDown = rightDown || leftDown;

    const imageUrl = leftOrRightDown
      ? playerImageData.motion
      : playerImageData.stationary;

    // const isCharSpecialCase = character === CHARACTERS.ELON;

    const isCharSpecialCase = [
      CHARACTERS.ELON,
      CHARACTERS.ZUCC,
      // CHARACTERS.BEARA,
      CHARACTERS.PIGYAN,
    ].includes(character);

    if (isCharSpecialCase) {
      // TODO:
      if (rightDown) {
        // newImageUrl = wasRightLastDirection
        //   ? characterToData[character].imageUrlMoveRight
        //   : characterToData[character].imageUrlMoveRight;

        newImageUrl = characterToData[character].imageUrlMoveRight;
      } else {
        // newImageUrl = wasRightLastDirection
        //   ? characterToData[character].imageUrlA
        //   : characterToData[character].imageUrlA;

        if (leftDown) {
          newImageUrl = characterToData[character].imageUrlMove;
        } else {
          newImageUrl = wasRightLastDirection
            ? characterToData[character].imageUrlARight
            : characterToData[character].imageUrlA;
        }
      }
    } else {
      newImageUrl = imageUrl;
    }

    // const doubleSize = character === CHARACTERS.ZUCC;
    const doubleSize = [
      CHARACTERS.ELON,
      CHARACTERS.ZUCC,
      // CHARACTERS.BEARA,
      CHARACTERS.PIGYAN,
    ].includes(character);

    if (isCharSpecialCase) {
      if (doubleSize) {
        // transform = "scale(2, 2)";
        // transform = "scale(1.5, 1.5) translateY(-17.5%)";
        // transform = "scale(1.75, 1.75) translateY(-21.25%)";

        let SCALAR; // = 1.85;
        let TRANSLATION;

        switch (character) {
          case CHARACTERS.ELON:
          case CHARACTERS.PIGYAN:
          case CHARACTERS.PEPE:
            SCALAR = 1.25;
            // TRANSLATION = (-SCALAR * 100) / 12.5;
            TRANSLATION = -SCALAR * 100 * 0.08;
            break;
          // case CHARACTERS.PIGYAN:
          //   SCALAR = 1.25;
          //   // TRANSLATION = (-SCALAR * 100) / 12.5;
          //   TRANSLATION = -SCALAR * 100 * 0.08;
          //   break;
          default:
            SCALAR = 1.8;
            TRANSLATION = (-SCALAR * 100) / 8;
            break;
        }
        // const TRANSLATION = (-SCALAR * 100) / 8;
        // transform = `scale(1.95, 1.95) translateY(${TRANSLATION}%)`;
        transform = `scale(${SCALAR}, ${SCALAR}) translateY(${TRANSLATION}%)`;
        // transform = "scale(2, 2) translateY(-25%)";
      } else {
        // transform = "scaleX(1)";
        transform = "scaleX(1)";
      }
    } else if (character === CHARACTERS.PEPE) {
      let SCALAR = 2.5;
      let TRANSLATION = -30;

      transform = `scale(${
        wasRightLastDirection ? -SCALAR : SCALAR
      }, ${SCALAR}) translateY(${TRANSLATION}%)`;
    } else if (character === CHARACTERS.DOGE2) {
      let SCALAR = 1.15 / 1.002407529;
      let TRANSLATION = -6.475;

      transform = `scale(${
        wasRightLastDirection ? -SCALAR : SCALAR
      }, ${SCALAR}) translateY(${TRANSLATION}%)`;
    } else if (character === CHARACTERS.BEARA) {
      // const SCALAR = 1.8;
      const SCALAR = 1.8;
      const TRANSLATION = (-SCALAR * 100) / 8;

      const xScalar = wasRightLastDirection ? SCALAR : -SCALAR;
      transform = `scale(${xScalar}, ${SCALAR}) translateY(${TRANSLATION}%)`;
    } else {
      // if (!character || character === CHARACTERS.DOGE) {
      //   // transform = wasRightLastDirection ? "scaleX(-1.4)" : "scaleX(1.4)";
      //   // const DOGE_SCALAR = 1.45;
      //   // const DOGE_TRANSLATION = -15.6;

      //   const DOGE_SCALAR = 1.2;
      //   const DOGE_TRANSLATION = -9;
      //   transform = wasRightLastDirection
      //     ? `scale(${-DOGE_SCALAR}, ${DOGE_SCALAR}) translateY(${DOGE_TRANSLATION}%)`
      //     : `scale(${DOGE_SCALAR}, ${DOGE_SCALAR}) translateY(${DOGE_TRANSLATION}%)`;
      // } else {
      transform = wasRightLastDirection ? "scaleX(-1)" : "scaleX(1)";
      // }
    }

    if (botoScreenCaptureMode) {
      userContent = <BotScreenCaptureUser />;
    } else {
      userContent = (
        <img
          src={newImageUrl}
          alt="nftitem_user"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            // apply double size if character is zucc

            transform,
          }}
        />
      );
    }

    // for testing assume has equipped item
    // const equippedItem = ITEMS.PICKAXE;

    if (equippedItem) {
      equippedItemContent = (
        <EquippedItemContent
          xPixels={xPixels}
          yPixels={yPixels}
          wasRightLastDirection={wasRightLastDirection}
          w={w}
          h={h}
          equippedItem={equippedItem}
        />
      );
    } else {
      equippedItemContent = null;
    }
  }

  return (
    <div
      id={`wchar-${localUserId}`}
      style={{
        position: "absolute",
        bottom,
        // left: "50%",
        opacity: character === CHARACTERS.EXPLORE ? 0 : 1,

        backgroundColor: botoScreenCaptureMode ? "green" : "transparent",

        border: botoScreenCaptureMode ? "5px solid red" : "none",

        boxSizing: "border-box",

        left,
        // left: `calc($5 - ${UNIT_WIDTH / 2}px)`,
        width: w,
        height: h,

        // backgroundColor: "green",
        transition: CSS_DEFAULT_ANIMATION_TRANSITION,
        zIndex: Z_1 + 1,
      }}
    >
      {cuddleImageUrl && cuddleImageUrl2 ? (
        <img
          // src={cuddleImageUrl}

          src={getCuddleImageUrl({
            cuddleImageUrl,
            cuddleImageUrl2,
          })}
          alt="nftitem_cuddle_user"
          style={{
            position: "absolute",

            top: 0,
            left: 0,

            // maxWidth: "100%",
            // maxHeight: "100%",
            transform: wasRightLastDirection ? "scaleX(-1)" : "scaleX(1)",

            height: "100%",
          }}
        />
      ) : null}
      {
        <ChatBubble
          lastMessage={lastMessage}
          lastMessageTime={lastMessageTime}
          wasRightLastDirection={wasRightLastDirection}
          w={w}
          h={h}
          character={character}
          messages={messages}
          userId={userId}
          address={address}
          scale={scale}
        />
      }
      {userContent}
      {equippedItemContent}
      <FireContent
        wasRightLastDirection={wasRightLastDirection}
        attack={attack}
        w={w}
        h={h}
      />
    </div>
  );
};
