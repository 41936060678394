import createExecutionQueue from "../../utils/createExecutionQueue";
import { getState, setState } from "../../reactStateManagement";
import updateAudioGhostQueue from "../../utils/world/updateAudioGhostQueue";
import { emitEvent } from "../../utils/emitEvent";
import { EVENT_TYPES } from "../../constants";
import delay from "../../utils/delay";
import compareAudioGhostQueueElements from "../../utils/browserRealtime/compareAudioGhostQueueElements";
import updateMessageIdToAudioPlayTime from "../../utils/browserRealtime/updateMessageIdToAudioPlayTime";

const executionQueue = createExecutionQueue(100000);
// safeguard against infinite loop

/*
    About: AudioGhost

    - invisible audio player
*/
const Audio = window.Audio;
const navigator = window.navigator;

const requestAudioPermission = async () => {
  try {
    await navigator.mediaDevices.getUserMedia({ audio: true });
  } catch (error) {
    console.error("error requesting audio permission:");
    console.error(error);
  }
};

const updateAudioGhostQueueWithoutRecentlyPlayed = async (recentlyPlayed) => {
  await updateAudioGhostQueue({
    mutateFunction: async (audioGhostQueue) => {
      const newAudioGhostQueue = audioGhostQueue.filter((ghostQueueElement) => {
        return !recentlyPlayed.some((recentlyPlayedElement) => {
          return compareAudioGhostQueueElements(
            recentlyPlayedElement,
            ghostQueueElement
          );
        });
      });
      /*
        The above filter, explained:
        - for each element in audioGhostQueue
        - check if it is in recentlyPlayed
        - if it is not in recentlyPlayed, keep it in newAudioGhostQueue
      */

      console.log(
        "Updating audioGhostQueue, old length:",
        audioGhostQueue.length,
        "new length:",
        newAudioGhostQueue.length
      );

      return newAudioGhostQueue;
    },
  });
};

// let recentlyPlayedMessageIds = [];
let recentlyPlayedGhostQueueElements = [];

const MAX_RECENTLY_PLAYED_MESSAGE_IDS = 100;

const addToRecentlyPlayed = (ghostQueueElement) => {
  // recentlyPlayedMessageIds.unshift(messageId);
  recentlyPlayedGhostQueueElements.unshift(
    Object.assign({}, ghostQueueElement)
  );

  // if (recentlyPlayedMessageIds.length > MAX_RECENTLY_PLAYED_MESSAGE_IDS) {
  //   recentlyPlayedMessageIds.pop();
  // }

  if (
    recentlyPlayedGhostQueueElements.length > MAX_RECENTLY_PLAYED_MESSAGE_IDS
  ) {
    recentlyPlayedGhostQueueElements.pop();
  }

  // console.log("recentlyPlayedMessageIds:", recentlyPlayedMessageIds.length);

  console.log(
    "recentlyPlayedGhostQueueElements:",
    recentlyPlayedGhostQueueElements.length
  );
};

let currentlyPlayingAudio = null;

const playAudioInQueue = () => {
  return new Promise((resolve, reject) => {
    executionQueue(async () => {
      const audioGhostQueue = getState("audioGhostQueue");

      if (audioGhostQueue.length === 0) {
        return;
      }

      try {
        if (currentlyPlayingAudio) {
          currentlyPlayingAudio.pause();
          await delay(250);
        }

        await requestAudioPermission();

        const ghostQueueElement = audioGhostQueue[0];

        const messageId = ghostQueueElement?.messageId;

        if (!messageId) {
          console.error("no messageId in ghostQueueElement!");
          return;
        }

        // if (recentlyPlayedMessageIds.includes(messageId)) {
        //   console.log("messageId already in recentlyPlayedMessageIds");

        // if(com)

        for (let i = 0; i < recentlyPlayedGhostQueueElements.length; i++) {
          if (
            compareAudioGhostQueueElements(
              recentlyPlayedGhostQueueElements[i],
              ghostQueueElement
            )
          ) {
            console.log(
              "ghostQueueElement already in recentlyPlayedGhostQueueElements"
            );
            await updateAudioGhostQueueWithoutRecentlyPlayed([
              ghostQueueElement,
            ]);
            return;
          }
        }

        // stopAllPreviousAudios();

        const audioBufferString = ghostQueueElement?.audioBufferString;

        if (!audioBufferString) {
          console.error("no audioBufferString in ghostQueueElement!");
          return;
        }

        // const audioBufferString = audioGhostQueue.shift();
        // earliest added audioBufferString

        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${audioBufferString}`;
        audio.play();

        updateMessageIdToAudioPlayTime(messageId);

        currentlyPlayingAudio = audio;
        addToRecentlyPlayed(ghostQueueElement);

        audio.onerror = async (error) => {
          console.error("error playing audio:");
          console.error(error);

          await updateAudioGhostQueueWithoutRecentlyPlayed([ghostQueueElement]);

          currentlyPlayingAudio = null;

          reject(error);
        };

        const handleAudioEnded = async () => {
          console.log("audio ended");

          await updateAudioGhostQueueWithoutRecentlyPlayed([ghostQueueElement]);

          const messageId = ghostQueueElement?.messageId;

          if (messageId) {
            emitEvent({
              type: EVENT_TYPES.MESSAGE_AUDIO_PLAY_COMPLETED,
              data: {
                messageId,
              },
            });
          }

          currentlyPlayingAudio = null;

          resolve();
        };

        // audio.onpause = async () => {
        //   // end of audio

        //   console.log("audio paused");
        //   handleAudioEnded();
        // };

        audio.onended = handleAudioEnded;

        // addToPreviousAudios({ audio, ghostQueueElement });
      } catch (error) {
        console.error("error in playAudioInQueue:");
        console.error(error);

        console.error("clearing audioGhostQueue!");
        setState(["audioGhostQueue"], []);

        currentlyPlayingAudio = null;

        reject(error);
      }
    });
  });
};

export default playAudioInQueue;
