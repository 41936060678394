import React, {
  useEffect,
  useState,
  // useState
} from "react";
import {
  // CHARACTERS,
  // COLOR_2,
  COLOR_4,
  LOCAL_STORAGE_KEYS,
  Z_INDICES,
} from "../../../constants";
import { getState, setState } from "../../../reactStateManagement";
import {
  getXCoordFromXPixels,
  getXPixelsFromXPercentString,
  getYCoordFromYPixels,
  getYPixelsFromYPercentString,
} from "../../../coordsMapper";
// import updateMetaverseUserState from "../../../utils/state/updateMetaverseUserState";
// import { getMTokenData } from "../../../utils/mToken";
// import delay from "../../../utils/delay";
// import setArbitraryBCharDatumKeyValuePair from "../../../utils/bChar/setArbitraryBCharDatumKeyValuePair";
import { setRespawn } from "../../../utils/bChar/setRespawn";
import delay from "../../../utils/delay";

const getCurrentCoords = (bCharDatum) => {
  const xPixels = getXPixelsFromXPercentString(bCharDatum.x);
  const yPixels = getYPixelsFromYPercentString(bCharDatum.y);

  const xCoord = getXCoordFromXPixels(xPixels);
  const yCoord = getYCoordFromYPixels(yPixels);

  return { xCoord, yCoord };
};

const refreshLocalRespawnData = (respawnData, setLocalRespawnDisplayData) => {
  if (respawnData) {
    const { bCharDatum } = respawnData;

    if (bCharDatum) {
      // const { x, y } = bCharDatum;

      // const xPixels = getXPixelsFromXPercentString(bCharDatum.x);
      // const yPixels = getYPixelsFromYPercentString(bCharDatum.y);

      // const xCoord = getXCoordFromXPixels(xPixels);
      // const yCoord = getYCoordFromYPixels(yPixels);

      // console.log(`

      //   MEGA LOG ${JSON.stringify(
      //     {
      //       xPixels,
      //       yPixels,
      //       xCoord,
      //       yCoord,
      //     },
      //     null,
      //     4
      //   )}
      //   )

      // `);

      const { xCoord, yCoord } = getCurrentCoords(bCharDatum);

      setLocalRespawnDisplayData({ xCoord, yCoord });
    }
  }
};

const SetRespawnButton = () => {
  const respawnData = getState("respawnData");

  const loading = getState("loading");

  const [localRespawnDisplayData, setLocalRespawnDisplayData] = useState(null);

  // useEffect(() => {
  //   localStorage.setItem(
  //     LOCAL_STORAGE_KEYS.RESPAWN_DATA,
  //     JSON.stringify(respawnData)
  //   );
  // }, [respawnData]);

  // localRespawnDisplayData?.xCoord || "0";
  // localRespawnDisplayData?.yCoord || "0";

  const xCoord = Number(localRespawnDisplayData?.xCoord || "0");

  const xCoordIsLarge = xCoord > 9999;

  const yCoord = Number(localRespawnDisplayData?.yCoord || "0");

  const yCoordIsLarge = yCoord > 9999;

  const coordFontSize = xCoordIsLarge || yCoordIsLarge ? 10 : "unset";

  useEffect(() => {
    refreshLocalRespawnData(respawnData, setLocalRespawnDisplayData);
  }, [respawnData]);

  useEffect(() => {
    const respawnDataString = localStorage.getItem(
      LOCAL_STORAGE_KEYS.RESPAWN_DATA
    );

    if (respawnDataString) {
      try {
        const respawnData = JSON.parse(respawnDataString);

        refreshLocalRespawnData(respawnData, setLocalRespawnDisplayData);

        setTimeout(() => {
          refreshLocalRespawnData(respawnData, setLocalRespawnDisplayData);
        }, 100);
      } catch (error) {
        console.log("error in init respawn data parsing:");
        console.log(error);
      }
    }
  }, []);

  return (
    <div
      style={{
        width: "50%",
        height: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        // backgroundColor: "purple",

        zIndex: Z_INDICES.TOP_LAYER,
      }}
    >
      <button
        style={{
          width: "100%",
          height: "100%",
          // backgroundColor: "blue",

          // backgroundColor: COLOR_4,
          backgroundColor: loading ? "gray" : COLOR_4,
          color: "white",
        }}
        disabled={loading}
        onClick={async () => {
          // setState(["showCoords"], !showCoords);

          // const bCharDatum = getState("bCharDatum");

          // const respawnData = {
          //   bCharDatum,
          // };

          // setState(["respawnData"], respawnData);

          // const mTokenData = getMTokenData();

          // if (!mTokenData) {
          //   return;
          // }

          setState(["loading"], true);

          await setRespawn({});
          await delay(1500);

          // const TEST_SKIP = true;

          // // if (TEST_SKIP) {
          // //   setTimeout(() => {
          // //     setState(["loading"], false);
          // //   }, 2000);

          // //   return;
          // // }

          // try {
          //   // await updateMetaverseUserState({
          //   //   key: "respawnData",
          //   //   value: respawnData,
          //   // });

          //   // await updateMetaverseUserState({
          //   //   key: "bCharDatum",
          //   //   value: bCharDatum,
          //   // });

          //   await setArbitraryBCharDatumKeyValuePair({
          //     keyValuePairs: [
          //       {
          //         key: "x",
          //         value: bCharDatum.x,
          //       },
          //       {
          //         key: "y",
          //         value: bCharDatum.y,
          //       },
          //       {
          //         key: "character",
          //         value: bCharDatum.character || CHARACTERS.DOGE,
          //       },
          //     ],
          //   });

          //   await delay(1500);
          // } catch (err) {
          //   console.error("Error in SetRespawnButton onClick:");
          //   console.error(err);
          // }

          setState(["loading"], false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>{"Set Respawn"}</div>
          <div
            style={{
              fontSize: coordFontSize,
              wordBreak: "break-all",
            }}
          >{`(${xCoord}, ${yCoord})`}</div>
        </div>
      </button>
    </div>
  );
};

export { SetRespawnButton };
