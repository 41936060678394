import React, { useEffect, useState } from "react";
// import { DisplayText } from "./DisplayText";
import {
  //UNIT_WIDTH,
  Z_1,
} from "../nzwConstants";

const ATTACK_DISPLAY_REFRESH_INTERVAL = 300;
const ATTACK_TIME = 1500;

const FIRE_IMAGE_URL =
  "https://coreminterstackprods3nftmine83689-nftmine6aababc1-1i1zrafm04pwk.s3.amazonaws.com/item-images/fire_attack_0.png";

const FireContent = ({
  //
  wasRightLastDirection,
  attack = null,
  w,
  h,
}) => {
  const [fireAttackImageLoaded, setFireAttackImageLoaded] = useState(false);
  const [displayAttack, setDisplayAttack] = React.useState(false);

  useEffect(() => {
    const isAttackInteger = attack && Number.isInteger(attack);

    if (!isAttackInteger) {
      return;
    }

    const effectSetDisplayAttack = () => {
      const timeSinceAttackStart = Date.now() - attack;

      if (timeSinceAttackStart >= ATTACK_TIME) {
        setDisplayAttack(false);
        setFireAttackImageLoaded(false);
      } else {
        setDisplayAttack(true);
      }
    };

    effectSetDisplayAttack();

    const i0 = setInterval(
      effectSetDisplayAttack,
      ATTACK_DISPLAY_REFRESH_INTERVAL
    );

    return () => {
      clearInterval(i0);
    };
  }, [attack]);

  if (!displayAttack) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        // left: w,d
        left: wasRightLastDirection ? w : -w * 2,

        width: w * 2,
        height: h,

        // backgroundColor: "red",

        backgroundColor: fireAttackImageLoaded ? "transparent" : "red",

        zIndex: Z_1 + 2,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={FIRE_IMAGE_URL}
        alt="fire attack"
        style={{
          width: "100%",
          height: "100%",
        }}
        onLoad={() => {
          setFireAttackImageLoaded(true);
        }}
        onError={() => {
          setFireAttackImageLoaded(false);
        }}
      />
    </div>
  );
};

export default FireContent;
