import { TOTAL_FORM_SCREEN_FORM_TYPES } from "../../constants";

export const validateForm = (formValues) => {
  const keys = Object.keys(formValues);

  if (keys.length === 0) {
    return false;
  }

  for (const key of keys) {
    const formValue = formValues[key];

    if (!formValue) {
      return false;
    }

    switch (formValue.type) {
      case TOTAL_FORM_SCREEN_FORM_TYPES.TEXT:
        if (typeof formValue.value !== "string") {
          return false;
        }

        if (
          formValue.value.length < formValue.min ||
          formValue.value.length > formValue.max
        ) {
          return false;
        }
        break;
      default:
        return false;
    }
  }

  return true;
};
