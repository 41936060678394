var Image = window.Image;

const getFashionImage = ({
  preBaseImageUrls = [],
  baseImageUrl,
  overlayImageUrls = [],
}) => {
  if (!baseImageUrl) {
    console.error("baseImageUrl is required");
    throw new Error("Base image URL is required");
  }

  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.crossOrigin = "Anonymous";
        img.src = src;
      });
    };

    // Function to draw an image onto the canvas
    const drawImageOnCanvas = async (img, x = 0, y = 0) => {
      if (!img) return; // Skip if image loading failed
      ctx.drawImage(img, x, y);
    };

    const processImages = async () => {
      let baseImage = await loadImage(baseImageUrl);

      canvas.width = baseImage.width;
      canvas.height = baseImage.height;

      // Load and draw pre-base images first
      for (let url of preBaseImageUrls) {
        try {
          const img = await loadImage(url);
          await drawImageOnCanvas(img);
        } catch (error) {
          console.warn(`Failed to load pre-base image: ${url}`, error);
        }
      }

      if (baseImage) {
        await drawImageOnCanvas(baseImage);
      }

      // Load and overlay images
      for (let urlOrData of overlayImageUrls) {
        let url,
          xOffset = 0,
          yOffset = 0;
        if (typeof urlOrData === "string") {
          url = urlOrData;
        } else {
          url = urlOrData.url;
          xOffset = urlOrData.xOffset || 0;
          yOffset = urlOrData.yOffset || 0;
        }

        try {
          const overlayImage = await loadImage(url);
          await drawImageOnCanvas(overlayImage, xOffset, yOffset);
        } catch (error) {
          console.error(`Failed to load overlay image: ${url}`, error);
        }
      }

      resolve(canvas.toDataURL());
    };

    processImages().catch(reject);
  });
};

export default getFashionImage;
