import React from "react";
import GameComponentButton from "../../../../../commonComponents/GameComponentButton";
import getNFTAttributesData from "./getNFTAttributesData";
import { getState, setState } from "../../../../../reactStateManagement";
import createNFTImage from "./createNFTImage";
import { emitEvent } from "../../../../../utils/emitEvent";
import { EVENT_TYPES } from "../../../../../constants";
import getAuth from "../../../../../utils/getAuth";
import { getMTokenData } from "../../../../../utils/mToken";
import getDoPromiseInQueue from "../../../../../utils/getDoPromiseInQueue";
import delay from "../../../../../utils/delay";
// import Spacer from "../../../../../commonComponents/Spacer";

const doPromiseInQueue = getDoPromiseInQueue();

const makeHunny = async () => {
  const bCharDatum = getState("bCharDatum");

  const character = bCharDatum.character;
  const fashionHat = bCharDatum.fashionHat;
  const fashionTop = bCharDatum.fashionTop;
  const fashionBottom = bCharDatum.fashionBottom;

  try {
    const backgroundImageUrl = "/images/base_image_black.png";

    const { attributes, useBaseImage } = getNFTAttributesData({
      character,
      fashionHat,
      fashionTop,
      fashionBottom,
      backgroundImageUrl,
    });

    const imageDataUri = await createNFTImage({
      useBaseImage,
      character,
      fashionHat,
      fashionTop,
      fashionBottom,
    });

    // console.log("FashionSelectNFTMode: imageDataUri: ");
    // console.log(imageDataUri);

    console.log("FashionSelectNFTMode: attributes: ");
    console.log(attributes);

    // const TEST_EXIT_EARLY = true;
    const TEST_EXIT_EARLY = false;

    if (TEST_EXIT_EARLY) {
      return;
    }

    const auth = getAuth();

    if (!auth) {
      setState(["browserAlertData"], {
        message: "Please login to assign NFT",
      });

      return;
    }

    const mTokenData = getMTokenData();

    emitEvent({
      type: EVENT_TYPES.ASSIGN_NFT_FASHION,
      data: {
        auth,
        address: mTokenData.address,
        mToken: mTokenData.mToken,
        attributes,
        imageDataUri,
      },
    });
  } catch (e) {
    console.log("FashionSelectNFTMode: error: ");
    console.log(e);

    setState(["browserAlertData"], {
      message: e.message,
    });
  }
};

const FashionSelectNFTMode = ({ height }) => {
  const [localLoading, setLocalLoading] = React.useState(false);

  const color = localLoading ? "gray" : "gold";

  return (
    <div
      style={{
        height,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        backgroundColor: "black",
      }}
    >
      {/* <Spacer height={20} /> */}

      <GameComponentButton
        id={"assignToNFT"}
        style={{
          width: "80%",
          height: 30,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // border: `1px solid gold`,
          border: `1px solid ${color}`,
          borderRadius: 5,
          backgroundColor: "transparent",
          // color: "gold",
          color,
        }}
        disabled={localLoading}
        onClick={async () => {
          await doPromiseInQueue({
            operation: async () => {
              setLocalLoading(true);

              try {
                await makeHunny();

                await delay(4000);
              } catch (e) {
                console.log("FashionSelectNFTMode: error: ");
                console.log(e);

                await delay(2000);
              }

              setLocalLoading(false);
            },
          });
        }}
      >
        {"Make Hunny"}
      </GameComponentButton>

      {/* <Spacer height={0} /> */}

      <div
        style={{
          width: "80%",
          height: 30,
          fontSize: 12,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          //   border: `1px solid gold`,
          borderRadius: 5,
          backgroundColor: "transparent",
          color: "gold",
        }}
      >
        {"Must have ownership of NFT to make your hunny - only once per NFT!"}
      </div>

      <GameComponentButton
        id={"mintNFT"}
        style={{
          width: "80%",
          height: 25,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          border: `1px solid gold`,
          borderRadius: 5,
          backgroundColor: "transparent",
          color: "gold",
        }}
        onClick={() => {
          // send message to parent window

          window.parent.postMessage("nftitemland-goToMintPage", "*");
        }}
      >
        {"Mint NFT"}
      </GameComponentButton>
    </div>
  );
};

export default FashionSelectNFTMode;
