import handleSTUAMessage from "./handleSTUAMessage";

const STUA_MESSAGE = "STUA_MESSAGE";

const handleServerTriggeredUserAction = async (data) => {
  if (!data.type) {
    return;
  }

  switch (data.type) {
    case STUA_MESSAGE: {
      await handleSTUAMessage(data);
      break;
    }
    default:
      break;
  }
};

export default handleServerTriggeredUserAction;
