import React, { useEffect, useRef, useState } from "react";
import { Z_1 } from "../../nzwConstants";
import { CHARACTERS, ZOOM_LEVELS } from "../../../../constants";
import { getState, setState } from "../../../../reactStateManagement";
// import { createDebugText } from "./createDebugText";
import { textLengthToData } from "./textLengthToData";
import { getSettingsByCharacter } from "./getSettingsByCharacter";
import { getLastFreshUserMessage } from "./getLastFreshUserMessage";
import { getModifiedDuration } from "./getModifiedDuration";
import getCoords from "./getCoords";
import multiRefreshBroadcastKey from "./multiRefreshBroadcastKey";
import updateBChar from "../../../../utils/bChar/updateBChar";

const BORDER_RADIUS = 10;
const BORDER_RADIUS_2 = 5;

export const DEFAULT_X_OFFSET = 0;
export const DEFAULT_Y_OFFSET = -10;

const isNonChatBubbleCharacter = (character) => {
  return character === CHARACTERS.PIGYAN;
};

const ChatBubble = ({
  w,
  h,
  userId,
  address,
  character,
  // lastMessage = createDebugText(),
  // lastMessageTime,
  messages,
  scale,
}) => {
  const messageIdToAudioPlayTime = getState("messageIdToAudioPlayTime");

  // move to top level state
  const [lastFreshUserMessage, setLastFreshUserMessage] = useState(null);
  const [lastFreshUserMessageTime, setLastFreshUserMessageTime] =
    useState(null);
  const [lastFreshUserMessageId, setLastFreshUserMessageId] = useState(null);
  const [lastFreshUserMessageTag, setLastFreshUserMessageTag] = useState(null);
  const [
    lastFreshUserMessageAudioBufferMetadata,
    setLastFreshUserMessageAudioBufferMetadata,
  ] = useState(null);
  const [sentences, setSentences] = useState(null);
  const [displayMessage, setDisplayMessage] = useState(null);
  const timeoutIds = useRef([]);

  // New state variables for tracking played messages and final display message
  const [finalDisplayMessage, setFinalDisplayMessage] = useState(null);
  const [lastPlayedMessages, setLastPlayedMessages] = useState([]);

  useEffect(() => {
    if (isNonChatBubbleCharacter(character)) {
      return;
    }

    const fresh = getLastFreshUserMessage({
      userId,
      address,
      messages,
    });

    const messageToUse = fresh.lastMessage;
    let timeToUse = fresh.lastMessageTime;
    const lastMessageId = fresh.lastMessageId;
    let audioBufferMetadata = fresh.audioBufferMetadata;
    const tag = fresh.lastMessageTag;

    if (lastMessageId === lastFreshUserMessageId) {
      return;
    } else if (!lastMessageId) {
      setLastFreshUserMessage(null);
      setLastFreshUserMessageTime(null);
      setLastFreshUserMessageId(null);
      setLastFreshUserMessageAudioBufferMetadata(null);
      return;
    }

    setLastFreshUserMessage(messageToUse);
    setLastFreshUserMessageTime(timeToUse);

    if (lastMessageId && !audioBufferMetadata) {
      audioBufferMetadata = {
        metadata: [
          {
            time: 0,
            type: "sentence",
            start: 0,
            end: messageToUse.length,
            value: messageToUse,
          },
        ],
      };
    }

    setLastFreshUserMessageAudioBufferMetadata(audioBufferMetadata);
    setLastFreshUserMessageId(lastMessageId);

    if (tag) {
      setLastFreshUserMessageTag(tag);
    }
  }, [messages]);

  useEffect(() => {
    if (!lastFreshUserMessage) {
      return;
    }

    if (isNonChatBubbleCharacter(character)) {
      return;
    }

    // Check if the message has been played before
    if (lastPlayedMessages.includes(lastFreshUserMessage)) {
      // Message has been played before; do not display it
      setFinalDisplayMessage(null);
    } else {
      // New message; update the final display message and the list of played messages
      setFinalDisplayMessage(lastFreshUserMessage);
      setLastPlayedMessages((prevMessages) => {
        const newMessages = [...prevMessages, lastFreshUserMessage];
        if (newMessages.length > 1) {
          newMessages.shift(); // Keep only the last 10 messages
        }
        return newMessages;
      });
    }
  }, [lastFreshUserMessage]);

  useEffect(() => {
    if (
      isNonChatBubbleCharacter(character) ||
      !lastFreshUserMessageId ||
      !lastFreshUserMessageAudioBufferMetadata ||
      !lastFreshUserMessageAudioBufferMetadata.metadata ||
      lastFreshUserMessageAudioBufferMetadata.metadata.length === 0 ||
      !lastFreshUserMessage ||
      !lastFreshUserMessage.length ||
      !lastFreshUserMessageTime
    ) {
      setSentences(null);
      return;
    }

    const lastDoneMessageIds = getState("lastDoneMessageIds");

    const lastDoneMessageIdData =
      lastDoneMessageIds[lastDoneMessageIds.length - 1];

    if (lastDoneMessageIdData) {
      const highestTime =
        lastDoneMessageIds[lastDoneMessageIds.length - 1]?.time;

      if (
        highestTime &&
        lastFreshUserMessageTime &&
        lastFreshUserMessageTime < highestTime
      ) {
        setSentences(null);
        return;
      }

      const messageIdIsAlreadyDone = lastDoneMessageIds.some(
        (lastDoneMessageIdData) =>
          lastDoneMessageIdData.messageId === lastFreshUserMessageId
      );

      if (messageIdIsAlreadyDone) {
        setSentences(null);
        return;
      }
    }

    // No need to check for played messages here since we handle it in the previous useEffect
    setSentences(lastFreshUserMessageAudioBufferMetadata.metadata);
  }, [lastFreshUserMessageId]);

  useEffect(() => {
    if (!sentences || sentences.length === 0) {
      return;
    }

    timeoutIds.current.forEach(clearTimeout);
    timeoutIds.current = [];

    let timeToUse = lastFreshUserMessageTime;

    // const messageId = sentences[0].messageId;

    if (messageIdToAudioPlayTime[lastFreshUserMessageTag]) {
      timeToUse = messageIdToAudioPlayTime[lastFreshUserMessageTag];
    }

    const currentTime = Date.now() - timeToUse;
    let index = 0;

    while (index < sentences.length && sentences[index].time <= currentTime) {
      index++;
    }

    const currentIndex = Math.max(index - 1, 0);

    // Immediately update displayMessage
    setDisplayMessage(sentences[currentIndex].value);

    const scheduleNextSentence = (currentIndex) => {
      if (currentIndex >= sentences.length) {
        return;
      }

      const sentence = sentences[currentIndex];
      const delay = lastFreshUserMessageTime + sentence.time - Date.now();

      const addToLastDoneMessageIds = () => {
        const lastDoneMessageIds = getState("lastDoneMessageIds");
        const MAX_LAST_DONE_MESSAGE_IDS = 100;
        const newLastDoneMessageIds = [
          ...lastDoneMessageIds,
          {
            messageId: lastFreshUserMessageId,
            time: lastFreshUserMessageTime,
          },
        ];
        if (newLastDoneMessageIds.length > MAX_LAST_DONE_MESSAGE_IDS) {
          newLastDoneMessageIds.shift();
        }
        setState(["lastDoneMessageIds"], newLastDoneMessageIds);
      };
      addToLastDoneMessageIds();

      const timeoutId = setTimeout(() => {
        setDisplayMessage(sentence.value);
        scheduleNextSentence(currentIndex + 1);
        updateBChar({
          mutateFunction: (bCharDatum) => {
            const bCharDatum2 = {
              ...bCharDatum,
              lastMessage: sentence.value,
              lastMessageTime: Date.now(),
            };

            return bCharDatum2;
          },
        });
        setTimeout(() => {
          multiRefreshBroadcastKey();
        }, 20);
      }, Math.max(delay, 0));

      timeoutIds.current.push(timeoutId);
    };

    scheduleNextSentence(currentIndex + 1);

    return () => {
      timeoutIds.current.forEach(clearTimeout);
      timeoutIds.current = [];
    };
  }, [sentences, messageIdToAudioPlayTime]);

  if (isNonChatBubbleCharacter(character)) {
    return null;
  }

  if (!lastFreshUserMessage || !lastFreshUserMessageTime) {
    return null;
  }

  // Check if the message has been played before; if so, do not display it
  if (!finalDisplayMessage) {
    return null;
  }

  const smolText = lastFreshUserMessage && lastFreshUserMessage.length < 10;
  const isZoomed = scale >= ZOOM_LEVELS.MICRO_OF_MICRO;

  let { fontSize, duration } = textLengthToData(
    lastFreshUserMessage.length,
    isZoomed
  );

  const modifiedDuration = getModifiedDuration({
    lastFreshUserMessageAudioBufferMetadata,
  });

  if (modifiedDuration) {
    duration = modifiedDuration;
  }

  let timeToUse =
    // messageIdToAudioPlayTime[lastFreshUserMessageId] ||
    messageIdToAudioPlayTime[lastFreshUserMessageTag] ||
    lastFreshUserMessageTime;
  // ||
  // lastMessageTime;

  if (!timeToUse || (timeToUse && Date.now() - timeToUse > duration)) {
    return null;
  }

  if (!displayMessage) {
    return null;
  }

  const settings = getSettingsByCharacter(character, isZoomed);

  if (!displayMessage) {
    return null;
  }

  const coords = getCoords({
    isZoomed,
    settings,
    w,
    h,
    smolText,
    BORDER_RADIUS,
    BORDER_RADIUS_2,
    Z_1,
  });

  return (
    <div
      style={{
        ...coords,
      }}
    >
      {/* Triangle on the right side for chat bubble pointer */}
      {isZoomed ? (
        <div
          style={{
            position: "absolute",
            top: 5,
            right: -7,
            width: 0,
            height: 0,
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            borderLeft: "10px solid white",
          }}
        ></div>
      ) : null}
      <div
        style={{
          position: "relative",
          textAlign: smolText ? "center" : "left",
          userSelect: "all",
          display: "inline-block",
          fontSize,
        }}
      >
        {displayMessage}
      </div>
    </div>
  );
};

export default ChatBubble;
