import React from "react";
import useGameComponent from "../utils/effects/useGameComponent";

const GameComponentButton = ({ id, children, onClick, disabled, style }) => {
  style = style || {};

  useGameComponent({ id });

  return (
    <button
      id={id}
      onClick={onClick}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        cursor: "pointer",
        ...style,
      }}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default GameComponentButton;
