import React from "react";
import {
  COLOR_0,
  COLOR_1,
  COLOR_4,
  COLOR_5,
  STANDARD_BUTTON_HEIGHT,
  // EVENT_TYPES
  characterToData,
  // CHARACTERS,
  // CHARACTERS_ARRAY,
  PLAYABLE_CHARACTERS as CHARACTERS,
  SELECTABLE_CHARACTERS_ARRAY as CHARACTERS_ARRAY,
  // PRODUCTION_MODE,
  // DEFAULT_START_X_PERCENT_STRING,
  // DEFAULT_START_Y_PERCENT_DECIMAL,
} from "../../../../constants";
typeof COLOR_0;
// import { emitEvent } from "../../../../utils/emitEvent";
// import getAuth from "../../../../utils/getAuth";
// import { getMTokenData } from "../../../../utils/mToken";
import { getState, setState } from "../../../../reactStateManagement";
// import updateBChar from "../../../../utils/bChar/updateBChar";
// import updateMetaverseUserState from "../../../../utils/state/updateMetaverseUserState";
// import { getMTokenData } from "../../../../utils/mToken";
// import { getUserData } from "../../../../api/getUserData";
// import setArbitraryBCharDatumKeyValuePair from "../../../../utils/bChar/setArbitraryBCharDatumKeyValuePair";
import SetToFashionSelectModeButton from "./SetToFashionSelectModeButton";
import FashionSelectMode from "./FashionSelectMode";
import doSelectCharacter from "../../../../utils/character/doSelectCharacter";

const RIGHT = "RIGHT";
const LEFT = "LEFT";

const MAIN_SECTION_PERCENT = 1;
const IMAGE_SECTION_PERCENT = 0.5;
const CHOOSE_BUTTON_SECTION_PERCENT = 0.5 / 3;
const SELECT_BUTTON_SECTION_PERCENT = 0.5 / 3;
const SELECT_FASHION_SECTION_PERCENT = 0.5 / 3;

// const setArbitaryBCharDatumKeyValuePair = async ({
//   key,
//   value,
//   key2,
//   value2,
// }) => {
//   await updateBChar({
//     mutateFunction: (bChar) => {
//       const newBChar = {
//         ...bChar,
//         [key]: value,
//       };

//       if (key2 && value2) {
//         newBChar[key2] = value2;
//       }

//       const mTokenData = getMTokenData();
//       if (mTokenData) {
//         getUserData({ addToState: false })
//           .then(async (userData) => {
//             const arbitraryBCharDatum =
//               userData?.arbitraryData?.metaverse_bCharDatum;

//             let newArbitraryBCharDatum;

//             if (arbitraryBCharDatum) {
//               console.log("set path a");
//               newArbitraryBCharDatum = {
//                 ...arbitraryBCharDatum,
//                 [key]: value,
//               };

//               if (key2 && value2) {
//                 newArbitraryBCharDatum[key2] = value2;
//               }
//             } else {
//               console.log("set path b");
//               newArbitraryBCharDatum = {
//                 ...newBChar,
//                 character: CHARACTERS.DOGE,
//                 x: DEFAULT_START_X_PERCENT_STRING,
//                 y: DEFAULT_START_Y_PERCENT_DECIMAL,
//               };
//             }

//             await updateMetaverseUserState({
//               key: "bCharDatum",
//               value: newArbitraryBCharDatum,
//             });
//           })
//           .catch((err) => {
//             console.error(
//               "setArbitaryBCharDatumKey: error in setting bCharDatum character:"
//             );
//             console.error(err);
//           });
//       }

//       return newBChar;
//     },
//   });
// };

const CharacterPreview = ({ characterDatum }) => {
  if (!characterDatum) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={characterDatum.imageUrlA}
        alt="Character"
        style={{
          // width: "100%",
          // height: "100%",

          maxWidth: "100%",
          maxHeight: "100%",
        }}
      />
    </div>
  );
};

const CharacterScrollButton = ({
  direction,
  characterIndex,
  setCharacterIndex,
}) => {
  return (
    <button
      style={{
        width: "50%",
        height: "100%",

        backgroundColor: COLOR_4,
        color: COLOR_0,
      }}
      onClick={() => {
        if (direction === RIGHT) {
          setCharacterIndex((characterIndex + 1) % CHARACTERS_ARRAY.length);
        } else if (direction === LEFT) {
          setCharacterIndex(
            (characterIndex - 1 + CHARACTERS_ARRAY.length) %
              CHARACTERS_ARRAY.length
          );
        }
      }}
    >
      {direction === RIGHT ? ">" : "<"}
    </button>
  );
};

const SelectCharacterButton = ({ character }) => {
  return (
    <button
      style={{
        width: "100%",
        height: "100%",

        backgroundColor: "red",
        color: "white",
      }}
      onClick={async () => {
        // await updateBChar({
        //   mutateFunction: (bChar) => {
        //     const newBChar = {
        //       ...bChar,
        //       character,
        //     };

        //     const mTokenData = getMTokenData();
        //     if (mTokenData) {
        //       getUserData({ addToState: false })
        //         .then((userData) => {
        //           const arbitraryBCharDatum =
        //             userData?.arbitraryData?.metaverse_bCharDatum;

        //           let newArbitraryBCharDatum;

        //           if (arbitraryBCharDatum) {
        //             console.log("set path a");
        //             newArbitraryBCharDatum = {
        //               ...arbitraryBCharDatum,
        //               character,
        //             };
        //           } else {
        //             console.log("set path b");
        //             newArbitraryBCharDatum = {
        //               ...newBChar,
        //               character,
        //               x: DEFAULT_START_X_PERCENT_STRING,
        //               y: DEFAULT_START_Y_PERCENT_DECIMAL,
        //             };
        //           }

        //           updateMetaverseUserState({
        //             key: "bCharDatum",
        //             value: newArbitraryBCharDatum,
        //           });
        //         })
        //         .catch((err) => {
        //           console.error("error in setting bCharDatum character:");
        //           console.error(err);
        //         });
        //     }

        //     return newBChar;
        //   },
        //   bBroadcast: true,
        // });

        // await setArbitaryBCharDatumKeyValuePair({
        //   key: "character",
        //   value: character,
        // });

        // await setArbitraryBCharDatumKeyValuePair({
        //   keyValuePairs: [
        //     { key: "character", value: character },
        //     {
        //       key: "fashionBottom",
        //       value: null,
        //     },
        //     {
        //       key: "fashionTop",
        //       value: null,
        //     },
        //     {
        //       key: "fashionHat",
        //       value: null,
        //     },
        //   ],
        // });

        await doSelectCharacter({ character });
      }}
    >
      {"Select Character"}
    </button>
  );
};

const LocalBackButton = ({ setFashionSelectMode }) => {
  return (
    <button
      style={{
        width: "100%",
        height: STANDARD_BUTTON_HEIGHT,

        backgroundColor: "black",
        border: "1px solid red",
        boxSizing: "border-box",

        color: "white",
      }}
      onClick={() => {
        setFashionSelectMode(false);
      }}
    >
      {"Choose Character"}
    </button>
  );
};

const CharacterSelect = ({ zIndex = "unset", executiveBoxHeight }) => {
  // const [characterIndex, setCharacterIndex] = useState(0);
  // const [fashionSelectMode, setFashionSelectMode] = useState(false);
  const characterIndex = getState("characterIndex");
  const fashionSelectMode = getState("fashionSelectMode");
  const character = CHARACTERS_ARRAY[characterIndex];

  const parsedCharacter = CHARACTERS[character];

  const characterDatum = characterToData[parsedCharacter];

  const setCharacterIndex = (newCharacterIndex) => {
    setState(["characterIndex"], newCharacterIndex);
  };

  const setFashionSelectMode = (newFashionSelectMode) => {
    setState(["fashionSelectMode"], newFashionSelectMode);
  };

  typeof setFashionSelectMode;

  const userData = getState("userData");

  // const mobile = getState("mobile");

  if (!userData) {
    return;
  }

  let contents;

  if (fashionSelectMode) {
    contents = [
      <div
        key="back-button"
        style={{
          width: "100%",
          // height: `${SELECT_FASHION_SECTION_PERCENT * 100}%`,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          backgroundColor: "black",
          // fontSize: 20,
        }}
      >
        <FashionSelectMode
          characterDatum={characterDatum}
          height={executiveBoxHeight - 2 * STANDARD_BUTTON_HEIGHT}
        />
        <LocalBackButton setFashionSelectMode={setFashionSelectMode} />
      </div>,
    ];
  } else {
    contents = [
      <div
        key="character-preview"
        style={{
          width: "100%",
          height: `${IMAGE_SECTION_PERCENT * 100}%`,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",

          // fontSize: 20,
        }}
      >
        <CharacterPreview characterDatum={characterDatum} />
      </div>,
      <div
        key="character-scroll"
        style={{
          width: "100%",
          height: `${CHOOSE_BUTTON_SECTION_PERCENT * 100}%`,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",

          // fontSize: 20,
        }}
      >
        <CharacterScrollButton
          direction={LEFT}
          characterIndex={characterIndex}
          setCharacterIndex={setCharacterIndex}
        />
        <CharacterScrollButton
          direction={RIGHT}
          characterIndex={characterIndex}
          setCharacterIndex={setCharacterIndex}
        />
      </div>,
      <div
        key="select-button"
        style={{
          width: "100%",
          height: `${SELECT_BUTTON_SECTION_PERCENT * 100}%`,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",

          backgroundColor: "violet",
          // fontSize: 20,
        }}
      >
        <SelectCharacterButton character={character} />
      </div>,

      <div
        key="select-fashion"
        style={{
          width: "100%",
          height: `${SELECT_FASHION_SECTION_PERCENT * 100}%`,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",

          backgroundColor: "black",
          // fontSize: 20,
        }}
      >
        <SetToFashionSelectModeButton
          character={character}
          setFashionSelectMode={setFashionSelectMode}
        />
      </div>,
    ];
  }

  return (
    <div
      style={{
        width: "100%",
        // height: 420,

        // maxHeight: 420,
        // height: mobile ? 190 : 420,

        height: executiveBoxHeight - STANDARD_BUTTON_HEIGHT,

        // backgroundColor: COLOR_0,
        // backgroundColor: "pink",
        color: COLOR_1,
        borderColor: COLOR_5,
        borderWidth: 1,
        borderStyle: "solid",

        boxSizing: "border-box",

        zIndex,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          // height: "80%",
          height: `${MAIN_SECTION_PERCENT * 100}%`,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          // fontSize: 20,
        }}
      >
        {contents}
      </div>

      {/* <div
        style={{
          width: "100%",

          // height: "60%",
          height: "calc(100% - 80px)",

          display: "block",

          overflowY: "scroll",
        }}
      ></div> */}
    </div>
  );
};

export default CharacterSelect;
