import { NWORLD_UNIT_WIDTH } from "../../../constants";

const HALF_CYCLE_LENGTH = 5;

const getNPCCycleLength = (halfCycleLength) => {
  return halfCycleLength * 2;
};

const NPC_CYCLE_LENGTH = getNPCCycleLength(HALF_CYCLE_LENGTH);

const w0 = NWORLD_UNIT_WIDTH;

function calculatePigyanPosition(x0, y0, systemTimeOffset) {
  // Since t0 is 0, we directly use currentTime for calculation simplicity
  let timeElapsed = Date.now() - systemTimeOffset;

  // Determine the position in the cycle: every 8 seconds, the pattern repeats (4 to left + 4 to right)
  let positionInCycle = Math.floor((timeElapsed / 1000) % NPC_CYCLE_LENGTH);

  let x;
  let wasRightLastDirection;

  if (positionInCycle >= HALF_CYCLE_LENGTH) {
    x = x0 + (NPC_CYCLE_LENGTH - positionInCycle) * w0;

    // if is last in cycle, then next will be left

    if (positionInCycle === HALF_CYCLE_LENGTH) {
      wasRightLastDirection = true;
    } else {
      wasRightLastDirection = false;
    }
  } else {
    x = x0 + positionInCycle * w0;

    if (positionInCycle === 0) {
      wasRightLastDirection = false;
    } else {
      wasRightLastDirection = true;
    }
  }

  return {
    x,
    y: y0,
    wasRightLastDirection,
  };
}

export default calculatePigyanPosition;
