// import updateBChars from "../../../../../../realtimeServer/app/utils/bChar/updateBChars.mjs";
// import { getState, setState } from "../../../../reactStateManagement";
import updateBChar from "../../../bChar/updateBChar";
// import getDoPromiseInQueue from "../../../getDoPromiseInQueue";

// const doPromiseInQueue = getDoPromiseInQueue();

// const MAX_CREATE_IMAGE_RESPONSES_TO_STORE = 200;

const handleTeleport = async (event) => {
  const x = event?.data?.x;
  const y = event?.data?.y;

  if (x && y) {
    updateBChar({
      mutateFunction: (bCharDatum) => {
        const newBCharDatum = { ...bCharDatum };

        newBCharDatum.x = x;
        newBCharDatum.y = y;

        return newBCharDatum;
      },

      bBroadcast: true,

      sourceTag: "handleTeleport",
    });
  } else {
    console.warn("handleTeleport: invalid event:");
    console.warn(event);
  }
};

export default handleTeleport;
