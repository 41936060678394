import React from "react";
// import { DisplayText } from "./DisplayText";
import { NCharBase } from "./NCharBase/NCharBase";
import { CUDDLE_CONSTANTS, SPECIAL_SELF_USER_ID } from "../../constants";
import { getMTokenData } from "../../utils/mToken";

export const NChar = ({
  userId,
  xPixels,
  yPixels,
  minY,
  characterDatum,
  botoScreenCaptureMode,
  eighteenPlusMode,
  messages,
  scale,
}) => {
  if (!characterDatum) {
    return null;
  }

  let fashion;

  if (characterDatum.fashionTop) {
    fashion = fashion || {};
    fashion.fashionTop = characterDatum.fashionTop;
  }

  if (characterDatum.fashionBottom) {
    fashion = fashion || {};
    fashion.fashionBottom = characterDatum.fashionBottom;
  }

  let cuddleImageUrl;
  let cuddleImageUrl2;

  if (characterDatum.cuddle) {
    if (characterDatum.cuddle.userIds[0] === userId) {
      const cuddleData = CUDDLE_CONSTANTS[characterDatum.cuddle.type];
      if (cuddleData) {
        cuddleImageUrl = eighteenPlusMode
          ? cuddleData.imageUrl
          : cuddleData.imageUrlCensor;
        // cuddleImageUrl2 = cuddleData.imageUrl2;
        cuddleImageUrl2 = eighteenPlusMode
          ? cuddleData.imageUrl2
          : cuddleData.imageUrl2Censor;
      }
    } else {
      // only primary user in cuddle gets to display the cuddle image
      return null;
    }
  }

  const mTokenData = getMTokenData();

  return (
    <NCharBase
      address={mTokenData?.address}
      xPixels={xPixels}
      yPixels={yPixels}
      index={0}
      rightDown={characterDatum.rightDown}
      leftDown={characterDatum.leftDown}
      lastDirection={characterDatum.lastDirection}
      minY={minY}
      botoScreenCaptureMode={botoScreenCaptureMode}
      character={characterDatum.character}
      // attack={true} // temporarily hardcoding this
      attack={characterDatum.attack}
      fun={characterDatum.fun}
      fashion={fashion}
      equippedItem={characterDatum.equippedItem}
      userId={SPECIAL_SELF_USER_ID}
      cuddleImageUrl={cuddleImageUrl}
      cuddleImageUrl2={cuddleImageUrl2}
      eighteenPlusMode={eighteenPlusMode}
      lastMessage={characterDatum.lastMessage}
      lastMessageTime={characterDatum.lastMessageTime}
      messages={messages}
      scale={scale}
    />
  );
};
