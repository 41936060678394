import React, { useEffect } from "react";
import {
  CHARACTERS,
  EVENT_TYPES,
  NONE,
  //FUN_CHARACTERS, NONE,
  Z_INDICES,
} from "../../constants";
// import updateBChar from "../../utils/bChar/updateBChar";
import { getState } from "../../reactStateManagement";
// import updateBChar from "../../utils/bChar/updateBChar";
import useGameComponent from "../../utils/effects/useGameComponent";
import { emitEvent } from "../../utils/emitEvent";
import getAuth from "../../utils/getAuth";
// import getDoPromiseInQueue from "../../utils/getDoPromiseInQueue";
// import delay from "../../utils/delay";
// import { getMTokenData } from "../../../utils/mToken";

// const doPromiseInQueue = getDoPromiseInQueue();

const SIZE_UNIT = 40;

const getDogeXDogeFashion0 = ({ userIdToBCharDatum }) => {
  let dogeBCharDatum = null;
  let dogeFashionBCharDatum = null;

  // find doge
  for (const uId in userIdToBCharDatum) {
    const bCharDatum = userIdToBCharDatum[uId];

    if (bCharDatum.character === CHARACTERS.DOGE) {
      if (
        bCharDatum.fashionTop &&
        bCharDatum.fashionTop.imageUrl === "/images/libra_0_top_0.png"
      ) {
        if (!bCharDatum.fashionHat || bCharDatum.fashionHat.imageUrl === NONE) {
          if (
            !bCharDatum.fashionBottom ||
            bCharDatum.fashionBottom.imageUrl === NONE
          ) {
            if (!bCharDatum.cuddle) {
              // if user is not already cuddling
              if (!dogeBCharDatum) {
                dogeBCharDatum = bCharDatum;
                dogeBCharDatum.userId = uId;
              }
            }
          }
        }
      }
    }
  }

  // find doge fashion
  for (const uId in userIdToBCharDatum) {
    const bCharDatum = userIdToBCharDatum[uId];
    if (bCharDatum.character === CHARACTERS.DOGE_FASHION) {
      if (!bCharDatum.fashionTop || bCharDatum.fashionTop.imageUrl === NONE) {
        if (
          !bCharDatum.fashionBottom ||
          bCharDatum.fashionBottom.imageUrl === NONE
        ) {
          if (!bCharDatum.cuddle) {
            if (!dogeFashionBCharDatum) {
              dogeFashionBCharDatum = bCharDatum;
              dogeFashionBCharDatum.userId = uId;
            }
          }
        }
      }
    }
  }

  if (dogeBCharDatum && dogeFashionBCharDatum) {
    return {
      dogeBCharDatum,
      dogeFashionBCharDatum,
    };
  }

  return null;
};

const CuddleButton = ({
  bCharDatum,
  userId,
  frenCharacterData,
  bottom = 0,
}) => {
  const menuOpen = getState("loginMode");
  const mobile = getState("mobile");

  const [cuddleButtonLive, setCuddleButtonLive] = React.useState(false);

  const id = "cuddle-button";

  useGameComponent({ id });

  const frenDataToConsider = frenCharacterData.filter(
    (characterDatum) => characterDatum.character !== CHARACTERS.PIGYAN
  );

  const frenDiff = frenDataToConsider.reduce((acc, characterDatum) => {
    return (
      acc +
      characterDatum.character +
      JSON.stringify(characterDatum.cuddle) +
      JSON.stringify(characterDatum.fashionTop) +
      JSON.stringify(characterDatum.fashionBottom) +
      JSON.stringify(characterDatum.fashionHat)
    );
  }, "");

  useEffect(() => {
    const setCuddleLiveEffect = () => {
      // console.log("setCuddleLiveEffect");
      if (bCharDatum.cuddle) {
        setCuddleButtonLive(true);
      } else {
        // console.log("frenCharacterData", frenCharacterData);

        const userIdToBCharDatum = {};

        for (const characterDatum of frenDataToConsider) {
          userIdToBCharDatum[characterDatum.userId] = characterDatum;
        }

        userIdToBCharDatum[userId] = {
          ...bCharDatum,
          userId,
        };

        const dogeXDogeFashion0 = getDogeXDogeFashion0({
          userIdToBCharDatum,
        });

        const userIsDogeOrDogeFashion =
          (dogeXDogeFashion0 &&
            dogeXDogeFashion0.dogeBCharDatum.userId === userId) ||
          (dogeXDogeFashion0 &&
            dogeXDogeFashion0.dogeFashionBCharDatum.userId === userId);

        // if (dogeXDogeFashion0) {

        if (userIsDogeOrDogeFashion) {
          setCuddleButtonLive(true);
        } else {
          setCuddleButtonLive(false);
        }
      }
    };

    // doPromiseInQueue({
    //   operation: async () => {
    //     setCuddleLiveEffect();

    //     await delay(10);
    //   },
    // });
    // typeof doPromiseInQueue;
    // typeof delay;
    setCuddleLiveEffect();

    // const i = setInterval(() => {
    //   setCuddleLiveEffect();
    // }, 2500);

    // return () => {
    //   clearInterval(i);
    // };
  }, [
    bCharDatum.cuddle,
    bCharDatum.character,
    bCharDatum.fashionHat,
    bCharDatum.fashionTop,
    bCharDatum.fashionBottom,
    frenDiff,
  ]);

  if (menuOpen) {
    return null;
  }

  if (!cuddleButtonLive) {
    return null;
  }

  let bottomVal = SIZE_UNIT / 4 + bottom * 1 + SIZE_UNIT / 2;

  if (!mobile) {
    bottomVal = bottomVal + SIZE_UNIT / 6;
  } else {
    bottomVal = bottomVal + SIZE_UNIT;
  }

  const rightOffsetMultiplier = mobile ? 1 : 3;

  const right =
    (mobile ? SIZE_UNIT / 4 : SIZE_UNIT / 2) +
    SIZE_UNIT * rightOffsetMultiplier;

  return (
    <button
      id={id}
      style={{
        position: "absolute",

        bottom: bottomVal,
        right,

        width: SIZE_UNIT * 1,
        height: SIZE_UNIT,

        // backgroundColor: "red",
        borderRadius: "50%",

        // maxHeight: 420,

        boxSizing: "border-box",

        zIndex: Number.MAX_SAFE_INTEGER || Z_INDICES.NEW_ZOOM_WORLD_LAYER * 2,

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        const auth = getAuth();

        emitEvent({
          type: EVENT_TYPES.CUDDLE,
          data: {
            auth,
          },
        });
      }}
      // onTouchStart={haveFun}
    >
      {/* {"❤️"} */}

      <img
        id={"cuddle-button-image"}
        src="/images/cuddle_button_0.png"
        alt="cuddle button"
        style={{
          width: "100%",
          height: "100%",

          userSelect: "none",
        }}
      />
    </button>
  );
};

export default CuddleButton;
