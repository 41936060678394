import { SPECIAL_SELF_USER_ID } from "../../../../constants";
import { getState } from "../../../../reactStateManagement";

const getLastFreshUserMessage = ({ userId, address, messages }) => {
  // const lastFreshUserMessage =
  // messages = messages || [];

  for (let i = messages.length - 1; i >= 0; i--) {
    // for (let i = 0; i < messages.length; i++) {
    const message = messages[i];

    const compareArray = [
      //userId
    ];

    const isSelf = userId === SPECIAL_SELF_USER_ID;

    if (isSelf) {
      const actualUserId = getState("userId");

      compareArray.push(actualUserId.toLowerCase());
    } else {
      compareArray.push(String(userId).toLowerCase());
    }

    if (address) {
      compareArray.push(address.toLowerCase());
    } else {
      // const isOwnMessage = [userId, address].includes(
      //   String(message.sender).toLowerCase()
      // );
      // if (isOwnMessage) {
      //   console.log("UID: ", userId, "A: ", address);
      // }
    }

    const isOwnMessage = compareArray.includes(
      String(message.sender).toLowerCase()
    );

    // console.log(`

    //     MEGA LOG: ${JSON.stringify(
    //       {
    //         isOwnMessage,
    //         userId,
    //         address,
    //         sender: message.sender,
    //       },
    //       null,
    //       4
    //     )}

    // `);

    if (isOwnMessage) {
      // if (
      //   message.text !==
      // ) {
      //   console.log(`

      //     MATCH LOG: ${JSON.stringify(
      //       {
      //         userId,
      //         address,
      //         sender: message.sender,
      //         text: message.text,
      //       },
      //       null,
      //       4
      //     )}

      // `);
      // }

      return {
        lastMessage: message.text,
        lastMessageTime: message.secondarySortKey,
        lastMessageId: message.messageId,
        audioBufferMetadata: message.audioBufferMetadata || null,
        lastMessageTag: message.tag || null,
      };
    }
  }

  return {
    lastMessage: null,
    lastMessageTime: null,
    lastMessageId: null,
    audioBufferMetadata: null,
  };
};

export { getLastFreshUserMessage };
