// import {
//   //getState,
//   setState,
// } from "../../../../reactStateManagement";
import { getState, setState } from "../../../../reactStateManagement";
import getDoPromiseInQueue from "../../../getDoPromiseInQueue";

const doPromiseInQueue = getDoPromiseInQueue();

const _handleBot6Data = (data) => {
  // console.log(`

  //     BOTO 6 DATA LOG ${JSON.stringify(
  //       {
  //         data,
  //       },
  //       null,
  //       4
  //     )}
  //     )

  //   `);

  /*
  {
    "data": {
        "bot6Data": {
            "bot6s": [
                {
                    "lastUpdated": 1718966226429,
                    "partitionKey": "bot6XXXXXX0x84040E364001f367E10a068Ae137893d7Dd1C289XXXXXX104",
                    "secondaryPartitionKey": "bot6XXXXXX0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
                    "secondarySortKey": 104,
                    "type": "BOT6"
                }
            ],
            "paginationValue": null
        }
    }

  */

  /*

      set state key

    bot6s: v([]),
    bot6PaginationValue: v(null),
    */

  const receivedBot6s = data?.bot6Data?.bot6s || [];
  const bot6PaginationValue = data?.bot6Data?.paginationValue || null;

  const existingBot6s = getState("bot6s") || [];

  // replace existing bot6s with new bot6s, based on partitionKey

  const partitionKeyToBot6 = existingBot6s.reduce((acc, bot6) => {
    acc[bot6.partitionKey] = bot6;
    return acc;
  }, {});

  for (const bot6 of receivedBot6s) {
    partitionKeyToBot6[bot6.partitionKey] = bot6;
  }

  const newBot6s = Object.values(partitionKeyToBot6);

  newBot6s.sort((a, b) => a.secondarySortKey - b.secondarySortKey);

  // console.log("New bot6s: ", newBot6s);

  setState("bot6s", newBot6s);

  if (!data?.doNotUpdatePaginationValue) {
    setState("bot6PaginationValue", bot6PaginationValue);
  }
};

const handleBot6Data = async (data) => {
  await doPromiseInQueue({
    operation: async () => {
      _handleBot6Data(data);
    },
  });
};

export { handleBot6Data };
