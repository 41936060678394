import {
  // COLOR_3,
  // EVENT,
  DIALOG_TYPES,
  EVENT_TYPES,
  // TOTAL_FORM_SCREEN_FORM_TYPES,
  TOTAL_FORM_SCREEN_INPUT_TYPES,
} from "../../constants";
import {
  getState,
  setState,
  // setState
} from "../../reactStateManagement";
// import { SignInBox } from "./SignInBox";
// import { getEvent } from "../../../utils/getEvent";
import handleCmdMessage from "../../mainComponents/NewControls/NMessage/handleCmdMessage";
import { emitEvent } from "../emitEvent";
import updateBChar from "../bChar/updateBChar";
const MAX_MESSAGE_LENGTH = 140;
const MAX_MESSAGE_TO_DISPLAY = 140;
import { getMTokenData } from "../mToken";
import getAuth from "../getAuth";

const getAdminNewsAnchorFunction = (noAuto = false) => {
  return () => {
    const mTokenData = getMTokenData() || {};

    if (!mTokenData || !mTokenData.address) {
      console.log("handleCmdMessageCore: invalid mTokenData");
      return;
    }

    const bCharDatum = getState("bCharDatum");

    if (!bCharDatum || !bCharDatum.x || !bCharDatum.y) {
      console.log("handleCmdMessageCore: invalid bCharDatum");
      return;
    }

    const auth = getAuth();

    if (!auth) {
      console.log("handleCmdMessageCore: invalid auth");
      return;
    }
    console.log("Request to spawn Pigyan at:", bCharDatum.x, bCharDatum.y);

    // const adminCode = getState("adminCode");

    // if (!adminCode) {
    //   console.log("No admin code set");
    //   return;
    // }

    emitEvent({
      type: EVENT_TYPES.NEWS_ANCHOR_APPLICATION,
      data: {
        auth,
        adminAddress: mTokenData.address,
        noAuto,
        // x: bCharDatum.x,
        // y: bCharDatum.y,
        // adminCode,
      },
    });
  };
};

const handleSlashBB = (message) => {
  const auth = getAuth();

  const mToken = getMTokenData();

  if (!mToken || !mToken.address || !mToken.mToken) {
    console.error("handleSlashBB: mToken data not found");

    setState(["browserAlertData"], {
      message: "Failed to submit /bb request, must be logged in",
    });

    return;
  }

  const data = {
    auth,
    type: TOTAL_FORM_SCREEN_INPUT_TYPES.BROADCAST_BUILDER,
    address: mToken.address,
    mToken: mToken.mToken,
  };

  if (message && typeof message === "string" && message.length < 20000000) {
    const messageWithoutSlashBB = message
      .substring(SLASH_BB_SPACE.length)
      .trim();

    if (messageWithoutSlashBB) {
      data.params = {
        pureParamsString: messageWithoutSlashBB,
        results: [],
      };
    }
  }

  console.info(`
  
  
      THE SEND LOG: ${JSON.stringify(
        {
          data,
        },
        null,
        4
      )}
  
  
  `);

  emitEvent({
    type: EVENT_TYPES.TOTAL_FORM_SCREEN_INPUT,
    data,
  });

  setState(["focus"], false);

  // manual unfocus
  const $ = window.$;

  const messageBoxInput = $("#message-box-input");

  messageBoxInput.blur();

  // setState(["totalFormScreen"], {
  //   formName: "Test form",
  //   formElements: [
  //     {
  //       type: TOTAL_FORM_SCREEN_FORM_TYPES.TEXT,
  //       label: "Test code",
  //       min: 1,
  //       max: 100,
  //     },
  //     {
  //       type: TOTAL_FORM_SCREEN_FORM_TYPES.TEXT,
  //       label: "Test code 2",
  //       min: 1,
  //       max: 100,
  //     },
  //     {
  //       type: TOTAL_FORM_SCREEN_FORM_TYPES.TEXT,
  //       label: "Test code 3",
  //       min: 1,
  //       max: 100,
  //     },
  //   ],
  // });
};

const specialMessageToAction = {
  adminPowerMonkey: () => {
    // opens dialog

    setState(["dialog"], {
      type: DIALOG_TYPES.EVENT_SENDER,
      heightPercentage: 100,
    });
  },

  ADMIN_SPAWN_PIGYAN: () => {
    const mTokenData = getMTokenData() || {};

    if (!mTokenData || !mTokenData.address) {
      console.log("handleCmdMessageCore: invalid mTokenData");
      return;
    }

    const bCharDatum = getState("bCharDatum");

    if (!bCharDatum || !bCharDatum.x || !bCharDatum.y) {
      console.log("handleCmdMessageCore: invalid bCharDatum");
      return;
    }

    const auth = getAuth();

    if (!auth) {
      console.log("handleCmdMessageCore: invalid auth");
      return;
    }
    console.log("Request to spawn Pigyan at:", bCharDatum.x, bCharDatum.y);

    const adminCode = getState("adminCode");

    if (!adminCode) {
      console.log("No admin code set");
      return;
    }

    emitEvent({
      type: EVENT_TYPES.ADMIN_SPAWN_PIGYAN,
      data: {
        auth,
        adminAddress: mTokenData.address,
        x: bCharDatum.x,
        y: bCharDatum.y,
        adminCode,
      },
    });
  },

  ADMIN_NEWS_ANCHOR_APPLICATION: getAdminNewsAnchorFunction(),

  ADMIN_NEWS_ANCHOR_APPLICATION_B: getAdminNewsAnchorFunction(true),

  ADMIN_TIS: () => {
    setState(["totalInputScreen"], {});
  },

  "/bb": () => {
    handleSlashBB();
  },
};

const SLASH_BB_SPACE = "/bb ";

const specialMessagePrefixesToAction = {
  SET_ADMIN_CODE: (message) => {
    const adminCode = message.substring("SET_ADMIN_CODE ".length).trim();

    setState(["adminCode"], adminCode);

    console.log("Admin code set to:", adminCode);
  },
};

const sendMessage = (messageToSend = null, audioBufferMetadata, tag = null) => {
  let message = messageToSend || getState("messageBoxInput");

  message = message.trim();
  message = message.substring(0, MAX_MESSAGE_LENGTH);

  if (message.length === 0) {
    return;
  }

  if (message === "/bb") {
    handleSlashBB();
    return;
  } else if (message.startsWith(SLASH_BB_SPACE)) {
    handleSlashBB(message);
    return;
  }

  const specialMessagePrefixes = Object.keys(specialMessagePrefixesToAction);

  for (let i = 0; i < specialMessagePrefixes.length; i++) {
    const prefix = specialMessagePrefixes[i];

    if (message.startsWith(prefix)) {
      specialMessagePrefixesToAction[prefix](message);

      return;
    }
  }

  if (specialMessageToAction[message]) {
    specialMessageToAction[message]();

    return;
  }

  let newMessage;

  const TEST_SEND_MESSAGE_MODE = false;

  if (TEST_SEND_MESSAGE_MODE && message.length > 4) {
    const lengthString = String(message.length);

    newMessage =
      message.substring(0, message.length - lengthString.length) + lengthString;
  } else {
    newMessage = message;
  }

  handleCmdMessage(newMessage);

  // socket.emit(
  //   EVENT,
  //   getEvent({
  //     type: EVENT_TYPES.MESSAGE,
  //     data: {
  //       text: newMessage,
  //       sender: "babebabe-babe-4bae-babe-babebabebabe",
  //     },
  //   })
  // );

  const userData = getState("userData");

  // console.log("UD:", userData);

  // const userId = getState("userId");
  const userId = userData?.address || userData?.id || getState("userId");

  // console.log("UID: ", userId);

  if (!userId) {
    console.log("Send message not executed: userId not found");
    return;
  }

  const data = {
    text: newMessage,
    // sender: "babebabe-babe-4bae-babe-babebabebabe",
    sender: userId,
    audioBufferMetadata,
  };

  if (tag) {
    data.tag = tag;
  }

  emitEvent({
    type: EVENT_TYPES.MESSAGE,
    data,
  });

  updateBChar({
    mutateFunction: (bCharDatum) => {
      const messageToUse =
        message.length > MAX_MESSAGE_TO_DISPLAY
          ? message.substring(0, MAX_MESSAGE_TO_DISPLAY) + "..."
          : message;

      const thePowerOfNow = Date.now();

      const newBCharDatum = {
        ...bCharDatum,
        lastMessage: messageToUse,
        lastMessageTime: thePowerOfNow,
        bk: `message${thePowerOfNow}`,
      };

      return newBCharDatum;
    },

    bBroadcast: true,
  });

  setState(["messageBoxInput"], "");

  const $ = window.$;

  const $messagesSection = $("#messages-section");

  const scrollDown = () => {
    $messagesSection.scrollTop(2222222);
  };

  setTimeout(scrollDown, 25);
  setTimeout(scrollDown, 50);
  setTimeout(scrollDown, 100);
  setTimeout(scrollDown, 200);
  setTimeout(scrollDown, 500);
};

export default sendMessage;
