import { getUserData } from "../../api/getUserData";
import {
  CHARACTERS,
  DEFAULT_START_X_PERCENT_STRING,
  DEFAULT_START_Y_PERCENT_STRING,
} from "../../constants";
import { getMTokenData } from "../mToken";
import updateMetaverseUserState from "../state/updateMetaverseUserState";
import updateBChar from "./updateBChar";

const setArbitraryBCharDatumKeyValuePair = async ({
  keyValuePairs, // = []
}) => {
  await updateBChar({
    mutateFunction: (bChar) => {
      const newBChar = {
        ...bChar,
        // [key]: value,
      };

      //   if (key2 && value2) {
      //     newBChar[key2] = value2;
      //   }
      for (const { key, value } of keyValuePairs) {
        if (value === null) {
          delete newBChar[key];
        } else {
          newBChar[key] = value;
        }
      }

      const mTokenData = getMTokenData();
      if (mTokenData) {
        getUserData({ addToState: false })
          .then(async (userData) => {
            const arbitraryBCharDatum =
              userData?.arbitraryData?.metaverse_bCharDatum;

            let newArbitraryBCharDatum;

            if (arbitraryBCharDatum) {
              console.log("set path a");
              newArbitraryBCharDatum = {
                ...arbitraryBCharDatum,
                // [key]: value,
              };

              //   if (key2 && value2) {
              //     newArbitraryBCharDatum[key2] = value2;
              //   }
              for (const { key, value } of keyValuePairs) {
                if (value === null) {
                  delete newArbitraryBCharDatum[key];
                } else {
                  newArbitraryBCharDatum[key] = value;
                }
              }
            } else {
              console.log("set path b");
              newArbitraryBCharDatum = {
                ...newBChar,
                character: CHARACTERS.DOGE,
                x: DEFAULT_START_X_PERCENT_STRING,
                y: DEFAULT_START_Y_PERCENT_STRING,
              };

              //   newArbitraryBCharDatum[key] = value;

              //   if (key2 && value2) {
              //     newArbitraryBCharDatum[key2] = value2;
              //   }

              for (const { key, value } of keyValuePairs) {
                newArbitraryBCharDatum[key] = value;
              }
            }

            await updateMetaverseUserState({
              key: "bCharDatum",
              value: newArbitraryBCharDatum,
            });
          })
          .catch((err) => {
            console.error(
              "setArbitraryBCharDatumKeyValuePair: error in setting bCharDatum character:"
            );
            console.error(err);
          });
      }

      return newBChar;
    },

    bBroadcast: true,

    sourceTag: "setArbitraryBCharDatumKeyValuePair",
  });
};

export default setArbitraryBCharDatumKeyValuePair;
