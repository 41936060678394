// import { getCharacterData } from "../utils/characterData/getCharacterData.mjs";
import delay from "../delay";
import handleBChar from "./handleBChar";
import {
  //   // TEST_LOG_MEMORY,
  //   FREQUENCY,
  //   MAX_EVENT_LOOP_ITERATION_DURATION,
  EVENT_LOOP_RUNNER_ERROR_DELAY,
} from "./bEventLoopConstants";

import updateBChar from "../bChar/updateBChar";
// import getValue from "../../../../realtimeServer/app/dino/getValue.mjs";
import {
  getState,

  //setState
} from "../../reactStateManagement";
// import getIfBCharDatumDiff from "../bChar/getIfBCharDatumDiff";
// import { getDecimalPercentFromPercentString } from "../../coordsMapper";

// const B_FREQUENCY = 1;
const MAX_I = 1000000000;
const TEST_DISABLED = false;
// const TEST_DISABLED = true;

const TEST_LOG_ON = false;
// const TEST_LOG_ON = true;

let counter = 0;

// let yValueRestingCount = 0;
// let previousYValue = 0;
// const Y_VALUE_NEGLIGIBLE_DIFFERENCE = 0.00000000001;

const logic = async ({ i, settings }) => {
  if (TEST_DISABLED) {
    return;
  }

  if (TEST_LOG_ON) {
    counter++;
    console.log("bEventLoop iteration: " + counter);
  }

  // console.log(`

  //   MEGA LOG ${JSON.stringify(
  //     {
  //       settings,
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);

  // let yValue;

  // typeof i;

  const lastBroadcastedBCharDatum = getState("lastBroadcastedBCharDatum");

  const isDoingBroadcast = i % settings.bFrequency === 0;

  // console.log("IS DOING BROADCAST: " + isDoingBroadcast);

  await updateBChar({
    mutateFunction: (bChar) => {
      const bCharDatum = handleBChar(bChar, {
        frequency: settings.frequency,
        settings,
      });

      // console.log(JSON.stringify(bCharDatum, null, 4));

      // yValue = getDecimalPercentFromPercentString(bCharDatum.y);

      const userData = getState("userData");

      if (userData && userData.address) {
        bCharDatum.address = userData.address;
      }

      // if (lastBroadcastedBCharDatum) {
      //   const diffBetweenNewAndLastBroadcastedBCharDatum = getIfBCharDatumDiff(
      //     lastBroadcastedBCharDatum,
      //     bCharDatum
      //   );

      //   if (diffBetweenNewAndLastBroadcastedBCharDatum) {
      //     console.log("diffBetweenNewAndLastBroadcastedBCharDatum");
      //   } else {
      //     console.log("no diffBetweenNewAndLastBroadcastedBCharDatum");
      //   }
      // }

      return bCharDatum;
    },
    bBroadcast: isDoingBroadcast,
    customCompareBCharDatum: lastBroadcastedBCharDatum
      ? lastBroadcastedBCharDatum
      : undefined,
    // bBroadcast: true,
    sourceTag: "bEventLoop",
  });

  // handle y-resting

  // const yDifference = Math.abs(yValue - previousYValue);
  // if (yDifference < Y_VALUE_NEGLIGIBLE_DIFFERENCE) {
  //   yValueRestingCount = (yValueRestingCount + 1) % 1000;

  //   // if (yValueRestingCount > 1) {
  //   // console.log("SETTING SCREEN Y");
  //   setState(["screenY"], yValue);
  //   // }
  // } else {
  //   yValueRestingCount = 0;
  // }

  // previousYValue = yValue;

  // console.log(`

  //   MEGA LOG ${JSON.stringify(
  //     {
  //       yValue,
  //       yValueRestingCount,
  //     },
  //     null,
  //     4
  //   )}
  //   )

  // `);
};

const bEventLoop = async () => {
  for (let i = 0; i < Infinity; i++) {
    const startTime = Date.now();

    const settings = getState("settings");

    await logic({ i, settings });

    const endTime = Date.now();

    const duration = endTime - startTime;

    const iterationsPerSecond = 1 / settings.frequency;

    // export const MAX_EVENT_LOOP_ITERATION_DURATION =
    //   EVENT_LOOP_ITERATIONS_PER_SECOND * 1000; // 250 ms
    const maxEventLoopIterationDuration = iterationsPerSecond * 1000;

    if (duration >= maxEventLoopIterationDuration) {
      console.log(
        "eventLoop: duration of " +
          duration +
          "ms is goe than " +
          maxEventLoopIterationDuration +
          "ms"
      );
    } else {
      let delayDuration = maxEventLoopIterationDuration - duration;

      // console.log("eventLoop: delayDuration: " + delayDuration + "ms");

      if (delayDuration > 0) {
        await delay(delayDuration);
      }
    }

    i = i % MAX_I;
  }
};

/**
 * Runs the event loop, catching any errors and retrying after a delay.
 * @returns {Promise<void>}
 */
const bEventLoopRunner = async () => {
  try {
    console.log("eventLoopRunner: starting eventLoop");

    await bEventLoop();
  } catch (err) {
    console.log("eventLoopRunner: error in eventLoop");
    console.log(err);

    await delay(EVENT_LOOP_RUNNER_ERROR_DELAY);

    await bEventLoopRunner();
  }
};

export { bEventLoopRunner };
