import React from "react";
// import { DisplayText } from "./DisplayText";
import { NCharBase } from "../NCharBase/NCharBase";
import { CUDDLE_CONSTANTS, LEFT, RIGHT } from "../../../constants";
import isNPC from "../../../utils/bChar/isNPC";
import calculatePigyanPosition from "./calculatePigyanPosition";

export const NCharFren = ({
  xMin,
  yMin,
  xPixels,
  yPixels,
  characterDatum,
  eighteenPlusMode,
  systemTimeOffset,
  messages,
  scale,
  // xPixelsMainUser,
}) => {
  if (!characterDatum) {
    return null;
  }

  let fashion;

  if (characterDatum.fashionTop) {
    fashion = fashion || {};
    fashion.fashionTop = characterDatum.fashionTop;
  }

  if (characterDatum.fashionBottom) {
    fashion = fashion || {};
    fashion.fashionBottom = characterDatum.fashionBottom;
  }

  let cuddleImageUrl;
  let cuddleImageUrl2;

  if (characterDatum.cuddle) {
    if (characterDatum.cuddle.userIds[0] === characterDatum.userId) {
      const cuddleData = CUDDLE_CONSTANTS[characterDatum.cuddle.type];
      if (cuddleData) {
        cuddleImageUrl = eighteenPlusMode
          ? cuddleData.imageUrl
          : cuddleData.imageUrlCensor;
        // cuddleImageUrl2 = cuddleData.imageUrl2;
        cuddleImageUrl2 = eighteenPlusMode
          ? cuddleData.imageUrl2
          : cuddleData.imageUrl2Censor;
      }
    } else {
      // only primary user in cuddle gets to display the cuddle image
      return null;
    }
  }

  let lastDirection;

  // adjust incase is NPC
  if (isNPC(characterDatum.character)) {
    // xPixels += NWORLD_UNIT_WIDTH;

    const pigyanPosition = calculatePigyanPosition(
      xPixels,
      yPixels,
      systemTimeOffset
    );

    xPixels = pigyanPosition.x;
    yPixels = pigyanPosition.y;

    lastDirection = pigyanPosition.wasRightLastDirection ? RIGHT : LEFT;
  } else {
    lastDirection = characterDatum.lastDirection;
  }

  return (
    <NCharBase
      userId={characterDatum.userId}
      address={characterDatum.address}
      frenXPixels={xPixels}
      xMin={xMin}
      minY={yMin}
      yPixels={yPixels}
      index={1}
      rightDown={characterDatum.rightDown}
      leftDown={characterDatum.leftDown}
      lastDirection={lastDirection}
      character={characterDatum.character}
      attack={characterDatum.attack}
      fun={characterDatum.fun}
      fashion={fashion}
      eighteenPlusMode={eighteenPlusMode}
      cuddleImageUrl={cuddleImageUrl}
      cuddleImageUrl2={cuddleImageUrl2}
      lastMessage={characterDatum.lastMessage}
      lastMessageTime={characterDatum.lastMessageTime}
      messages={messages}
      scale={scale}
    />
  );
};
