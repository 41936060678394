// import { getState } from "../../../../../reactStateManagement";
import refreshBCharLastUpdated from "../../../../bChar/refreshBCharLastUpdated";
import delay from "../../../../delay";
import sendMessage from "../../../../message/sendMessage";
import updateAudioGhostQueue from "../../../../world/updateAudioGhostQueue";
import compareAudioGhostQueueElements from "../../../compareAudioGhostQueueElements";

// const createRandomId = () => {
//   return Math.random().toString(36).substring(2, 15);
// };

const handleSTUAMessage = async (data) => {
  const message = data.message.text;

  const audioBufferMetadata = data?.audioBufferMetadata || null;

  // # MESSAGE-ID-NAWSM
  sendMessage(message, audioBufferMetadata, data.message.messageId);

  const audioBufferString = data.audioBufferString;

  if (!audioBufferString) {
    return;
  }

  await updateAudioGhostQueue({
    mutateFunction: async (audioGhostQueue) => {
      // return [...audioGhostQueue, audioBufferString];

      // if (audioGhostQueue.includes(audioBufferString)) {
      //   return;
      // }

      const messageIsAlreadyInQueue = audioGhostQueue.some(
        // (element) => element.messageId === data.message.messageId
        (element) =>
          compareAudioGhostQueueElements(element, {
            messageId: data.message.messageId,
            text: data.message.text,
            audioBufferString: data.message.audioBufferString,
          })
      );

      if (messageIsAlreadyInQueue) {
        return null;
      }

      const newElement = {
        audioBufferString,
        time: Date.now(),
        messageId: data.message.messageId,
        messageIdParent: data.message.messageIdParent,
        text: data.message.text,
      };

      const newAudioGhostQueue = [...audioGhostQueue, newElement];

      return newAudioGhostQueue;
    },
  });

  await delay(12000);

  //   const bCharDatum = getState("bCharDatum");

  await refreshBCharLastUpdated();
};

export default handleSTUAMessage;
