import {
  default as ReactStateManagement,
  v,
  //VALUE
} from "react-state-management";

import {
  LOCAL_STORAGE_KEYS,
  MOBILE_WIDTH_THRESHOLD,
  POWER_MODES_LIST,
} from "./constants";
import { getMTokenData } from "./utils/mToken";

// const GLOBAL_CONTROL_SCALAR = 0.0000000009;

const initialBCharDatum = {
  x: "50%",
  y: "0%",
  lastUpdated: 0,
  metaStateA: false,
};

const respawnDataString = localStorage.getItem(LOCAL_STORAGE_KEYS.RESPAWN_DATA);

let respawnData = null;

const mTokenData = getMTokenData();

if (!mTokenData) {
  if (respawnDataString) {
    try {
      respawnData = JSON.parse(respawnDataString);

      if (
        respawnData &&
        respawnData.bCharDatum &&
        respawnData.bCharDatum.x &&
        respawnData.bCharDatum.y
      ) {
        console.log(
          "Initial State Management respawnData x,y: " +
            respawnData.bCharDatum.x +
            ", " +
            respawnData.bCharDatum.y
        );

        initialBCharDatum.x = respawnData.bCharDatum.x;
        initialBCharDatum.y = respawnData.bCharDatum.y;
      }
    } catch (error) {
      console.log("error in respawn data parsing:");
      console.log(error);
    }
  }
}

const CALIBRATION_SCALAR = 1;
const GLOBAL_CONTROL_SCALAR = 5e-10 * CALIBRATION_SCALAR;

const reactStateManagement = ReactStateManagement({
  initialState: {
    // Global
    // loading: v(true),
    loading: v(false),
    mobile: v(false),
    mobileWidthThreshold: v(MOBILE_WIDTH_THRESHOLD),
    focus: v(null),
    windowWidth: v(window.innerWidth),
    windowHeight: v(window.innerHeight),
    socket: v(null),
    snackMessage: v(""),
    snackTime: v(0),
    messages: v([]),
    unreadMessages: v(0),
    dialog: v(null),
    totalInputScreen: v(null),
    totalInputScreenText: v(""),
    totalFormScreen: v(null),
    browserAlertData: v(null),
    browserAlertDataB: v(null),
    powerCredentialsWaitTimeBoostCount: v(0),
    shouldSilentCancelPowerCredentialsRequest: v(false),
    lastPowerCredentialsPostMessageTime: v(0),
    lastBroadcastedBCharDatum: v(null),
    audioGhostQueue: v([]),
    messageIdToAudioPlayTime: v({}),
    lastDoneMessageIds: v([]),
    userIdToChatBubbleData: v({}),

    adminCode: v(null),
    constructionViewMode: v(false),

    botoScreenCaptureMode: v(false),
    botoScreenCaptureModeType: v(null),
    // botoScreenCaptureMode: v(true),
    // botoScreenCaptureModeType: v("COORDINATE_GRID"),
    globalIFrameData: v([]),

    // browserAlertData: v({
    //   message: "Sample browser alert message",
    // }),

    unreadMessagesNotFirstShot: v(false),

    showCoords: v(false),
    respawnData: v(respawnData),
    // slideMenuData: v(null),
    slideMenuData: v(null),

    landMappings: v(null),

    messageMode: v(false),
    nPaintMode: v(false),
    loginMode: v(false), // incorrectly named: now technically is "menu open"
    menuPageNumber: v(0),

    // dialog: v({
    //   type: "EVENT_SENDER",
    //   heightPercentage: 100,
    // }),

    userId: v(null),
    password: v(null),

    mining: v(false),

    // Main User
    userData: v(null),

    // Main User Local Data
    bCharDatum: v(initialBCharDatum),
    powerMode: v(POWER_MODES_LIST[2]),

    // Build
    buildMode: v(false),
    putObjectMode: v(false),
    bot2Mode: v(false),
    mapData: v([]), // map === world
    quantizeMode: v(true),
    pressedDownOnBuildPlane: v(false),
    // quantizeMode: v(true),

    // MessagesBox
    messageBoxInput: v(""),

    // ExecutiveBox
    executiveBoxMode: v(null),
    executiveBoxUsernameInput: v(""),
    executiveBoxPasswordInput: v(""),

    // World (Classical)
    worldNumber: v(0),
    /* characterData example:
      [
        {
          x: 0,
          y: 0,
          userId: 'babebabe-babe-4bae-babe-babebabebabe',
        }
      ]
    */

    // NWorld
    screenY: v(0),

    nWorldData: v([]),
    worldNumberToLevelNumberToNWorldData: v({
      0: {
        0: [],
      },
    }),
    // scale: v(1),
    scale: v(1),

    systemTimeOffset: v(0),

    lastScanTime: v(0),

    partitionIdToNWorldData: v({}),

    localUpdateQueuePutsBrowser: v([]),
    localUpdateQueueRemovesBrowser: v([]),
    localUpdateQueueCombinedBrowser: v([]),

    selectedItems: v([]),

    // nObjectPutBackgroundColor: v(null),

    // browser dark red default
    nObjectPutBackgroundColor: v("#8b0000"),
    lastSetNObjectPutBackgroundColor: v(null),
    nObjectPutBackgroundColorInput: v(""),

    createImageResponses: v([]),

    characterData: v([]),
    // characterData: v([
    //   {
    //     x: 0,
    //     y: 0,
    //     userId: "babebabe-babe-4bae-babe-babebabebabe",
    //   },
    // ]),

    wCharacterData: v([]),

    calculatedUnitWidth: v(null),
    calculatedUnitHeight: v(null),

    // Joy Pad
    //     const [buttonIsPressedRight, setButtonIsPressedRight] = React.useState(false);
    // const [buttonIsPressedLeft, setButtonIsPressedLeft] = React.useState(false);
    // const [buttonIsPressedUp, setButtonIsPressedUp] = React.useState(false);
    // const [buttonIsPressedRightUp, setButtonIsPressedRightUp] =
    //   React.useState(false);
    // const [buttonIsPressedLeftUp, setButtonIsPressedLeftUp] =
    //   React.useState(false);
    // const [centerDivIsPressed, setCenterDivIsPressed] = React.useState(false);

    buttonIsPressedRight: v(false),
    buttonIsPressedLeft: v(false),
    buttonIsPressedUp: v(false),
    buttonIsPressedRightUp: v(false),
    buttonIsPressedLeftUp: v(false),
    centerDivIsPressed: v(false),

    // Dialog
    eventSenderEvents: v([]),
    eventSenderEventsOutput: v([]),
    eventSenderEventsShowCharacterData: v(false),
    eventSenderEventsShowWCharacterData: v(false),
    eventSenderEventsShowBotData: v(false),
    eventSenderEventsShowBCharData: v(false),

    // const FREQUENCY = 10;
    // const B_FREQUENCY = 1;
    // const MAX_Y_MOMENTUM = 12 * GLOBAL_CONTROL_SCALAR; //
    // const JUMP_INCREMENT = 44 * GLOBAL_CONTROL_SCALAR;
    // const MAX_MOMENTUM = 9 * GLOBAL_CONTROL_SCALAR;
    // const INCREMENT_CONSTANT = 6 * GLOBAL_CONTROL_SCALAR;
    // const INCREMENT_BOOST_THRESHOLD = 12 * GLOBAL_CONTROL_SCALAR;
    // const INCREMENT_BOOST = 2 * GLOBAL_CONTROL_SCALAR;

    // {
    //   "frequency": 30,
    //   "bFrequency": 3,
    //   "globalControlScalar": 5e-10,
    //   "maxYMomentum": 12,
    //   "jumpIncrement": 44,
    //   "maxMomentum": 22,
    //   "incrementConstant": 12,
    //   "incrementBoostThreshold": 12,
    //   "incrementBoost": 2
    // }

    settings: v({
      maxNumberOfPartitionsInMemory: 1000, // 10x10 per partition * 1000 = 100,000
      frequency: 30,
      bFrequency: 3,
      globalControlScalar: GLOBAL_CONTROL_SCALAR,
      // gravity: 2 * GLOBAL_CONTROL_SCALAR,
      gravity: 15 * GLOBAL_CONTROL_SCALAR,
      maxYMomentum: 7 * GLOBAL_CONTROL_SCALAR,
      jumpIncrement: 16 * GLOBAL_CONTROL_SCALAR,
      maxMomentum: 9 * GLOBAL_CONTROL_SCALAR,
      incrementConstant: 12 * GLOBAL_CONTROL_SCALAR,
      incrementBoostThreshold: 2 * GLOBAL_CONTROL_SCALAR,
      incrementBoost: 4 * GLOBAL_CONTROL_SCALAR,
    }),

    // Bot
    botData: v([]),

    // Bot2
    bot2Data: v([]),
    // bot2Data: v([
    //   {
    //     address: "babebabe-babe-4bae-babe-babebabebabe",
    //     data: {
    //       data: [
    //         {
    //           x: "50.000000025%",
    //           y: "0%",
    //         },
    //       ],
    //     },
    //   },
    // ]),

    bot2Interactions: v([]), // DEPRECATED
    bot2InteractionsThereAreMoreResults: v(false), // DEPRECATED
    botNumberToBot2InteractionData: v({}),

    bot6PageMode: v(null),
    bot6PageModeTokenId: v(null),
    bot6s: v([]),
    bot6PaginationValue: v(null),
    tokenIdToBot6InteractionData: v({}),
    bot6InteractionProcessingReceiptData: v([]),
    completedRequestIds: v([]),
    tokenIdToBot6InteractionInput: v({}),
    tokenIdToBot6InteractMode: v({}),
    bot6WorldData: v({}), // tokenId to bot6 data
    bot6PageExpandMode: v(false),
    // sample bot2Interactions
    // bot2Interactions: v([
    //   {
    //     lastUpdated: 1718770475537,
    //     partitionKey:
    //       "bot2Action2_3935b175-ee94-4c98-99e2-f054aac88e27_1718770475537",
    //     output: {
    //       commands: [
    //         {
    //           parameters: {
    //             direction: "up",
    //           },
    //           command: "move",
    //         },
    //       ],
    //     },
    //     secondarySortKey: 1718770475537,
    //     input:
    //       'BOTOCOMMAND_GENERALIZED_COMMAND_V1_|{"type":"BOTO","subtype":"INTERACT_VPOWER","value":{"promptAIDataIndex":0,"contextAIDataIndex":-1,"gptSelection":"gpt-4o"}}|',
    //     address: "0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
    //     secondaryPartitionKey:
    //       "bot2Action2_BOTOXXXXXXINTERACT_VPOWERXXXXXXbot0_0x57D9be95B12a687D1cc6f327B57338Cd85AEeA8E",
    //     id: "3935b175-ee94-4c98-99e2-f054aac88e27",
    //     type: "BOT2_ACTION_2",
    //   },
    // ]),

    // Mint Mode (Select Rect/Square)
    mintMode: v(false),

    // Select Rect (in Decimal Percent)
    selectRectTopLeftX: v(null),
    selectRectTopLeftY: v(null),
    selectRectBottomRightX: v(null),
    selectRectBottomRightY: v(null),

    // Select Square (in Decimal Percent)
    selectSquareTopLeftX: v(null),
    selectSquareTopLeftY: v(null),
    selectSquareBottomRightX: v(null),
    selectSquareBottomRightY: v(null),

    pixelSpec: v(null),

    imageToPixelsImage: v(null),
    imageToPixelsPutBlackPixels: v(false),

    // Marketplace
    marketplaceMode: v(false),
    marketplaceExpandMode: v(false),
    marketplaceNFTListings: v([]),
    marketplaceShowNFTListings: v(true),
    marketplaceListNFTSmartContractAddressInput: v(""),
    marketplaceListNFTTokenIdInput: v(""),
    marketplaceListNFTPriceInput: v(""),
    marketplaceListNFTSellerMemoInput: v(""),
    marketplaceListNFTPartitionKeyToBuyerMemoInput: v({}),
    marketplaceNFTActionables: v([]),
    marketplaceShowNFTActionables: v(true),
    marketplaceNFTSales: v([]),
    marketplaceShowNFTSales: v(true),

    //  {
    //     "selectRectTopLeftX": 0.50000000025,
    //     "selectRectTopLeftY": 2.5e-10,
    //     "selectRectBottomRightX": 0.5000000005,
    //     "selectRectBottomRightY": 3e-10
    // }

    // selectRectTopLeftX: v(0.5),
    // selectRectTopLeftY: v(50 / 2000000000000),
    // // selectRectBottomRightX: v(0.5000000005),
    // selectRectBottomRightX: v(0.5 + 250 / 2000000000000),
    // // selectRectBottomRightY: v(3e-10),
    // selectRectBottomRightY: v(0),

    /* Inventory */
    inventoryMode: v(false),

    /* Explore Mode */
    preExploreModeBCharDatum: v(null),

    /* Character and Fashion */
    characterIndex: v(0),
    fashionSelectMode: v(false),
    fashionSelectNFTMode: v(false),
    userIdToFashionData: v({}),

    /* 18+ Mode */
    eighteenPlusMode: v(false),
  },
});

const setUpReactStateManagement = reactStateManagement.setUpReduxX;
const resetReactStateManagement = reactStateManagement.resetReduxX;
const getState = reactStateManagement.getState;
const setState = reactStateManagement.setState;
// const oldeFashionedStateManagement =
//   reactStateManagement.oldeFashionedStateManagement;

export {
  setUpReactStateManagement,
  resetReactStateManagement,
  getState,
  setState,
  // oldeFashionedStateManagement,
};
