import { yieldNumber } from "../../constants";
import updateBChar from "./updateBChar";

const refreshBk = () => {
  updateBChar({
    mutateFunction: (bChar) => {
      bChar.bk = `s${yieldNumber()}`;
      // console.log("bChar.bk: " + bChar.bk);
      return bChar;
    },
    bBroadcast: true,
    sourceTag: "SignInInit",
  });
};

export default refreshBk;
