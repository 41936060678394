import updateBChar from "../../../bChar/updateBChar.js";
import getDoPromiseInQueue from "../../../getDoPromiseInQueue.js";

const doPromiseInQueue = getDoPromiseInQueue();

const handleForceCuddle = async (event) => {
  await doPromiseInQueue({
    operation: async () => {
      const cuddle = event.data.cuddle;

      // if (!cuddle) {
      //   console.error("handleForceCuddle: no cuddle data!😠");

      //   return;
      // }

      updateBChar({
        mutateFunction: (bCharDatum) => {
          if (cuddle) {
            bCharDatum.cuddle = cuddle;
            if (cuddle.x) {
              bCharDatum.x = cuddle.x;
            }

            if (cuddle.y) {
              bCharDatum.y = cuddle.y;
            }
          } else {
            delete bCharDatum.cuddle;
          }

          return bCharDatum;
        },
        bBroadcast: true,
      });
    },
  });
};

export default handleForceCuddle;
